@use 'mq';

.bubble {
  @include use-responsive-config(--bubble-size, responsive-map(xs 6rem, xl 8rem));
  @include use-responsive-config(--bubble-text-max-inline-size, responsive-map(xs 16rem, xl 20rem));
}

.bubble__bubble {
  align-items: center;
  background-color: var(--bubble-background-color, var(--primary-color));
  block-size: var(--_bubble-size);
  border-color: var(--bubble-border-color, var(--color-white));
  border-radius: calc(var(--_bubble-size) / 2);
  border-style: var(--bubble-border-style, solid);
  border-width: var(--bubble-border-width, 0.5rem);
  box-shadow: 0 0 var(--bubble-shadow-size, 1.5rem) 0 var(--bubble-shadow-color, rgba(0, 0, 0, 0.5));
  color: var(--bubble-color, var(--color-white));
  display: flex;
  flex-direction: column;
  inline-size: var(--_bubble-size);
  justify-content: center;
  overflow: hidden;
  transition-property: scale;

  &:hover,
  &:focus {
    scale: 1.1;
  }
}

.bubble--text .bubble__bubble {
  inline-size: fit-content;
}

.bubble__bubble-image {
  block-size: 100%;
  display: flex;
  flex-direction: column;
  inline-size: 100%;
}

.bubble__bubble-icon {
  font-size: calc(var(--_bubble-size) * 0.6);
  line-height: 0;
}

.bubble__bubble-label {
  font-size: calc(var(--_bubble-size) * 0.2);
  font-weight: bold;
  line-height: var(--line-height-default);
  max-inline-size: var(--_bubble-text-max-inline-size);
  padding-inline: 2rem;
  text-align: center;
  text-transform: var(--_bubble-text-text-transform, none);
}
