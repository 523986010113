.bullet-list {
  --_bullet-list-indent: var(--bullet-list-indent, 2em);
  --_bullet-list-marker-color: var(--bullet-list-marker-color, var(--primary-color));
  --_bullet-list-marker-size: var(--bullet-list-marker-size, 1em);
  --_bullet-list-marker-space: var(--bullet-list-marker-space, 0.4em);
  --_bullet-list-marker-font-family: var(--bullet-list-marker-font-family, 'bullet', var(--root-font-family));
  --_bullet-list-spacing: var(--bullet-list-spacing, 0.8em);
  --_bullet-list-line-height: var(--bullet-list-line-height, var(--line-height-body));
  --_bullet-list-color: var(--bullet-list-color, inherit);
  --_bullet-list-font-size: var(--bullet-list-font-size, inherit);

  color: var(--_bullet-list-color);
  font-size: var(--_bullet-list-font-size);
  line-height: var(--_bullet-list-line-height);
  list-style-type: var(--_bullet-list-marker);
  padding-inline-start: 0;

  & & {
    margin-block-start: var(--_bullet-list-spacing);
  }
}

.bullet-list--unordered {
  --_bullet-list-marker: var(--bullet-list-unordered-marker, '\E001');
  --_bullet-list-marker-size: var(--bullet-list-unordered-marker-size, 1.3em);
}

.bullet-list--checked {
  --_bullet-list-marker: var(--bullet-list-checked-marker, '\E000');
  --_bullet-list-marker-size: var(--bullet-list-checked-marker-size, 1.3em);
}

.bullet-list--crossed {
  --_bullet-list-marker: var(--bullet-list-crossed-marker, '\E002');
  --_bullet-list-marker-size: var(--bullet-list-crossed-marker-size, 1.3em);
}

.bullet-list--ordered {
  --_bullet-list-marker: var(--bullet-list-ordered-marker, decimal);
  --_bullet-list-marker-size: var(--bullet-list-ordered-marker-size, 1em);
}

.bullet-list__item {
  inset-inline-start: var(--_bullet-list-indent);
  padding-inline-start: var(--_bullet-list-marker-space);
  position: relative;

  & + & {
    margin-block-start: var(--_bullet-list-spacing);
  }

  &::marker {
    color: var(--_bullet-list-marker-color);
    font-family: var(--_bullet-list-marker-font-family);
    font-size: var(--_bullet-list-marker-size);
    font-weight: var(--font-weight-bold);
    line-height: 0;
    width: 1em;
  }
}

.bullet-list__text {
  display: flow-root;
  padding-inline-end: var(--_bullet-list-indent);
}
