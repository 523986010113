.profile {
  @include use-responsive-config(--profile-links-row-gap, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-config(--profile-row-gap, responsive-map(xs 3rem, xl 5rem));

  --_profile-section-font-size: var(--profile-section-font-size, var(--font-size-paragraph));
  --_profile-image-max-width: var(--profile-image-max-width, 40rem);

  container-name: profile;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: var(--_profile-row-gap);
}

.profile__image {
  --image-z-index: 1;

  max-width: var(--_profile-image-max-width);
  pointer-events: none;
  position: relative;
  width: 75%;
}

.profile__copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  pointer-events: all;
  position: absolute;
}

.profile__links {
  --_profile-links-columns: 1;

  column-gap: var(--gap);
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(var(--_profile-links-columns), 1fr);
  row-gap: var(--_profile-links-row-gap);

  .profile__headline + & {
    margin-block-start: calc(var(--_profile-row-gap) * -0.5);
  }

  @container profile (min-width: 30rem) {
    --_profile-links-columns: 2;
  }

  @container profile (min-width: 60rem) {
    --_profile-links-columns: 3;
  }
}

.profile__link {
  display: block;
}

.profile__section {
  column-gap: var(--gap);
  display: flex;
  flex-direction: column;
  font-size: var(--_profile-section-font-size);
  line-height: var(--line-height-body);
  row-gap: calc(var(--_profile-row-gap) / 2);

  @container profile (min-width: 60rem) {
    flex-direction: row;
  }
}

.profile__section-headline {
  --headline-text-transform: uppercase;
  --headline-line-height: var(--line-height-body);
  --headline-underline-color: currentColor;

  flex-shrink: 0;

  @container profile (min-width: 60rem) {
    width: 25%;
  }
}

.profile__sections-elements {
  display: grid;
  row-gap: calc(var(--_profile-row-gap) / 2);
}

.profile__element {
  display: flex;
  flex-direction: column;
  row-gap: 0.4em;
}

.profile__element-title {
  font-weight: var(--font-weight-bold);
}

.profile__element-list {
  --bullet-list-indent: 1em;
  --bullet-list-spacing: 0.4em;
}

.profile__element-links {
  --links-row-gap: 0.4em;
}
