.link {
  --_link-color: var(--link-color, var(--primary-interactive-color));
  --_link-font-weight: var(--link-font-weight, inherit);
  --_link-icon-color: var(--link-icon-color, inherit);
  --_link-icon-gap: var(--link-icon-gap, 0.5em);
  --_link-icon-puffer: var(--link-icon-puffer, 0.1em);
  --_link-icon-size: var(--link-icon-size, 0.9em);
  --_link-icon-vertical-align: var(--link-icon-vertical-align, 0.1em);
  --_link-underline-color: var(--link-underline-color, currentColor);
  --_link-underline-height: var(--link-underline-height, 0.08em);
  --_link-underline-offset: var(--link-underline-offset, 0.2em);

  // :hover, :focus & :active
  --_link-color--enter: var(--link-color--enter, var(--primary-interactive-color--enter));
  --_link-icon-color--enter: var(--link-icon-color--enter, inherit);
  --_link-underline-color--enter: var(--link-underline-color--enter, transparent);

  background-color: var(--link-background-color, transparent);
  box-decoration-break: clone;
  color: var(--_link-color);
  font-weight: var(--_link-font-weight);
  padding-block: var(--link-padding-block, 0);
  padding-inline: var(--link-padding-inline, 0);

  &:is(:hover:not([disabled]), :focus-visible:not(.has-invisible-focus)) {
    --_link-color: var(--_link-color--enter);
    --_link-icon-color: var(--_link-icon-color--enter);
  }
}

.link--block {
  display: flex;
}

.link__text {
  --underline-color: var(--_link-underline-color);
  --underline-color--enter: var(--_link-underline-color--enter);
  --underline-height: var(--_link-underline-height);
  --underline-offset: var(--_link-underline-offset);
  --underline-transition-duration: var(--duration-default);

  flex-grow: 1;
}

.link__icon {
  color: var(--_link-icon-color);
  flex-shrink: 0;
  font-size: var(--_link-icon-size);
  transition-property: color;
  vertical-align: var(--_link-icon-vertical-align);
  white-space: nowrap;

  &:first-child {
    margin-inline-end: var(--_link-icon-gap);
    margin-inline-start: var(--_link-icon-puffer);
  }

  &:last-child {
    margin-inline-end: var(--_link-icon-puffer);
    margin-inline-start: var(--_link-icon-gap);
  }
}
