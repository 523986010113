.list {
  @include use-responsive-config(--list-item-padding-block, responsive-map(xs 3rem, xl 4rem));

  --_list-border-color: var(--list-border-color, var(--line-color));
  --_list-border-width: var(--list-border-width, 1px);
  --_list-border-style: var(--list-border-style, solid);
  --_list-item-column-gap: var(--list-item-column-gap, var(--gap));
  --_list-item-row-gap: var(--list-item-row-gap, calc(var(--_list-item-padding-block) * 0.5));

  border-block-color: var(--_list-border-color);
  border-block-style: var(--_list-border-style);
  border-block-width: var(--_list-border-width);
  container-name: list;
  container-type: inline-size;
}

.list__item {
  column-gap: var(--_list-item-column-gap);
  display: flex;
  flex-direction: column;
  padding-block: var(--_list-item-padding-block);
  position: relative;
  row-gap: calc(var(--_list-item-row-gap) * 2);

  &:not(:last-child) {
    border-block-end-color: var(--_list-border-color);
    border-block-end-style: var(--_list-border-style);
    border-block-end-width: var(--_list-border-width);
  }

  @container list (min-width: 50rem) {
    flex-direction: row;
  }
}

.list__item-image {
  align-self: flex-start;
  flex-shrink: 0;
  max-width: 30rem;
  position: relative;

  @container list (min-width: 50rem) {
    width: 40%;
  }
}

.list__item-copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  position: absolute;
}

.list__item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: var(--_list-item-row-gap);
}

.list__item-text {
  font-size: var(--font-size-default);
  line-height: var(--line-height-body);

  > * + * {
    margin-block-start: 0.5em;
  }
}
