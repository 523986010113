.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site__footer {
  margin-block-start: auto;
}

.site__bubble {
  inset-block-end: clamp(2rem, 2vw, 6rem);
  inset-inline-end: clamp(2rem, 2vw, 6rem);
  position: fixed;
  z-index: z-index('bubble');
}

.site__banderole {
  inset-block-end: 0;
  inset-inline: 0;
  position: sticky;
  z-index: z-index('banderole');
}
