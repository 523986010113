.copyright {
  --_copyright-background-color: var(--copyright-background-color, var(--color-black));
  --_copyright-border-radius: var(--copyright-border-radius, 50%);
  --_copyright-color: var(--copyright-color, var(--color-white));
  --_copyright-icon-size: var(--copyright-icon-size, 2rem);
  --_copyright-opacity: var(--copyright-opacity, 0.4);
  --_copyright-size: var(--copyright-size, 2.4rem);
  --_copyright-z-index: var(--copyright-z-index, #{z-index('default')});

  // :hover, :focus, :active
  --_copyright-opacity--enter: var(--copyright-opacity--enter, 1);

  display: inline-block;
  font-size: var(--_copyright-size);
  line-height: 0;
  max-width: 40rem;
  position: relative;
  z-index: var(--_copyright-z-index);
}

.copyright__button {
  align-items: center;
  background-color: var(--_copyright-background-color);
  border-radius: var(--_copyright-border-radius);
  color: var(--_copyright-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--_copyright-icon-size);
  height: var(--_copyright-size);
  justify-content: center;
  opacity: var(--_copyright-opacity);
  transition-duration: var(--duration-default);
  transition-property: opacity;
  user-select: none;
  width: var(--_copyright-size);
  z-index: 1;

  &:is(:hover, :focus-visible, .copyright__button--active) {
    opacity: var(--_copyright-opacity--enter);
  }
}

.copyright__tooltip {
  --selection-background-color: var(--copyright-tooltip-color, var(--color-white));
  --selection-foreground-color: var(--copyright-tooltip-background-color, var(--color-black));
  --link-color: currentColor;
  --link-color--enter: currentColor;
  --tooltip-background-color: var(--copyright-tooltip-background-color, var(--color-black));
  --tooltip-color: var(--copyright-tooltip-color, var(--color-white));
}

.copyright__text {
  --selection-background-color: var(--copyright-tooltip-color, var(--color-white));
  --selection-foreground-color: var(--copyright-tooltip-background-color, var(--color-black));
  --link-color: currentColor;
  --link-color--enter: currentColor;

  color: var(--copyright-strict-color, var(--color-white));
  font-size: var(--copyright-strict-font-size, 1.2rem);
  line-height: var(--copyright-strict-line-height, 1.3);
  text-align: right;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
  -webkit-text-stroke: 0.1px var(--color-black);
}
