.tab-bar {
  --_tab-bar-background-color: var(--tab-bar-background-color, var(--color-white));
  --_tab-bar-font-family: var(--tab-bar-font-family, inherit);
  --_tab-bar-font-size: var(--tab-bar-font-size, var(--font-size-headline-5));
  --_tab-bar-font-weight: var(--tab-bar-font-weight, var(--font-weight-bold));
  --_tab-bar-letter-spacing: var(--tab-bar-letter-spacing, 0);
  --_tab-bar-options-row-gap: var(--tab-bar-options-row-gap, 2rem);
  --_tab-bar-scroller-fade-width: var(--tab-bar-scroller-fade-width, 2rem);
  --_tab-bar-text-transform: var(--tab-bar-text-transform, uppercase);

  // Label
  --_tab-bar-label-color: var(--tab-bar-label-color, var(--primary-color));
  --_tab-bar-label-font-family: var(--tab-bar-label-font-family, inherit);
  --_tab-bar-label-font-size: var(--tab-bar-label-font-size, inherit);
  --_tab-bar-label-font-weight: var(--tab-bar-label-font-weight, inherit);
  --_tab-bar-label-letter-spacing: var(--tab-bar-label-letter-spacing, inherit);
  --_tab-bar-label-text-transform: var(--tab-bar-label-text-transform, inherit);

  // Option
  --_tab-bar-option-color: var(--tab-bar-option-color, var(--text-color));
  --_tab-bar-option-font-family: var(--tab-bar-option-font-family, inherit);
  --_tab-bar-option-font-size: var(--tab-bar-option-font-size, inherit);
  --_tab-bar-option-font-weight: var(--tab-bar-option-font-weight, inherit);
  --_tab-bar-option-letter-spacing: var(--tab-bar-label-letter-spacing, inherit);
  --_tab-bar-option-text-transform: var(--tab-bar-option-text-transform, inherit);
  --_tab-bar-option-underline-color: var(--tab-bar-option-underline-color, transparent);
  --_tab-bar-option-underline-height: var(--tab-bar-option-underline-height, 0.2em);
  --_tab-bar-option-underline-offset: var(--tab-bar-option-underline-offset, 0.4em);

  //_ Option: var(// Option, selecte)d
  --_tab-bar-option-color--selected: var(--tab-bar-option-color--selected, var(--text-color));
  --_tab-bar-option-underline-color--selected: var(--tab-bar-option-underline-color--selected, var(--primary-color));

  background-color: var(--_tab-bar-background-color);
  line-height: 1;
  min-width: 0;
}

.tab-bar {
  font-family: var(--_tab-bar-font-family);
  font-size: var(--_tab-bar-font-size);
  font-weight: var(--_tab-bar-font-weight);
  letter-spacing: var(--_tab-bar-letter-spacing);
  text-transform: var(--_tab-bar-text-transform);
}

.tab-bar__inner {
  align-items: flex-start;
  column-gap: 1rem;
  display: flex;
}

.tab-bar__label {
  color: var(--_tab-bar-label-color);
  flex-shrink: 0;
  font-family: var(--_tab-bar-label-font-family);
  font-size: var(--_tab-bar-label-font-size);
  font-weight: var(--_tab-bar-label-font-weight);
  letter-spacing: var(--_tab-bar-label-letter-spacing);
  margin-inline-end: var(--_tab-bar-scroller-fade-width);
  text-transform: var(--_tab-bar-label-text-transform);
  text-transform: var(--_tab-bar-label-text-transform);
}

.tab-bar__scroller {
  display: flex;
  margin-inline: calc(var(--_tab-bar-scroller-fade-width) * -1);
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-padding-inline: var(--_tab-bar-scroller-fade-width);
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tab-bar__fade {
  display: block;
  flex-shrink: 0;
  inset-block: 0;
  position: sticky;
  width: var(--_tab-bar-scroller-fade-width);

  &:first-child {
    background-image: linear-gradient(to left, transparent, var(--_tab-bar-background-color));
    inset-inline-start: 0;
  }

  &:last-child {
    background-image: linear-gradient(to right, transparent, var(--_tab-bar-background-color));
    inset-inline-end: 0;
  }
}

.tab-bar__options {
  display: flex;
  gap: var(--_tab-bar-options-row-gap);
}

.tab-bar__option {
  display: block;
  font-family: var(--_tab-bar-option-font-family);
  font-size: var(--_tab-bar-option-font-size);
  font-weight: var(--_tab-bar-option-font-weight);
  letter-spacing: var(--_tab-bar-option-letter-spacing);
  padding-block-end: calc(var(--_tab-bar-option-underline-height) + var(--_tab-bar-option-underline-offset));
  scroll-snap-align: start;
  text-transform: var(--_tab-bar-option-text-transform);
  user-select: none;
}

.tab-bar__option-label {
  --underline-color: var(--_tab-bar-option-underline-color);
  --underline-height: var(--_tab-bar-option-underline-height);
  --underline-offset: var(--_tab-bar-option-underline-offset);

  color: var(--_tab-bar-option-color);
  cursor: pointer;
  display: block;
  user-select: none;

  &[aria-selected='true'] {
    --underline-color: var(--_tab-bar-option-underline-color--selected);

    color: var(--_tab-bar-option-color--selected);
  }
}
