.button {
  @include use-responsive-config(--button-height, responsive-map(xs 4rem, xl 5.2rem));

  // Button
  --_button-align: var(--button-align, flex-start);
  --_button-background-color: var(--button-background-color, var(--secondary-interactive-color));
  --_button-border-color: var(--button-border-color, transparent);
  --_button-border-radius: var(--button-border-radius, 0);
  --_button-border-style: var(--button-border-style, solid);
  --_button-border-width: var(--button-border-width, 0);
  --_button-color: var(--button-color, var(--secondary-interactive-background-color));
  --_button-font-size: var(--button-font-size, var(--font-size-paragraph));
  --_button-font-weight: var(--button-font-weight, var(--font-weight-bold));
  --_button-line-height: var(--button-line-height, var(--line-height-small));
  --_button-padding-inline: var(--button-padding-inline, 1.2em);
  --_button-text-align: var(--button-text-align, left);
  --_button-underline-color: var(--button-underline-color, transparent);
  --_button-underline-height: var(--button-underline-height, 0.2em);
  --_button-width: var(--button-width, auto);

  // Icon
  --_button-icon-background-color: var(--button-icon-background-color, transparent);
  --_button-icon-size: var(--button-icon-size, 1.2em);
  --_button-icon-text-gap: var(--button-icon-text-gap, -1.5em);
  --_button-icon-width: var(--button-icon-width, auto);
  --_button-icon-color: var(--button-icon-color, inherit);

  // :hover & :focus
  --_button-background-color--enter: var(--button-background-color--enter, var(--secondary-interactive-color--enter));
  --_button-border-color--enter: var(--button-border-color--enter, transparent);
  --_button-color--enter: var(--button-color--enter, var(--secondary-interactive-background-color--enter));
  --_button-icon-background-color--enter: var(--button-icon-background-color--enter, transparent);
  --_button-underline-color--enter: var(--button-underline-color--enter, transparent);

  // :active
  --_button-scale--active: var(--button-scale--active, 0.95);

  // Disabled
  --_button-background-color--disabled: var(--button-background-color--disabled, var(--secondary-interactive-color--disabled));
  --_button-border-color--disabled: var(--button-border-color--disabled, transparent);
  --_button-color--disabled: var(--button-color--disabled, var(--secondary-interactive-background-color--disabled));
  --_button-icon-background-color--disabled: var(--button-icon-background-color--disabled, transparent);
  --_button-underline-color--disabled: var(--button-underline-color--disabled, transparent);

  // Calculated values
  --_button-icon-padding-inline: var(--button-icon-padding-inline, calc(var(--_button-padding-inline) / 1.5));
  --_button-text-height: calc(var(--_button-font-size) * var(--_button-line-height));
  --_button-padding-block: calc((var(--_button-height) - var(--_button-text-height)) / 2);
  --_button-text-transform: var(--button-text-transform, none);

  background-color: var(--_button-background-color);
  border-color: var(--_button-border-color);
  border-radius: var(--_button-border-radius);
  border-style: var(--_button-border-style);
  color: var(--_button-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--_button-font-size);
  justify-content: var(--_button-align);
  text-align: var(--_button-text-align);
  transition-property: background-color, border-color, color, opacity, scale;
  user-select: none;
  width: var(--_button-width);

  &:is(:hover:not([disabled]), :focus-visible:not(.has-invisible-focus), .button--active),
  .has-button:is(:hover, :focus-within) &:not([disabled]) {
    --_button-icon-background-color: var(--_button-icon-background-color--enter);
    --_button-background-color: var(--_button-background-color--enter);
    --_button-border-color: var(--_button-border-color--enter);
    --_button-color: var(--_button-color--enter);
    --_button-underline-color: var(--_button-underline-color--enter);
  }

  &:not([disabled]):active {
    scale: var(--_button-scale--active);
  }

  &[disabled] {
    --_button-icon-background-color: var(--_button-icon-background-color--disabled);
    --_button-background-color: var(--_button-background-color--disabled);
    --_button-border-color: var(--_button-border-color--disabled);
    --_button-color: var(--_button-color--disabled);

    cursor: not-allowed;
  }
}

.button__icon {
  align-items: center;
  background-color: var(--_button-icon-background-color);
  color: var(--_button-icon-color);
  display: flex;
  font-size: var(--_button-icon-size);
  justify-content: center;
  line-height: 0;
  min-height: var(--_button-height);
  padding-inline: var(--_button-icon-padding-inline);
  width: var(--_button-icon-width);

  &:not(:first-child):last-child {
    margin-inline-start: var(--_button-icon-text-gap);
  }
}

.button__text {
  --underline-color: var(--_button-underline-color);
  --underline-color--enter: var(--_button-underline-color);
  --underline-height: var(--_button-underline-height);

  font-weight: var(--_button-font-weight);
  line-height: var(--_button-line-height);
  min-height: var(--_button-height);
  padding-block: var(--_button-padding-block, var(--_button-padding-block));
  padding-inline: var(--_button-padding-inline);
  text-transform: var(--_button-text-transform);

  &:not(:first-child):last-child {
    margin-inline-start: var(--_button-icon-text-gap);
  }
}
