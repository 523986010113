.skip-link {
  --focus-outline-width: 0;

  background-color: var(--skip-link-background-color, var(--text-color));
  color: var(--skip-link-color, var(--color-white));
  font-size: 2rem;
  font-weight: bold;
  inset-block-start: 0;
  inset-inline: 0;
  outline: 0;
  padding: 1.6rem 3.2rem;
  position: fixed;
  text-align: center;
  transform: translateY(-500%);
  z-index: z-index('skip-link');

  &:is(:hover, :focus, :active) {
    transform: translateY(0);
  }
}
