.loading {
  aspect-ratio: 1;
  background-color: var(--loading-color, var(--color-gray-300));
  border-radius: 50%;
  mask:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  mask-composite: subtract;
  padding: var(--loading-stroke-width, 0.8rem);
  width: var(--loading-size, 5rem);

  .is-loading & {
    animation: rotate 1s infinite linear;
  }
}
