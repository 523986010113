.card {
  --_card-background-color: var(--card-background-color, var(--text-color));
  --_card-color: var(--card-color, var(--color-white));

  // :hover, :focus & :active
  --_card-background-color--enter: var(--card-background-color--enter, var(--color-spd-red));
  --_card-color--enter: var(--card-color--enter, var(--color-white));
  --_card-inner-gap: 5cqi;
  --_card-icon-size: 10cqi;

  container-name: card;
  container-type: inline-size;
  position: relative;
}

.card__inner {
  align-content: space-between;
  align-items: flex-start;
  aspect-ratio: 32 / 45;
  background-color: var(--_card-background-color);
  color: var(--_card-color);
  display: flex;
  flex-wrap: wrap;
  gap: var(--_card-inner-gap);
  padding: var(--_card-inner-gap);
  transition-property: background-color, color;

  &:any-link:is(:hover, :focus-visible:not(.has-invisible-focus)) {
    --image-zoom: 1.1;

    background-color: var(--_card-background-color--enter);
    color: var(--_card-color--enter);
  }
}

.card__image {
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  margin-block-start: calc(var(--_card-inner-gap) * -1);
  margin-inline: calc(var(--_card-inner-gap) * -1);
  pointer-events: none;
  position: relative;
  width: calc(100cqi + var(--_card-inner-gap) * 2);
  z-index: 3;
}

.card__copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  pointer-events: all;
  position: absolute;
}

.card__headline {
  --headline-color: var(--_card-color);
  --headline-color--enter: var(--_card-color);
  --headline-font-size: 10cqi;
  --headline-hyphens: auto;
  --line-clamp: 3;

  flex-grow: 1;
  flex-shrink: 0;
  margin-block-end: auto;
  width: 100%;

  .card--with-image & {
    --headline-font-size: 8cqi;
    --line-clamp: 1;
  }
}

.card__description {
  --line-clamp: 4;

  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 7cqi;
  font-weight: var(--font-weight-regular);
  line-height: 10cqi;
  margin-block-start: auto;
  max-width: calc(100% - var(--_card-inner-gap) - var(--_card-icon-size));

  .card--with-image & {
    --line-clamp: 2;
  }
}

.card__icon {
  font-size: 10cqi;
  height: 10cqi;
  line-height: 0;
  margin-block-start: auto;
  margin-inline-start: auto;
}
