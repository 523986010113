.u-hidden-visually:not(:focus):not(:active) {
  @include use-hidden-visually();
}

.u-clearfix {
  @include use-clearfix();
}

.u-highlight {
  background-color: var(--highlight-background-color, var(--primary-color));
  color: var(--highlight-foreground-color, var(--color-white));
}

.u-tooltip {
  --_tooltip-triangle-size: var(--tooltip-triangle-size, 0.7rem);
  --_tooltip-triangle-size-negative: calc(var(--_tooltip-triangle-size) * -1);
  --_tooltip-triangle-offset-x: var(--tooltip-triangle-offset-x, 0);
  --_tooltip-triangle-offset-y: var(--tooltip-triangle-offset-y, 0);

  --selection-foreground-color: var(--tooltip-background-color);
  --selection-background-color: var(--tooltip-color);

  animation-duration: var(--duration-default);
  animation-name: opacity;
  background-color: var(--tooltip-background-color);
  border-radius: var(--tooltip-border-radius, 0);
  color: var(--tooltip-color);
  font-size: var(--tooltip-font-size, 1.4rem);
  inline-size: min-content;
  isolation: isolate;
  line-height: var(--tooltip-line-height, var(--line-height-default));
  min-inline-size: var(--tooltip-min-width, 20rem);
  padding-block: var(--tooltip-padding-block, 1rem);
  padding-inline: var(--tooltip-padding-inline, 1rem);
  position: fixed;
  text-align: var(--tooltip-text-align, left);
  transition-property: opacity;
  z-index: var(--tooltip-z-index, #{z-index('tooltip')});

  &[data-placement='top']::before,
  &[data-placement='bottom']::before {
    inset-inline-start: calc(var(--_tooltip-triangle-size-negative) + var(--_tooltip-triangle-offset-x));
  }

  &[data-placement='bottom']::before {
    border-block-end-color: var(--tooltip-background-color);
    border-block-start: 0;
    inset-block-start: var(--_tooltip-triangle-size-negative);
  }

  &[data-placement='top']::before {
    border-block-end: 0;
    border-block-start-color: var(--tooltip-background-color);
    inset-block-end: var(--_tooltip-triangle-size-negative);
  }

  &[data-placement='right']::before,
  &[data-placement='left']::before {
    inset-block-start: calc(var(--_tooltip-triangle-size-negative) + var(--_tooltip-triangle-offset-y));
  }

  &[data-placement='right']::before {
    border-inline-end-color: var(--tooltip-background-color);
    border-inline-start: 0;
    inset-inline-start: var(--_tooltip-triangle-size-negative);
  }

  &[data-placement='left']::before {
    border-inline-end: 0;
    border-inline-start-color: var(--tooltip-background-color);
    inset-inline-end: var(--_tooltip-triangle-size-negative);
  }

  &::before {
    block-size: 0;
    border: var(--_tooltip-triangle-size) solid transparent;
    content: '';
    inline-size: 0;
    position: absolute;
    z-index: 2;
  }
}

.u-overlay-link {
  &::after {
    content: '';
    inset: var(--overlay-link-inset, 0);
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    z-index: var(--overlay-link-z-index, 2);
  }
}

.has-overlay-link {
  position: relative;

  :not(.u-overlay-link):is(:any-link, button):not([disabled]) {
    position: relative;
    z-index: calc(var(--overlay-link-z-index, 2) + 1);
  }
}

.u-underline {
  text-decoration-color: var(--underline-color, transparent);
  text-decoration-line: var(--underline-line, underline);
  text-decoration-skip: var(--underline-skip, objects);
  text-decoration-skip-ink: var(--underline-skip-ink, auto);
  text-decoration-style: var(--underline-style, solid);
  text-decoration-thickness: var(--underline-height, 1px);
  text-underline-offset: var(--underline-offset, 0.25em);
  transition-duration: var(--underline-transition-duration, var(--duration-fast));
  transition-property: var(--underline-transition-property, text-decoration-color, color);

  &:is(button, :any-link):is(:focus-visible:not(.has-invisible-focus), :hover:not([disabled])),
  :is(button, :any-link, .has-underline):is(
    :focus-visible:not(.has-invisible-focus),
    :hover:not([disabled]),
    :focus-within:not(.has-invisible-focus)
  ) & {
    text-decoration-color: var(--underline-color--enter, currentColor);
  }
}

.u-line-clamp {
  -webkit-box-orient: vertical;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: var(--line-clamp, 5);
  overflow: hidden;
}

.js .u-hide-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
