.teaser {
  --date-square-block-size: var(--teaser-event-block-size, clamp(8rem, 33cqw, 12.6rem));
  --date-square-inline-size: var(--teaser-event-inline-size, clamp(8rem, 33cqw, 12.6rem));

  --_teaser-padding-block: var(--teaser-padding-block, 2rem);
  --_teaser-padding-inline: var(--teaser-padding-inline, 2rem);
  --_teaser-peak-out-offset: var(--teaser-peak-out-offset, 2rem);
  --_teaser-peak-out: var(--teaser-peak-out, 1);

  container-name: teaser;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  position: relative;
}

.teaser__event {
  margin-block-end: calc(var(--date-square-block-size) * -1 + var(--_teaser-peak-out-offset));
  margin-inline-start: var(--_teaser-padding-inline);
  position: relative;
  z-index: 4;
}

.teaser__inner {
  background-color: var(--teaser-background-color, var(--secondary-background-color));
  border-color: var(--teaser-border-color, transparent);
  border-radius: var(--teaser-border-radius, 0);
  border-style: var(--teaser-border-style, solid);
  border-width: var(--teaser-border-width, 0);
  color: var(--teaser-color, var(--text-color));
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .teaser__event + & {
    --_teaser-image-offset: calc((var(--date-square-block-size) - var(--_teaser-peak-out-offset)) * -1);

    padding-block-start: calc(var(--date-square-block-size) - var(--_teaser-peak-out-offset));
  }

  @container teaser (min-width: 60rem) {
    flex-direction: row;
  }
}

.teaser__image {
  align-self: flex-start;
  flex-shrink: 0;
  margin-block-start: var(--_teaser-image-offset, 0);
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: 3;

  @container teaser (min-width: 60rem) {
    max-width: 78.5rem;
    order: 1;
    padding-block: var(--_teaser-padding-block);
    padding-inline: var(--_teaser-padding-inline);
    width: 50%;
  }
}

.teaser__copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  pointer-events: all;
  position: absolute;

  @container teaser (min-width: 60rem) {
    inset-block-end: calc(1rem + var(--_teaser-padding-block));
    inset-inline-end: calc(1rem + var(--_teaser-padding-inline));
  }
}

.teaser__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;
  padding-block: var(--_teaser-padding-block);
  padding-inline: var(--_teaser-padding-inline);

  @container teaser (min-width: 80rem) {
    gap: 2rem;
  }
}

.teaser__meta {
  --_teaser-meta-font-size: var(--teaser-meta-font-size, var(--font-size-meta));

  color: var(--teaser-meta-color, inherit);
  font-size: 0;
  line-height: var(--line-height-body);
}

.teaser__date,
.teaser__sender {
  display: inline;
  font-size: var(--_teaser-meta-font-size);
}

.teaser__sender {
  font-weight: var(--font-weight-bold);

  &:not(:first-child)::before {
    content: '•\FEFF';
    margin-inline: 0.5em;
  }
}

.teaser__headline {
  --headline-font-size: var(--font-size-headline-4);
  --headline-hyphens: auto;
  --headline-color: var(--teaser-headline-color, var(--primary-heading-color));
  --headline-color--enter: var(--teaser-headline-color--enter, var(--primary-heading-color));

  display: block;

  @container teaser (min-width: 85rem) {
    --headline-hyphens: manual;
    --headline-font-size: var(--font-size-headline-2);
  }
}

.teaser__details {
  color: var(--teaser-details-color, inherit);
  display: grid;
  font-size: var(--teaser-details-font-size, var(--font-size-meta));
  line-height: var(--line-height-body);
  margin-block-start: 0.5rem;
  row-gap: 0.4em;
}

.teaser__detail {
  display: flex;
  gap: 0.5em;
}

.teaser__detail-icon {
  color: var(--teaser-details-icon-color, inherit);
  flex-shrink: 0;
}

.teaser__description {
  font-size: var(--teaser-description-font-size, var(--font-size-default));
  line-height: var(--line-height-body);

  > * + * {
    margin-block-start: 0.5em;
  }
}

.teaser__tags {
  --tag-font-size: 1.4rem;

  margin-block-start: auto;
  padding-block-start: 1rem;
}
