@use 'mq';

.stage {
  @include use-responsive-config(--stage-controls-font-size, responsive-map(xs 2.4rem, xl 3rem));

  --_stage-tiles-background-color: var(--stage-tiles-background-color, var(--primary-interactive-background-color));
  --_stage-tile-gap: var(--stage-tile-gap, 1rem);
  --_stage-tile-gap-color: var(--stage-tile-gap-color, var(--color-white));
  --_stage-tile-background-color: var(--stage-tile-background-color, var(--primary-interactive-color));
  --_stage-tile-border-color: var(--stage-tile-border-color, transparent);
  --_stage-tile-border-radius: var(--stage-tile-border-radius, 0);
  --_stage-tile-border-style: var(--stage-tile-border-style, solid);
  --_stage-tile-border-width: var(--stage-tile-border-width, 0);
  --_stage-tile-color: var(--stage-tile-color, var(--color-white));
  --_stage-tile-font-weight: var(--stage-tile-font-weight, var(--font-weight-bold));
  --_stage-tile-text-transform: var(--stage-tile-text-transform, none);
  --_stage-track-background-color: var(--stage-track-background-color, var(--secondary-background-color));

  // :hover, :focus & :active
  --_stage-tile-background-color--enter: var(--stage-tile-background-color--enter, var(--text-color));
  --_stage-tile-border-color--enter: var(--stage-tile-border-color--enter, transparent);
  --_stage-tile-color--enter: var(--stage-tile-color--enter, var(--color-white));

  container-name: stage;
  container-type: inline-size;
}

.stage--has-tiles {
  @include mq.mq($from: m) {
    --image-slide-copyright-inset-block: 4rem;
  }
}

.stage__inner {
  @include use-container($padding: false);

  background-color: var(--stage-inner-background-color, transparent);
}

.stage__track {
  background-color: var(--_stage-track-background-color);
  overflow: hidden;
  position: relative;
  transition-duration: var(--duration-xx-long);
  will-change: height;
  z-index: 1;

  .stage--initialized & {
    transition-property: height;
  }
}

.stage__slides {
  align-items: flex-start;
  display: flex;
  transition-duration: var(--duration-xx-long);
  transition-property: translate;
  will-change: translate;
}

.stage__slide {
  display: block;
  flex-shrink: 0;
  width: 100%;

  .stage:not(.stage--initialized) &:not(:first-child) {
    display: none;
  }
}

.stage__content {
  @include use-body-padding();

  min-height: calc(var(--_stage-controls-font-size) * 0.8 + 3rem);
  position: relative;
  z-index: 2;

  @include mq.mq($from: m) {
    margin-block-start: -3rem;

    &::before {
      background-color: var(--_stage-tiles-background-color);
      border-block-start: var(--_stage-tile-gap) solid var(--_stage-tile-gap-color);
      content: '';
      inset-block-end: 0;
      inset-block-start: 3rem;
      inset-inline: 0;
      position: absolute;
      z-index: -1;
    }
  }
}

.stage__controls {
  --icon-button-font-size: var(--_stage-controls-font-size);

  display: flex;
  gap: 0.5rem;
  position: absolute;
  translate: 0 -50%;
  z-index: 2;

  @include mq.mq($from: m) {
    inset-block-start: 3rem;
  }
}

.stage__tiles {
  column-gap: var(--_stage-tile-gap);
  display: grid;
  grid-auto-columns: clamp(12rem, calc(25% - var(--inner-container-padding) / 4 - 2rem / 4), 19.5rem);
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  padding: var(--_stage-tile-gap);
  position: relative;
  scrollbar-width: none;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mq.mq($until: m) {
    margin-inline: calc(var(--body-padding) * -1);
    padding-block: 2rem;
    padding-inline: var(--body-padding);

    .stage__controls + & {
      padding-block-start: 4rem;
    }
  }

  @include mq.mq($from: m) {
    margin-block-start: calc(var(--_stage-tile-gap) * -1);
    margin-inline-end: calc(var(--inner-container-padding) * -1);
    margin-inline-start: calc(column-offset(2) - var(--_stage-tile-gap));

    &::before {
      background-color: var(--_stage-tiles-background-color);
      border-block-start: var(--_stage-tile-gap) solid var(--_stage-tile-gap-color);
      content: '';
      inset: 0;
      position: absolute;
      z-index: -1;
    }
  }

  @include mq.mq($from: l) {
    margin-inline-start: calc(column-offset(4) - var(--_stage-tile-gap));
  }
}

.stage__tile {
  container-name: stage-tile;
  container-type: inline-size;
  display: block;
  position: relative;

  @include mq.mq($from: m) {
    &::before {
      background-color: var(--_stage-tile-gap-color);
      content: '';
      inset: calc(var(--_stage-tile-gap) * -1);
      position: absolute;
      z-index: -1;
    }
  }
}

.stage__tile-link {
  aspect-ratio: 1.05;
  background-color: var(--_stage-tile-background-color);
  border-color: var(--_stage-tile-border-color);
  border-radius: var(--_stage-tile-border-radius);
  border-style: var(--_stage-tile-border-style);
  border-width: var(--_stage-tile-border-width);
  color: var(--_stage-tile-color);
  display: block;
  font-size: 12.5cqi;
  font-weight: var(--_stage-tile-font-weight);
  line-height: var(--line-height-default);
  padding: 10cqi;
  text-transform: var(--_stage-tile-text-transform);
  transition-property: background-color, border-color, color;
  width: 100%;

  &:is(:hover, :focus-visible) {
    background-color: var(--_stage-tile-background-color--enter);
    border-color: var(--_stage-tile-border-color--enter);
    color: var(--_stage-tile-color--enter);
  }
}
