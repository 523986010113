.checkbox {
  --_checkbox-background-color: var(--checkbox-background-color, var(--control-background-color));
  --_checkbox-border-color: var(--checkbox-border-color, var(--control-border-color));
  --_checkbox-border-radius: var(--checkbox-border-radius, 0);
  --_checkbox-border-style: var(--checkbox-border-style, solid);
  --_checkbox-border-width: var(--checkbox-border-width, 0.2rem);
  --_checkbox-font-size: var(--checkbox-font-size, var(--font-size-meta));
  --_checkbox-gap: var(--checkbox-gap, 1.5rem);
  --_checkbox-icon-color: var(--checkbox-icon-color, var(--control-background-color));
  --_checkbox-line-height: var(--checkbox-line-height, 2.8rem);
  --_checkbox-required-color: var(--checkbox-required-color, var(--form-required-color));
  --_checkbox-size: var(--checkbox-size, 2.8rem);
  --_checkbox-text-color: var(--checkbox-text-color, var(--control-color));

  // :hover, :focus
  --_checkbox-border-color--checked: var(--checkbox-border-color--checked, var(--control-border-color));
  --_checkbox-background-color--checked: var(--checkbox-background-color--checked, var(--control-border-color));

  cursor: pointer;
  display: flex;
  gap: var(--_checkbox-gap);
  position: relative;
}

.checkbox--disabled {
  cursor: default;
  filter: grayscale(1);
  opacity: 0.2;
}

.checkbox__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;

  &:focus {
    outline: 0;
  }
}

.checkbox__box {
  align-items: center;
  background-color: var(--_checkbox-background-color);
  border-color: var(--_checkbox-border-color);
  border-radius: var(--_checkbox-border-radius);
  border-style: var(--_checkbox-border-style);
  border-width: var(--_checkbox-border-width);
  color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: calc(var(--_checkbox-size) / 2);
  height: var(--_checkbox-size);
  justify-content: center;
  transition-property: background-color, border-color, color;
  user-select: none;
  width: var(--_checkbox-size);

  .checkbox__input:checked ~ & {
    background-color: var(--_checkbox-background-color--checked);
    border-color: var(--_checkbox-border-color--checked);
    color: var(--_checkbox-icon-color);
  }

  .checkbox__input:focus-visible ~ & {
    @include use-focus-outline();
  }
}

.checkbox__label {
  color: var(--_checkbox-text-color);
  font-size: var(--_checkbox-font-size);
  line-height: var(--_checkbox-line-height);
  margin-block-start: calc((var(--_checkbox-size) - var(--_checkbox-line-height)) / 2);
  text-align: left;
}

.checkbox__required {
  margin-inline-start: 0.7rem;
}
