.slider {
  @include use-responsive-config(--slider-controls-font-size, responsive-map(xs 2.4rem, xl 3rem));
  @include use-responsive-config(--slider-navigation-margin-block, responsive-map(xs 2.4rem, xl 3.8rem));

  --_slider-scroll-bar-background-color: var(--slider-scroll-bar-background-color, var(--color-gray-050));
  --_slider-scroll-bar-foreground-color: var(--slider-scroll-bar-foreground-color, var(--color-gray-200));

  container-name: slider;
  container-type: inline-size;
}

.slider__items {
  @include use-break-out();

  column-gap: var(--gap);
  display: grid;
  grid-auto-columns: min(100%, 34rem);
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  padding-inline: var(--body-padding);
  position: relative;
  scroll-behavior: smooth;
  scroll-padding-inline: var(--body-padding);
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  z-index: 1;

  .slider--1 & {
    grid-auto-columns: 100%;
  }

  .slider--2 & {
    @container slider (min-width: 69rem) {
      grid-auto-columns: column-width(6);
    }
  }

  .slider--3 & {
    @container slider (min-width: 69rem) {
      grid-auto-columns: column-width(6);
    }

    @container slider (min-width: 92rem) {
      grid-auto-columns: column-width(4);
    }
  }

  &[data-state='dragging'] {
    scroll-behavior: auto;
    scroll-snap-type: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.slider__item {
  display: grid;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.slider__item--large {
  @container slider (min-width: 50rem) {
    .slider--3 & {
      grid-column: span 2;
    }
  }

  @container slider (min-width: 60rem) {
    .slider--2 & {
      grid-column: span 2;
    }
  }

  @container slider (min-width: 90rem) {
    .slider--3 & {
      grid-column: span 3;
    }
  }
}

.slider__navigation {
  align-items: center;
  column-gap: 2rem;
  display: flex;
  margin-block-start: var(--_slider-navigation-margin-block);
  opacity: 0.2;
  transition-property: opacity;

  &:is(:not([data-hidden])) {
    opacity: 1;
  }
}

.slider__controls {
  --icon-button-font-size: var(--_slider-controls-font-size);

  column-gap: 0.5rem;
  display: flex;
}

.slider__scroll-bar {
  background-color: var(--_slider-scroll-bar-background-color);
  cursor: default;
  flex-grow: 1;
  flex-shrink: 0;
  height: 1rem;
  position: relative;
}

.slider__scroll-bar-thumb {
  background-color: var(--_slider-scroll-bar-foreground-color);
  inset-block: 0;
  pointer-events: all;
  position: absolute;
  transform: translate3d(0, 0, 0);
  will-change: transform, width;
}
