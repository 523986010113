// stylelint-disable order/order

@use 'sass:color';
@use 'sass:map';
@use 'sass:meta';
@use 'sass:string';
@use 'sass:math';
@use 'sass:list';
@use 'mq';

/// use-fluid-sizing
/// Generate linear interpolated size values through multiple breakpoints
/// @param $properties - A string or list of CSS property name
/// @param $map - A Sass map of viewport unit and size value pairs
/// @requires function linear-interpolation
/// @requires function map-sort
/// @example
///   @include use-fluid-sizing('padding', (576px: 22px, 768px: 24px, 992px: 34px));
@mixin use-fluid-sizing($properties, $map) {
  // Convert into a list
  @if meta.type-of($properties) != list {
    $properties: ($properties);
  }

  // Get the number of provided breakpoints
  $length: list.length(map.keys($map));

  // Error if the number of breakpoints is < 2
  @if ($length < 2) {
    @error 'use-fluid-sizing() $map requires at least values';
  }

  // Sort the map by viewport width (key)
  // $map: map-sort($map);
  $keys: map.keys($map);

  // Minimum size
  @each $property in $properties {
    #{$property}: map.get($map, list.nth($keys, 1));
  }

  // Interpolated size through breakpoints
  @for $i from 1 through ($length - 1) {
    $min-width: list.nth($keys, $i);
    @if meta.type-of($min-width) == 'number' {
      $min-width: rem2px($min-width);
    }

    @if $min-width == 'xs' or (meta.type-of($min-width) == 'number' and $min-width < $root-min-width) {
      $min-width: rem2px($root-min-width);
    }

    @include mq.mq($from: $min-width) {
      $value1: map.get($map, list.nth($keys, $i));
      $value2: map.get($map, list.nth($keys, ($i + 1)));

      // If values are not equal, perform linear interpolation
      @if ($value1 != $value2) {
        @each $property in $properties {
          $key1: list.nth($keys, $i);
          $key2: list.nth($keys, ($i + 1));

          @if meta.type-of($key1) == 'string' {
            $key1: harmonize-value(map.get($breakpoints, $key1), $value1);

            @if ($key1 < harmonize-value($root-min-width, $value1)) {
              $key1: harmonize-value($root-min-width, $value1);
            }
          }

          @if meta.type-of($key2) == 'string' {
            $key2: harmonize-value(map.get($breakpoints, $key2), $value2);
          }

          #{$property}: linear-interpolation((
            $key1: $value1,
            $key2: $value2,
          ));
        }
      } @else {
        @each $property in $properties {
          #{$property}: $value1;
        }
      }
    }
  }

  // Maximum size
  $max-width: list.nth($keys, $length);
  @if meta.type-of($max-width) == 'number' {
    $max-width: rem2px($max-width);
  }

  @include mq.mq($from: $max-width) {
    @each $property in $properties {
      #{$property}: map.get($map, list.nth($keys, $length));
    }
  }
}

/// use-responsive-sizing
/// Generate values through multiple breakpoints
/// @param $properties - A string or list of CSS property name
/// @param $breakpoint-map - A Sass map of breakpoints and the value for the breakpoint
/// @param $start-breakpoint - Starting breakpoint
/// @param $end-breakpoint - Ending breakpoint
/// @param $scoped - Is the last breakpoint open end?
/// @requires function list-slice
/// @requires function px2rem
/// @example
///   @include use-responsive-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
@mixin use-responsive-sizing(
  $properties,
  $breakpoint-map,
  $start-breakpoint: null,
  $end-breakpoint: null,
  $scoped: false
) {
  $breakpoint-map: normalize-breakpoint-map($breakpoint-map);

  @if $start-breakpoint == null {
    $start-breakpoint: list.nth(map.keys($breakpoint-map), 1);
  }

  @if $end-breakpoint == null {
    $end-breakpoint: list.nth(map.keys($breakpoint-map), list.length(map.keys($breakpoint-map)));
  }

  $all-breakpoints: map.keys($breakpoint-map);
  $start-index: list.index($all-breakpoints, $start-breakpoint);
  $end-index: list.index($all-breakpoints, $end-breakpoint);
  $breakpoints: list-slice($all-breakpoints, $start-index, $end-index);

  @if meta.type-of($properties) != list {
    $properties: ($properties);
  }

  @each $breakpoint in $breakpoints {
    $index: list.index($all-breakpoints, $breakpoint);
    $next-breakpoint: false;

    @if $scoped and list.length($all-breakpoints) != $index {
      $next-breakpoint: list.nth($all-breakpoints, $index + 1);
    }

    @include mq.mq($from: $breakpoint, $until: $next-breakpoint) {
      $value: map.get($breakpoint-map, $breakpoint);

      @if meta.type-of($value) == number and math.unit($value) == px {
        $value: px2rem($value);
      }

      @each $property in $properties {
        #{$property}: $value;
      }
    }
  }
}

/// use-responsive-config
/// Generate values through multiple breakpoints with an internal and external variable
/// @param $variables - A string or list of CSS variables
/// @param $breakpoint-map - A Sass map of breakpoints and the value for the breakpoint
/// @param $start-breakpoint - Starting breakpoint
/// @param $end-breakpoint - Ending breakpoint
/// @param $scoped - Is the last breakpoint open end?
/// @requires function list-slice
/// @requires function px2rem
/// @example
///   @include use-responsive-sizing(--my-component-font-size, (576px: 22px, 768px: 24px, 992px: 34px));
@mixin use-responsive-config(
  $variables,
  $breakpoint-map,
  $start-breakpoint: null,
  $end-breakpoint: null,
  $scoped: false
) {
  $breakpoint-map: normalize-breakpoint-map($breakpoint-map);

  @if $start-breakpoint == null {
    $start-breakpoint: list.nth(map.keys($breakpoint-map), 1);
  }

  @if $end-breakpoint == null {
    $end-breakpoint: list.nth(map.keys($breakpoint-map), list.length(map.keys($breakpoint-map)));
  }

  $all-breakpoints: map.keys($breakpoint-map);
  $start-index: list.index($all-breakpoints, $start-breakpoint);
  $end-index: list.index($all-breakpoints, $end-breakpoint);
  $breakpoints: list-slice($all-breakpoints, $start-index, $end-index);

  @if meta.type-of($variables) != list {
    $variables: ($variables);
  }

  @each $breakpoint in $breakpoints {
    $index: list.index($all-breakpoints, $breakpoint);
    $next-breakpoint: false;

    @if $scoped and list.length($all-breakpoints) != $index {
      $next-breakpoint: list.nth($all-breakpoints, $index + 1);
    }

    @include mq.mq($from: $breakpoint, $until: $next-breakpoint) {
      $value: map.get($breakpoint-map, $breakpoint);

      @if meta.type-of($value) == number and math.unit($value) == px {
        $value: px2rem($value);
      }

      @each $variable in $variables {
        $internal-variable: '--_' + string.slice($variable, $start-at: 3);

        #{$internal-variable}: var(#{$variable}, $value);
      }
    }
  }
}

/// use-column-padding
/// Uses responsive column width
/// @param $properties - A string or list of CSS property name
/// @param $column-map - A Sass map of breakpoints and the value for the breakpoint
/// @param $start-breakpoint - Starting breakpoint
/// @param $end-breakpoint - Ending breakpoint
/// @param $scoped - Is the last breakpoint open end?
@mixin use-column-width($properties, $column-map, $start-breakpoint: null, $end-breakpoint: null, $scoped: false) {
  $column-map: normalize-breakpoint-map($column-map);
  $breakpoint-map: ();

  @each $breakpoint, $column in $column-map {
    @if meta.type-of($column) != list {
      $column: ($column);
    }

    $column-count: list.nth($column, 1);
    $number-of-columns: $columns;

    @if list.length($column) == 3 and list.nth($column, 2) == 'of' {
      $number-of-columns: list.nth($column, 3);
    }

    $breakpoint-map: map.set($breakpoint-map, $breakpoint, column-width($column-count, $number-of-columns));
  }

  @include use-responsive-sizing($properties, $breakpoint-map, $start-breakpoint, $end-breakpoint, $scoped);
}

/// use-column-padding
/// Uses responsive column offset
/// @param $properties - A string or list of CSS property name
/// @param $column-map - A Sass map of breakpoints and the value for the breakpoint
/// @param $start-breakpoint - Starting breakpoint
/// @param $end-breakpoint - Ending breakpoint
/// @param $scoped - Is the last breakpoint open end?
@mixin use-column-offset($properties, $column-map, $start-breakpoint: null, $end-breakpoint: null, $scoped: false) {
  $column-map: normalize-breakpoint-map($column-map);
  $breakpoint-map: ();

  @each $breakpoint, $column in $column-map {
    @if meta.type-of($column) != list {
      $column: ($column);
    }

    $column-count: list.nth($column, 1);
    $number-of-columns: $columns;

    @if list.length($column) == 3 and list.nth($column, 2) == 'of' {
      $number-of-columns: list.nth($column, 3);
    }

    $breakpoint-map: map.set($breakpoint-map, $breakpoint, column-offset($column-count, $number-of-columns));
  }

  @include use-responsive-sizing($properties, $breakpoint-map, $start-breakpoint, $end-breakpoint, $scoped);
}

/// use-body-padding
/// Adds body padding
/// @param $padding - Padding in vw
/// @param $max-padding - Max Padding in rem
/// @param $start-breakpoint - Start breakpoint, defaults to xs
/// @param $end-breakpoint - End breakpoint, defaults to xl
/// @param $scoped - Scopes padding between start and end breakpoints
/// @param $max-width - Maximal width of container
/// @param $sides - To which sides to apply padding
/// @param $negative - Make body padding negative
@mixin use-body-padding(
  $padding: $inner-container-padding,
  $min-padding: $inner-container-min-padding,
  $max-padding: $inner-container-max-padding,
  $start-breakpoint: null,
  $end-breakpoint: null,
  $scoped: false,
  $min-width: $root-min-width,
  $max-width: $inner-container-max-width,
  $properties: (
    padding-inline,
  ),
  $negative: false
) {
  @if $start-breakpoint == null {
    $start-breakpoint: list.nth(map.keys($padding), 1);
  }

  @if $end-breakpoint == null {
    $end-breakpoint: list.nth(map.keys($padding), list.length(map.keys($padding)));
  }

  @if meta.type-of($negative) == 'bool' and $negative == true {
    $padding: map-multiply($padding, -1);
    $max-padding: $max-padding * -1;
  }

  @include use-responsive-sizing($properties, $padding, $start-breakpoint, $end-breakpoint, $scoped);

  @include mq.mq($until: rem2px($root-min-width)) {
    @each $property in $properties {
      #{$property}: $min-padding;
    }
  }

  @include mq.mq($from: rem2px($max-width)) {
    @each $property in $properties {
      #{$property}: $max-padding;
    }
  }
}

/// use-container
/// Generate a container with a maximal width
/// @param $max-width - Maximal width
/// @param $padding - Padding in vw
/// @param $max-padding - Max Padding in rem
@mixin use-container(
  $max-width: $inner-container-max-width,
  $min-width: $root-min-width,
  $padding: $inner-container-padding,
  $max-padding: $inner-container-max-padding,
  $min-padding: $inner-container-min-padding,
  $start-breakpoint: null,
  $end-breakpoint: null,
  $scoped: false
) {
  @if meta.type-of($padding) == 'map' {
    @if $start-breakpoint == null {
      $start-breakpoint: list.nth(map.keys($padding), 1);
    }

    @if $end-breakpoint == null {
      $end-breakpoint: list.nth(map.keys($padding), list.length(map.keys($padding)));
    }

    @include use-body-padding(
      $padding: $padding,
      $min-padding: $min-padding,
      $max-padding: $max-padding,
      $start-breakpoint: $start-breakpoint,
      $end-breakpoint: $end-breakpoint,
      $scoped: $scoped,
      $max-width: $max-width,
      $min-width: $min-width
    );
  }

  margin-inline: auto;
  max-width: $max-width;
  width: 100%;
}

/// use-break-out
/// Breaks out of a container
@mixin use-break-out() {
  margin-inline: calc((max(100vw, var(--root-min-width)) - 100%) / -2);
}

/// use-scrim-gradient
/// Generate a smooth gradient from a color into transparent
/// @param $start-color - Start color
/// @param $direction - Gradient direction
@mixin use-scrim-gradient($start-color: #000, $direction: 'to bottom') {
  $scrim-coordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0,
  );

  $hue: color.channel($start-color, 'hue', $space: hsl);
  $saturation: color.channel($start-color, 'saturation', $space: hsl);
  $lightness: color.channel($start-color, 'lightness', $space: hsl);
  $stops: ();

  @each $color-stop, $alpha-value in $scrim-coordinates {
    $stop: hsla($hue, $saturation, $lightness, $alpha-value) math.percentage(math.div($color-stop, 100));
    $stops: list.append($stops, $stop, comma);
  }

  background-image: linear-gradient(string.unquote($direction), $stops);
}

/// use-focus-outline
/// Generate a outline
@mixin use-focus-outline() {
  outline: var(--focus-outline-width, max(2px, 0.15em)) solid var(--focus-outline-color, currentColor);
  outline-offset: var(--focus-outline-offset, max(2px, 0.15em));
}

/// use-triangle-cut-out
/// Adds an triangle cut-out clip path
/// @param $width - Triangle width
/// @param $height - Triangle height
/// @param $x - Position from left
/// @param $y - Position from top
// stylelint-disable-next-line length-zero-no-unit
@mixin use-triangle-cut-out($width: 1rem, $height: 1rem, $x: 0px, $y: 0px) {
  $ba: math.div(360deg, 3);
  $p: ();
  $factor: 0.85714;

  @for $i from 0 to 3 {
    $ca: -90deg - 0.5 * $ba - $i * $ba;
    /* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
    $p:
      $p,
      calc(#{$x} + #{$width * $factor * math.cos($ca)}) calc(#{$y} + #{$height * math.sin($ca)});
  }

  clip-path: polygon(#{$p}, 100% 0, 100% 100%, 0 100%, 0 0);
}

/// use-hidden-visually
/// Hides element visually but not from the a11y tree
@mixin use-hidden-visually() {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/// use-clearfix
/// Generate a clearfix
@mixin use-clearfix() {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}
