.field {
  --_field-add-group-margin-block: var(--field-add-group-margin-block, 2rem);
  --_field-controls-column-gap: var(--field-controls-column-gap, 2rem);
  --_field-controls-row-gap: var(--field-controls-row-gap, 1.2rem);
  --_field-errors-background-color: var(--field-errors-background-color, var(--color-spd-red));
  --_field-errors-color: var(--field-errors-color, var(--color-white));
  --_field-errors-font-size: var(--field-errors-font-size, 1.4rem);
  --_field-errors-font-weight: var(--field-errors-font-weight, var(--font-weight-bold));
  --_field-errors-margin-block: var(--field-errors-margin-block, 1.5rem);
  --_field-errors-text-color: var(--field-errors-text-color, var(--color-spd-red));
  --_field-groups-column-gap: var(--field-groups-column-gap, var(--gap));
  --_field-groups-margin-block: var(--field-groups-margin-block, 2rem);
  --_field-groups-row-gap: var(--field-groups-row-gap, 3rem);
  --_field-instructions-margin-block: var(--field-instructions-margin-block, 0.7rem);
  --_field-label-margin-block: var(--field-label-margin-block, 1rem);
  --_field-repeatable-group-background-color: var(--field-repeatable-group-background-color, var(--color-gray-050));
  --_field-repeatable-group-border-color: var(--field-repeatable-group-border-color, transparent);
  --_field-repeatable-group-border-radius: var(--field-repeatable-group-border-radius, 0);
  --_field-repeatable-group-border-style: var(--field-repeatable-group-border-style, solid);
  --_field-repeatable-group-border-width: var(--field-repeatable-group-border-width, 0);
  --_field-repeatable-group-counter-background-color: var(--field-repeatable-group-counter-background-color, var(--color-white));
  --_field-repeatable-group-counter-border-color: var(--field-repeatable-group-counter-border-color, transparent);
  --_field-repeatable-group-counter-border-radius: var(--field-repeatable-group-counter-border-radius, 50%);
  --_field-repeatable-group-counter-border-style: var(--field-repeatable-group-counter-border-style, solid);
  --_field-repeatable-group-counter-border-width: var(--field-repeatable-group-counter-border-width, 0);
  --_field-repeatable-group-counter-color: var(--field-repeatable-group-counter-color, var(--text-color));
  --_field-repeatable-group-counter-size: var(--field-repeatable-group-counter-size, 2.4rem);
  --_field-repeatable-group-delete-margin-block: var(--field-repeatable-group-delete-margin-block, 2rem);
  --_field-repeatable-group-delete-size: var(--field-repeatable-group-delete-size, 1.6rem);
  --_field-repeatable-group-padding-block: var(--field-repeatable-group-padding-block, 2rem);
  --_field-repeatable-group-padding-inline: var(--field-repeatable-group-padding-inline, 2rem);

  > :first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

.field__label {
  display: block;
  float: left;
  margin-block: var(--_field-label-margin-block);
  padding-inline: 0;
  width: 100%;

  + * {
    clear: both;
  }
}

.field__errors {
  --notice-color: var(--_field-errors-text-color);
  --notice-icon-color: var(--_field-errors-color);
  --notice-icon-background-color: var(--_field-errors-background-color);

  font-size: var(--_field-errors-font-size);
  font-weight: var(--_field-errors-font-weight);
  margin-block-start: var(--_field-errors-margin-block);
}

.field__controls {
  clear: both;
  column-gap: var(--_field-controls-column-gap);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: var(--_field-controls-row-gap);
}

.field__controls--stacked {
  flex-direction: column;
  flex-wrap: nowrap;
}

.field__control {
  --horizontal-rule-margin-block: var(--_field-groups-row-gap);

  flex-grow: 1;
  min-width: min-content;
}

.field__groups {
  clear: both;
  counter-reset: field-groups;
  margin-block: var(--_field-groups-margin-block);
}

.field__group {
  position: relative;

  & + & {
    margin-block-start: var(--_field-groups-row-gap);
  }
}

.field__groups--repeatable .field__group {
  background-color: var(--_field-repeatable-group-background-color);
  border-color: var(--_field-repeatable-group-border-color);
  border-radius: var(--_field-repeatable-group-border-radius);
  border-style: var(--_field-repeatable-group-border-style);
  border-width: var(--_field-repeatable-group-border-width);
  counter-increment: field-groups;
  margin-block-end: var(--_field-groups-row-gap);
  padding-block: var(--_field-repeatable-group-padding-block);
  padding-inline: var(--_field-repeatable-group-padding-inline);

  &::before {
    background-color: var(--_field-repeatable-group-counter-background-color);
    border-color: var(--_field-repeatable-group-counter-border-color);
    border-radius: var(--_field-repeatable-group-counter-border-radius);
    border-style: var(--_field-repeatable-group-counter-border-style);
    border-width: var(--_field-repeatable-group-counter-border-width);
    color: var(--_field-repeatable-group-counter-color);
    content: counter(field-groups);
    font-size: calc(var(--_field-repeatable-group-counter-size) * 0.6);
    height: var(--_field-repeatable-group-counter-size);
    inset-block-start: var(--_field-repeatable-group-padding-block);
    inset-inline-start: var(--_field-repeatable-group-padding-inline);
    line-height: calc(var(--_field-repeatable-group-counter-size) - 2px);
    position: absolute;
    text-align: center;
    width: var(--_field-repeatable-group-counter-size);
  }
}

.field__group-row {
  display: grid;
  gap: var(--_field-groups-column-gap);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  & + & {
    margin-block-start: var(--_field-groups-row-gap);
  }
}

.field__group-delete {
  --icon-button-size: var(--_field-repeatable-group-delete-size);

  margin-block-end: var(--_field-repeatable-group-delete-margin-block);
  text-align: right;
}

.field__group-field {
  --label-font-weight: var(--font-weight-regular);
}

.field__instructions {
  font-size: 1.4rem;
  margin-block: var(--_field-instructions-margin-block);
}
