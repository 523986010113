.banderole {
  --_banderole-font-size: var(--banderole-font-size, var(--font-size-headline-5));
  --_banderole-background-color: var(--banderole-background-color, var(--color-black));
  --_banderole-color: var(--banderole-color, var(--color-white));

  --selection-background-color: var(--_banderole-color);
  --selection-foreground-color: var(--_banderole-background-color);

  align-items: center;
  background-color: var(--_banderole-background-color);
  color: var(--_banderole-color);
  column-gap: var(--banderole-column-gap, var(--gap));
  display: flex;
  flex-wrap: wrap;
  font-size: var(--_banderole-font-size);
  justify-content: space-between;
  padding-block: var(--banderole-padding-block, 2rem);
  padding-inline: var(--banderole-padding-block, min(5rem, var(--inner-container-padding)));
  position: relative;
  row-gap: var(--banderole-row-gap, 1rem);
}

.banderole__text {
  font-size: 1em;
  font-weight: var(--banderole-font-weight, var(--font-weight-bold));
  line-height: var(--line-height-small);
  text-transform: var(--banderole-text-transform, none);
}

.banderole__button {
  --icon-button-color--enter: var(--_banderole-color);
  --icon-button-color: var(--_banderole-color);
  --icon-button-icon-background-color--enter: transparent;
  --icon-button-icon-background-color: transparent;
  --icon-button-icon-color--enter: var(--_banderole-color);
  --icon-button-icon-color: var(--_banderole-color);
  --icon-button-icon-padding: 0;
  --icon-button-icon-size: 0.8em;
  --icon-button-underline-color--enter: currentColor;
  --icon-button-font-size: var(--banderole-button-font-size, 0.9em);

  line-height: 0;
}
