.notice {
  --_notice-color: var(--notice-color, var(--text-color));
  --_notice-font-size: var(--notice-font-size, inherit);
  --_notice-icon-color: var(--notice-icon-color, var(--color-white));
  --_notice-icon-background-color: var(--notice-icon-background-color, var(--text-color));
  --_notice-icon-size: var(--notice-icon-size, 1.2em);
  --_notice-gap: var(--notice-gap, 0.75em);

  align-items: flex-start;
  color: var(--_notice-color);
  display: flex;
  font-size: var(--_notice-font-size);
  gap: var(--_notice-gap);
}

.notice__icon {
  align-items: center;
  background-color: var(--_notice-icon-background-color);
  color: var(--_notice-icon-color);
  display: flex;
  flex-shrink: 0;
  font-size: var(--_notice-icon-size);
  height: var(--_notice-icon-size);
  justify-content: center;
  line-height: 0;
  width: var(--_notice-icon-size);
}

.notice__text {
  --link-color--enter: var(--text-color);
  --link-color: var(--_notice-color);
  --link-icon-color: var(--_notice-color);
  --link-icon-size: 0.8em;
  --link-underline-color: var(--_notice-color);
  --link-underline-height: 1px;
  --link-underline-offset: 0.2em;

  line-height: var(--line-height-body);
}
