/*!
 * @author Dennis Morhardt <info@dennismorhardt.de>
 */
@charset 'UTF-8';

/**
 * Abstracts
 */
@import 'abstracts/colors';
@import 'abstracts/configuration';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/globals';
@import 'abstracts/keyframes';

/**
 * Base
 */
@import 'base/reset';
@import 'base/fonts';
@import 'base/root';
@import 'base/body';
@import 'base/utils';
@import 'base/focus';
@import 'base/reduced-motion';
@import 'base/selection';

/**
 * Particles
 */
@import '../components/0-particles/icon/icon';
@import '../components/0-particles/loading/loading';
@import '../components/0-particles/preview/preview';
@import '../components/0-particles/required/required';

/**
 * Atoms
 */
@import '../components/1-atoms/alert/alert';
@import '../components/1-atoms/bullet-list/bullet-list';
@import '../components/1-atoms/button/button';
@import '../components/1-atoms/checkbox/checkbox';
@import '../components/1-atoms/copyright/copyright';
@import '../components/1-atoms/date-square/date-square';
@import '../components/1-atoms/dropdown/dropdown';
@import '../components/1-atoms/external-video/external-video';
@import '../components/1-atoms/headline/headline';
@import '../components/1-atoms/horizontal-rule/horizontal-rule';
@import '../components/1-atoms/icon-button/icon-button';
@import '../components/1-atoms/image/image';
@import '../components/1-atoms/input/input';
@import '../components/1-atoms/label/label';
@import '../components/1-atoms/link-preview/link-preview';
@import '../components/1-atoms/link-tile/link-tile';
@import '../components/1-atoms/link/link';
@import '../components/1-atoms/notice/notice';
@import '../components/1-atoms/paragraph/paragraph';
@import '../components/1-atoms/progress/progress';
@import '../components/1-atoms/radio/radio';
@import '../components/1-atoms/skip-link/skip-link';
@import '../components/1-atoms/switch/switch';
@import '../components/1-atoms/table/table';
@import '../components/1-atoms/tag/tag';
@import '../components/1-atoms/toggle/toggle';

/**
 * Molecules
 */
@import '../components/2-molecules/accordion/accordion';
@import '../components/2-molecules/app/app';
@import '../components/2-molecules/box/box';
@import '../components/2-molecules/breadcrumb/breadcrumb';
@import '../components/2-molecules/browser/browser';
@import '../components/2-molecules/buttons/buttons';
@import '../components/2-molecules/card/card';
@import '../components/2-molecules/details-box/details-box';
@import '../components/2-molecules/election-slide/election-slide';
@import '../components/2-molecules/embed/embed';
@import '../components/2-molecules/external-video-slide/external-video-slide';
@import '../components/2-molecules/field/field';
@import '../components/2-molecules/grid-teaser/grid-teaser';
@import '../components/2-molecules/image-slide/image-slide';
@import '../components/2-molecules/links/links';
@import '../components/2-molecules/list/list';
@import '../components/2-molecules/media/media';
@import '../components/2-molecules/pagination/pagination';
@import '../components/2-molecules/quote/quote';
@import '../components/2-molecules/social-media-post/social-media-post';
@import '../components/2-molecules/tab-bar/tab-bar';
@import '../components/2-molecules/tags/tags';
@import '../components/2-molecules/teaser-slide/teaser-slide';
@import '../components/2-molecules/teaser/teaser';
@import '../components/2-molecules/text-breaker/text-breaker';
@import '../components/2-molecules/tile/tile';

/**
 * Organisms
 */
@import '../components/3-organisms/archive/archive';
@import '../components/3-organisms/banderole/banderole';
@import '../components/3-organisms/bubble/bubble';
@import '../components/3-organisms/calendar/calendar';
@import '../components/3-organisms/footer/footer';
@import '../components/3-organisms/form-breaker/form-breaker';
@import '../components/3-organisms/form/form';
@import '../components/3-organisms/gallery/gallery';
@import '../components/3-organisms/grid/grid';
@import '../components/3-organisms/header/header';
@import '../components/3-organisms/input-form/input-form';
@import '../components/3-organisms/menu/menu';
@import '../components/3-organisms/overlay/overlay';
@import '../components/3-organisms/page/page';
@import '../components/3-organisms/portal/portal';
@import '../components/3-organisms/profile/profile';
@import '../components/3-organisms/search-overlay/search-overlay';
@import '../components/3-organisms/section/section';
@import '../components/3-organisms/slider/slider';
@import '../components/3-organisms/social-media-posts/social-media-posts';
@import '../components/3-organisms/stage/stage';
@import '../components/3-organisms/teaser-grid/teaser-grid';

/**
 * Layouts
 */
@import 'layouts/site';

/**
 * Themes
 */
@import 'themes/spd-2023';
@import 'themes/spd-mvp-2023';
@import 'themes/spd-nds-2022';
