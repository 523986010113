.dropdown {
  --_dropdown-toggle-background-color: var(--dropdown-toggle-background-color, var(--secondary-interactive-color));
  --_dropdown-toggle-border-color: var(--dropdown-toggle-border-color, transparent);
  --_dropdown-toggle-border-radius: var(--dropdown-toggle-border-radius, 1.5rem/50%);
  --_dropdown-toggle-border-style: var(--dropdown-toggle-border-style, solid);
  --_dropdown-toggle-border-width: var(--dropdown-toggle-border-width, 0);
  --_dropdown-toggle-color: var(--dropdown-toggle-color, var(--secondary-interactive-background-color));
  --_dropdown-toggle-font-size: var(--dropdown-toggle-font-size, var(--font-size-meta));
  --_dropdown-toggle-padding-block: var(--dropdown-toggle-padding-block, 0.4em);
  --_dropdown-toggle-padding-inline: var(--dropdown-toggle-padding-inline, 1em);
  --_dropdown-toggle-text-transform: var(--dropdown-toggle-text-transform, none);
  --_dropdown-toggle-underline-color: var(--dropdown-toggle-underline-color, transparent);
  --_dropdown-toggle-underline-height: var(--dropdown-toggle-underline-height, 0);

  // Options
  --_dropdown-options-background-color: var(--dropdown-options-background-color, var(--secondary-interactive-background-color));
  --_dropdown-options-border-color: var(--dropdown-options-border-color, var(--secondary-interactive-color));
  --_dropdown-options-border-style: var(--dropdown-options-border-style, solid);
  --_dropdown-options-border-width: var(--dropdown-options-border-width, 2px);
  --_dropdown-options-font-size: var(--dropdown-options-font-size, 2rem);
  --_dropdown-options-padding-block: var(--dropdown-options-padding-block, 2.4rem);
  --_dropdown-options-padding-inline: var(--dropdown-options-padding-inline, 2.4rem);

  // Option
  --_dropdown-option-color: var(--dropdown-option-color, var(--secondary-interactive-color));
  --_dropdown-option-background-color: var(--dropdown-option-background-color, transparent);
  --_dropdown-option-color--selected: var(--dropdown-option-color--selected, var(--secondary-interactive-background-color));
  --_dropdown-option-background-color--selected: var(--dropdown-option-background-color--selected, var(--secondary-interactive-color));
  --_dropdown-option-color--enter: var(--dropdown-option-color--enter, var(--secondary-interactive-color));
  --_dropdown-option-background-color--enter: var(--dropdown-option-background-color--enter, var(--secondary-background-color));

  // :hover, :focus & :active
  --_dropdown-toggle-background-color--enter: var(--dropdown-toggle-background-color--enter, var(--secondary-interactive-color--enter));
  --_dropdown-toggle-border-color--enter: var(--dropdown-toggle-border-color--enter, transparent);
  --_dropdown-toggle-color--enter: var(--dropdown-toggle-color--enter, var(--secondary-interactive-background-color--enter));
  --_dropdown-toggle-underline-color--enter: var(--dropdown-toggle-underline-color--enter, transparent);

  display: inline-block;
  text-align: left;
  user-select: none;

  :focus-visible:not(.has-invisible-focus)::before {
    @include use-focus-outline();

    --focus-outline-offset: 0;
    --focus-outline-width: 3px;

    content: '';
    inset: 0;
    pointer-events: none;
    position: absolute;
  }
}

.dropdown__toggle {
  --focus-outline-width: 0;
  --underline-color: var(--_dropdown-toggle-underline-color);
  --underline-color--enter: var(--_dropdown-toggle-underline-color--enter);
  --underline-height: var(--_dropdown-toggle-underline-height);

  align-items: center;
  background-color: var(--_dropdown-toggle-background-color);
  border-color: var(--_dropdown-toggle-border-color);
  border-radius: var(--_dropdown-toggle-border-radius);
  border-style: var(--_dropdown-toggle-border-style);
  border-width: var(--_dropdown-toggle-border-width);
  color: var(--_dropdown-toggle-color);
  column-gap: 0.5em;
  display: inline-flex;
  font-size: var(--_dropdown-toggle-font-size);
  font-weight: var(--_dropdown-toggle-font-weight);
  line-height: var(--line-height-small);
  padding-block: var(--_dropdown-toggle-padding-block);
  padding-inline: var(--_dropdown-toggle-padding-inline);
  text-transform: var(--_dropdown-toggle-text-transform);
  transition-duration: var(--duration-fast);
  transition-property: background-color, border-color, color;

  &:is(:hover, :focus-visible) {
    background-color: var(--_dropdown-toggle-background-color--enter);
    border-color: var(--_dropdown-toggle-border-color--enter);
    color: var(--_dropdown-toggle-color--enter);
  }
}

.dropdown__toggle-icon {
  --icon-size: 1.2em;

  flex-shrink: 0;
}

.dropdown__toggle-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown__toggle-arrow {
  --icon-size: 0.7em;

  flex-shrink: 0;
}

.dropdown__menu {
  animation: opacity var(--duration-default);
  background-color: var(--_dropdown-options-background-color);
  border-block-start: 0;
  border-color: var(--_dropdown-options-border-color);
  border-style: var(--_dropdown-options-border-style);
  border-width: var(--_dropdown-options-border-width);
  font-size: var(--_dropdown-options-font-size);
  line-height: var(--line-height-small);
  max-width: calc(100vw - var(--inner-container-padding) * 2);
  padding-block: calc(var(--_dropdown-options-padding-block) / 2);
  position: absolute;
  width: 40rem;
  z-index: z-index('dropdown');

  &[hidden] {
    display: none;
  }
}

.dropdown__items {
  display: grid;
}

.dropdown__item {
  display: block;
}

.dropdown__option {
  --_dropdown-option-check-color: transparent;

  align-items: center;
  background-color: var(--_dropdown-option-background-color);
  color: var(--_dropdown-option-color);
  cursor: pointer;
  display: flex;
  gap: 1em;
  padding-block: calc(var(--_dropdown-options-padding-block) / 2);
  padding-inline: var(--_dropdown-options-padding-inline);
  text-align: left;
  transition-property: background-color, color;
  width: 100%;

  &[hidden] {
    display: none;
  }

  &:is([aria-selected='true'], [aria-current='page']) {
    --_dropdown-option-check-color: currentColor;
  }

  &:is(:hover) {
    background-color: var(--_dropdown-option-background-color--enter);
    color: var(--_dropdown-option-color--enter);
  }

  &:is([data-selected='true']) {
    background-color: var(--_dropdown-option-background-color--selected);
    color: var(--_dropdown-option-color--selected);
  }
}

.dropdown__option-icon {
  flex-shrink: 0;
}

.dropdown__option-text {
  --line-clamp: 1;

  flex-grow: 1;
}

.dropdown__option-check {
  color: var(--_dropdown-option-check-color);
  flex-shrink: 0;
  font-size: 0.8em;
  transition-property: color;
}

.dropdown__arrow {
  color: var(--_dropdown-options-border-color);
  position: absolute;
}
