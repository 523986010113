$grid-teaser-breakpoint: 60rem;

.grid-teaser {
  @include use-responsive-config(
    --grid-teaser-padding-block --grid-teaser-padding-inline,
    responsive-map(xs 2rem, xl 3rem)
  );

  --_grid-teaser-background-color: var(--grid-teaser-background-color, var(--secondary-background-color));
  --_grid-teaser-border-color: var(--grid-teaser-border-color, transparent);
  --_grid-teaser-border-radius: var(--grid-teaser-border-radius, 0);
  --_grid-teaser-border-style: var(--grid-teaser-border-style, solid);
  --_grid-teaser-border-width: var(--grid-teaser-border-width, 0);
  --_grid-teaser-content-row-gap: var(--grid-teaser-content-row-gap, max(1.5rem, 1.8cqi));
  --_grid-teaser-font-size: var(--grid-teaser-font-size, var(--font-size-teaser));
  --_grid-teaser-headline-color--enter: var(--grid-teaser-headline-color--enter, var(--primary-heading-color));
  --_grid-teaser-headline-color: var(--grid-teaser-headline-color, var(--primary-heading-color));

  container-name: grid-teaser;
  container-type: inline-size;
  position: relative;
}

.grid-teaser__inner {
  background-color: var(--_grid-teaser-background-color);
  border-color: var(--_grid-teaser-border-color);
  border-radius: var(--_grid-teaser-border-radius);
  border-style: var(--_grid-teaser-border-style);
  border-width: var(--_grid-teaser-border-width);
  height: 100%;
  min-height: 50cqi;

  .grid-teaser--large & {
    min-height: 0;

    @container grid-teaser (min-width: #{$grid-teaser-breakpoint}) {
      align-items: stretch;
      display: flex;
    }
  }
}

.grid-teaser__image {
  flex-shrink: 0;
  pointer-events: none;
  position: relative;
  z-index: 3;

  .grid-teaser--large & {
    display: flex;
    flex-direction: column;
    min-height: 100cqi;

    @container grid-teaser (min-width: #{$grid-teaser-breakpoint}) {
      min-height: 0;
      width: 50%;
    }
  }
}

.grid-teaser__copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  pointer-events: all;
  position: absolute;
}

.grid-teaser__content {
  --link-color: var(--grid-teaser-link-color, var(--primary-interactive-color));
  --link-color--enter: var(--grid-teaser-link-color--enter, var(--primary-interactive-color--enter));

  color: var(--grid-teaser-color, var(--text-color));
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: var(--_grid-teaser-font-size);
  gap: var(--_grid-teaser-content-row-gap);
  line-height: var(--line-height-body);
  padding-block: var(--_grid-teaser-padding-block);
  padding-inline: var(--_grid-teaser-padding-inline);
  width: 100%;

  @container grid-teaser (min-width: #{$grid-teaser-breakpoint}) {
    .grid-teaser--large .grid-teaser__image + & {
      justify-content: center;
      width: 50%;
    }
  }
}

.grid-teaser__headline {
  --headline-font-size: var(--font-size-headline-4);
  --headline-color: var(--_grid-teaser-headline-color);
  --headline-color--enter: var(--_grid-teaser-headline-color--enter);
  --headline-hyphens: auto;

  display: block;
}

.grid-teaser__link {
  --link-icon-gap: 1rem;
  --link-underline-puffer: 0;
  --link-icon-size: 1em;
  --link-icon-vertical-align: 0;

  display: block;

  & + & {
    margin-block-start: 0.6em;
  }
}

.grid-teaser__button {
  margin-block-start: calc(var(--_grid-teaser-content-row-gap) * 0.5);
}
