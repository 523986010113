.box {
  @include use-responsive-config(--box-padding-block --box-padding-inline, responsive-map(xs 2rem, xl 3rem));

  --_box-background-color: var(--box-background-color, var(--secondary-background-color));
  --_box-text-font-size: var(--box-text-font-size, inherit);
  --_box-inner-offset: var(--box-inner-offset, 4rem);
  --_box-image-max-width: var(--box-image-max-width, 20rem);
  --_box-links-row-gap: var(--box-links-row-gap, 1.4rem);
  --_box-links-icon-gap: var(--box-links-icon-gap, 1rem);

  container-name: box;
  container-type: inline-size;
}

.box__inner {
  background-color: var(--_box-background-color);
  column-gap: var(--gap);
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  .box--image & {
    margin-block-start: var(--_box-inner-offset);

    @container box (min-width: 50rem) {
      margin-block-start: 0;
      margin-inline-start: var(--_box-inner-offset);
    }
  }

  @container box (min-width: 50rem) {
    flex-direction: row;
  }
}

.box__image {
  --_box-image-offset: var(--box-image-offset, calc(var(--_box-inner-offset) * -1));

  align-self: flex-start;
  margin-block-start: var(--_box-image-offset);
  margin-inline-start: var(--_box-padding-inline);
  max-width: var(--_box-image-max-width);
  position: relative;
  width: 75%;

  @container box (min-width: 50rem) {
    flex-shrink: 0;
    margin-block: var(--_box-padding-block);
    margin-inline-start: var(--_box-image-offset);
  }
}

.box__copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  position: absolute;
}

.box__content {
  padding-block: var(--_box-padding-block);
  padding-inline: var(--_box-padding-inline);

  .box--image & {
    padding-block-start: 0;

    @container box (min-width: 50rem) {
      padding-block-start: var(--_box-padding-block);
      padding-inline-start: 0;
    }
  }
}

.box__headline {
  --headline-font-size: var(--font-size-headline-4);

  @container box (min-width: 50rem) {
    --headline-font-size: var(--font-size-headline-3);
  }
}

.box__text {
  font-size: var(--font-size-default);
  line-height: var(--line-height-body);

  &:not(:first-child) {
    margin-block-start: 1em;
  }

  > * + * {
    margin-block-start: 0.5em;
  }

  @container box (min-width: 50rem) {
    font-size: var(--font-size-paragraph);
  }
}

.box__links {
  column-gap: var(--gap);
  display: grid;
  font-size: var(--font-size-default);
  line-height: var(--line-height-body);
  row-gap: var(--_box-links-row-gap);

  &:not(:first-child) {
    margin-block-start: 1em;
  }
}

.box__link {
  --link-icon-gap: var(--_box-links-icon-gap);
  --link-underline-puffer: 0;
  --link-icon-size: 1em;
  --link-icon-vertical-align: 0;

  display: grid;
}
