.input {
  --_input-background-color: var(--input-background-color, var(--control-background-color));
  --_input-border-color: var(--input-border-color, var(--control-border-color));
  --_input-border-radius: var(--input-border-radius, 0);
  --_input-border-width: var(--input-border-width, 2px);
  --_input-color: var(--input-color, var(--control-color));
  --_input-dropdown-background-selected: var(--input-dropdown-background-selected, var(--background-color));
  --_input-dropdown-background: var(--input-dropdown-background, var(--light-background-color));
  --_input-dropdown-border-color: var(--input-dropdown-border-color, var(--color-gray-900));
  --_input-dropdown-color: var(--input-dropdown-color, var(--form-color));
  --_input-dropdown-padding-block: var(--input-dropdown-padding-block, 1.2rem);
  --_input-font-size: var(--input-font-size, 1.8rem);
  --_input-icon-background-color: var(--input-icon-background-color, var(--secondary-background-color));
  --_input-icon-color: var(--input-icon-color, var(--secondary-color));
  --_input-icon-size: var(--input-icon-size, 1.6rem);
  --_input-limit-background-color: var(--input-limit-background-color, var(--secondary-background-color));
  --_input-limit-color: var(--input-limit-color, var(--text-color));
  --_input-limit-font-size: var(--input-limit-font-size, 1.3rem);
  --_input-line-height: var(--input-line-height, 2.4rem);
  --_input-padding-block: var(--input-padding-block, 1.2rem);
  --_input-padding-inline: var(--input-padding-inline, 2rem);
  --_input-placeholder-color: var(--input-placeholder-color, var(--color-gray-300));
  --_input-tag-background-color: var(--input-tag-background-color, var(--secondary-background-color));
  --_input-tag-color: var(--input-tag-color, var(--text-color));
  --_input-uploads-background-color: var(--input-uploads-background-color, var(--background-color));
  --_input-uploads-color: var(--input-uploads-color, var(--text-color));
  --_input-uploads-gap: var(--input-uploads-gap, 1.5rem);
  --_input-uploads-margin-block: var(--input-uploads-margin-block, 1.2rem);
  --_input-z-index: var(--input-z-index, #{z-index('default')});

  // Toolbar
  --_input-toolbar-background-color: var(--input-toolbar-background-color, var(--secondary-background-color));
  --_input-toolbar-button-background-color: var(--input-toolbar-button-background-color, var(--color-white));
  --_input-toolbar-button-border-color: var(--input-toolbar-button-border-color, var(--color-gray-200));
  --_input-toolbar-button-border-radius: var(--input-toolbar-button-border-radius, 0);
  --_input-toolbar-button-border-style: var(--input-toolbar-button-border-style, solid);
  --_input-toolbar-button-border-width: var(--input-toolbar-button-border-width, 1px);
  --_input-toolbar-button-color: var(--input-toolbar-button-color, var(--text-color));
  --_input-toolbar-button-size: var(--input-toolbar-button-size, 2.6rem);

  // :hover, :focus & :active
  --_input-toolbar-button-background-color--enter: var(--input-toolbar-button-background-color--enter, var(--color-gray-050));
  --_input-toolbar-button-border-color--enter: var(--input-toolbar-button-border-color--enter, var(--text-color));
  --_input-toolbar-button-color--enter: var(--input-toolbar-button-color--enter, var(--text-color));

  // Selected
  --_input-toolbar-button-background-color--selected: var(--input-toolbar-button-background-color--selected, var(--text-color));
  --_input-toolbar-button-border-color--selected: var(--input-toolbar-button-border-color--selected, var(--text-color));
  --_input-toolbar-button-color--selected: var(--input-toolbar-button-color--selected, var(--color-white));

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: var(--_input-z-index);
}

.input__input {
  --focus-outline-offset: var(--_input-focus-outline-offset, 0);
  --focus-outline-color: var(--input-focus-outline-color, var(--_input-border-color));
  --_input-icon-width: calc(var(--_input-padding-inline) * 3 + var(--_input-icon-size));
  --selection-background-color: var(--_input-color);
  --selection-foreground-color: var(--_input-background-color);
  --_input-limit-height: calc(var(--_input-padding-block) * 2 + var(--_input-limit-font-size) + var(--_input-border-width));

  background-color: var(--_input-background-color);
  border: var(--_input-border-width) solid var(--_input-border-color);
  border-radius: var(--_input-border-radius);
  color: var(--_input-color);
  display: block;
  font-size: var(--_input-font-size);
  line-height: var(--_input-line-height);
  min-height: calc(var(--_input-line-height) + (var(--_input-padding-block) + var(--_input-border-width)) * 2);
  min-width: 100%;
  opacity: var(--_input-opacity);
  padding-block: var(--_input-padding-block);
  padding-inline: var(--_input-padding-inline);
  position: relative;
  text-align: left;
  transition-property: border-color;
  width: 100%;
  z-index: 2;

  &:where(:not(:first-child)) {
    padding-inline-start: var(--_input-icon-width);
  }

  &:where(:not(:last-child)) {
    padding-inline-end: var(--_input-icon-width);
  }

  &[disabled] {
    filter: grayscale(1);
    opacity: 0.2;
    user-select: none;
  }

  &[data-limit] {
    padding-block-end: calc(var(--_input-limit-height) + var(--_input-padding-block));
  }

  &::placeholder {
    color: var(--_input-placeholder-color);
    opacity: 1;
  }
}

.input__input--richtext {
  padding-inline: var(--_input-padding-inline);

  &:focus-within {
    @include use-focus-outline();
  }
}

.input__input--color {
  cursor: pointer;
  height: var(--_input-line-height);
  line-height: 0;

  &::-webkit-color-swatch,
  &::-moz-color-swatch {
    border: 0;
  }
}

.input__input--select {
  cursor: pointer;

  &:invalid {
    color: var(--_input-placeholder-color);
  }
}

.input__input--tags {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-inline: var(--_input-padding-inline);

  &:focus-within {
    @include use-focus-outline();
  }
}

.input__toolbar {
  background-color: var(--_input-toolbar-background-color);
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-block-start: calc(var(--_input-padding-block) * -1);
  margin-inline: calc(var(--_input-padding-inline) * -1);
  padding-block: var(--_input-padding-block);
  padding-inline: var(--_input-padding-inline);
  user-select: none;
}

.input__toolbar-button {
  background-color: var(--_input-toolbar-button-background-color);
  border-color: var(--_input-toolbar-button-border-color);
  border-radius: var(--_input-toolbar-button-border-radius);
  border-style: var(--_input-toolbar-button-border-style);
  border-width: var(--_input-toolbar-button-border-width);
  color: var(--_input-toolbar-button-color);
  flex-shrink: 0;
  font-size: calc(var(--_input-toolbar-button-size) * 0.5);
  height: var(--_input-toolbar-button-size);
  line-height: 0;
  transition-property: background-color, border-color, color;
  width: var(--_input-toolbar-button-size);

  &:is(:hover, :focus-visible) {
    --_input-toolbar-button-background-color: var(--_input-toolbar-button-background-color--enter);
    --_input-toolbar-button-border-color: var(--_input-toolbar-button-border-color--enter);
    --_input-toolbar-button-color: var(--_input-toolbar-button-color--enter);
  }
}

.input__toolbar-button--selected {
  --_input-toolbar-button-background-color: var(--_input-toolbar-button-background-color--selected);
  --_input-toolbar-button-border-color: var(--_input-toolbar-button-border-color--selected);
  --_input-toolbar-button-color: var(--_input-toolbar-button-color--selected);
}

.input__toolbar-button-icon {
  pointer-events: none;
}

.input__richtext {
  --focus-outline-width: 0;

  color: var(--text-color);
  line-height: var(--line-height-body);
  margin-block-start: 1rem;

  > * + * {
    margin-block-start: 0.5rem;
  }

  :is(h1) {
    font-size: 1.3em;
    font-weight: bold;
  }

  :is(h2) {
    font-size: 1.1em;
    font-weight: bold;
  }

  :is(blockquote) {
    border-inline-start: 0.5rem solid var(--color-gray-500);
    padding-inline-start: 1.5rem;
  }

  :is(ul, ol) {
    padding-inline-start: 2rem;
  }

  :is(pre) {
    background-color: var(--light-background-color);
    font-family: monospace;
    white-space: nowrap;
  }

  :is(hr) {
    border-block-start: 1px solid var(--text-color);
  }

  :is(a) {
    color: var(--link-color);
    text-decoration-color: var(--link-color);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 1px;
  }
}

.input__option,
.input__option-group {
  color: var(--_input-color);
  font-family: inherit;
}

.input__option--select {
  color: var(--_input-placeholder-color);
}

.input__icon {
  align-items: center;
  background-color: var(--_input-icon-background-color);
  color: var(--_input-icon-color);
  display: flex;
  font-size: var(--_input-icon-size);
  inset-block: var(--_input-border-width);
  inset-inline-start: var(--_input-border-width);
  justify-items: center;
  line-height: 0;
  padding-inline: var(--_input-padding-inline);
  pointer-events: none;
  position: absolute;
  z-index: 3;
}

.input__icon--select {
  inset-inline: auto var(--_input-border-width);
}

.input__limit {
  background-color: var(--_input-limit-background-color);
  color: var(--_input-limit-color);
  display: inline-block;
  font-size: var(--_input-limit-font-size);
  inset-block-end: var(--_input-border-width);
  inset-inline: var(--_input-border-width);
  line-height: 1;
  padding-block: var(--_input-padding-block);
  padding-inline: var(--_input-padding-inline);
  position: absolute;
  transition-property: background-color, color;
  z-index: 2;
}

.input__tags-input {
  --focus-outline-width: 0;

  display: inline-block;
  flex-grow: 1;
  min-width: 11rem;
  white-space: pre-wrap;
}

.input__tag {
  align-items: center;
  background-color: var(--_input-tag-background-color);
  color: var(--_input-tag-color);
  display: flex;
  font-size: 0.7em;
  height: var(--_input-line-height);
  line-height: 1;
}

.input__tag-text {
  padding-inline: 0.6em;
}

.input__tag-remove {
  background-color: var(--_input-tag-color);
  color: var(--_input-tag-background-color);
  cursor: pointer;
  display: block;
  font-size: 0.8em;
  font-weight: var(--font-weight-bold);
  height: var(--_input-line-height);
  line-height: var(--_input-line-height);
  padding-inline: 0.5em;
  position: relative;

  &::after {
    content: 'X';
  }
}

.input__dropdown {
  --_input-tags-arrow-size: 1.2rem;
  --_input-tags-arrow-offset: calc(var(--_input-padding-inline) - var(--_input-tags-arrow-size) / 2);

  animation-duration: var(--duration-default);
  animation-name: opacity;
  background-color: var(--_input-dropdown-background);
  border-color: var(--_input-dropdown-border-color);
  border-radius: var(--_input-border-radius);
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: var(--_input-dropdown-color);
  font-size: 1.4rem;
  line-height: var(--line-height-small);
  position: absolute;
  text-align: left;
  transform: translateY(calc(var(--_input-tags-arrow-size) / 2));
  width: 100%;
  z-index: 10;

  &::before {
    border: var(--_input-tags-arrow-size) solid transparent;
    border-block-end-color: var(--_input-dropdown-border-color);
    content: '';
    height: 0;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    transform: translate(var(--_input-tags-arrow-offset), -100%);
    width: 0;
    z-index: 2;
  }
}

.input__dropdown-item {
  padding-block: var(--_input-dropdown-padding-block);
  padding-inline: var(--_input-padding-inline);
  position: relative;
  transition-property: background-color, font-weight;

  & + & {
    border-top: 1px solid var(--_input-dropdown-border-color);
  }

  &:first-child {
    border-start-end-radius: var(--_input-border-radius);
    border-start-start-radius: var(--_input-border-radius);
  }

  &:first-child::before {
    border: calc(var(--_input-tags-arrow-size) - 0.15rem) solid transparent;
    border-block-end-color: var(--_input-dropdown-background);
    content: '';
    height: 0;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    transform: translate(calc(var(--_input-tags-arrow-offset) + 0.15rem), -100%);
    transition-property: border-color;
    width: 0;
    z-index: 4;
  }

  &:last-child {
    border-end-end-radius: var(--_input-border-radius);
    border-end-start-radius: var(--_input-border-radius);
  }
}

.input__dropdown-item--selected {
  background-color: var(--_input-dropdown-background-selected);
  font-weight: bold;
  text-decoration: underline;

  &:first-child::before {
    border-block-end-color: var(--_input-dropdown-background-selected);
  }
}

.input__uploads {
  display: flex;
  flex-wrap: wrap;
  gap: var(--_input-uploads-gap);
  margin-block-start: var(--_input-uploads-margin-block);
}

.input__upload {
  align-items: center;
  background-color: var(--_input-uploads-background-color);
  border-radius: 0.5rem;
  color: var(--_input-uploads-color);
  display: flex;
  font-size: 1.4rem;
  gap: 0.3rem;
  line-height: 2.4rem;
  padding-inline: 0.5rem;
}
