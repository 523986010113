$form-mobile-switch-breakpoint: m;

:root {
  --_form-alert-margin-block: var(--form-alert-margin-block, 4rem);
  --_form-pages-margin-block: var(--form-pages-margin-block, 3rem);
  --_form-title-margin-block: var(--form-title-margin-block, 3rem);
  --_form-text-margin-block: var(--form-title-margin-block, 3rem);
  --_form-progress-margin-block: var(--form-progress-margin-block, 5rem);
  --_form-pages-gap: var(--form-pages-gap, 3rem);
  --_form-errors-background-color: var(--form-errors-background-color, var(--error-color));
  --_form-errors-color: var(--form-errors-color, var(--color-white));
  --_form-errors-font-size: var(--form-errors-font-size, 1.4rem);
  --_form-errors-margin-block: var(--form-errors-margin-block, 0.7rem);
  --_form-errors-padding-block: var(--form-errors-padding-block, 0.8rem);
  --_form-errors-padding-inline: var(--form-errors-padding-inline, 1.2rem);
  --_form-page-background-color: var(--form-page-background-color, var(--color-white));
  --_form-page-border-color: var(--form-page-border-color, transparent);
  --_form-page-border-width: var(--form-page-border-width, 0);
  --_form-page-buttons-gap: var(--form-page-buttons-gap, 4rem);
  --_form-page-buttons-margin-block: var(--form-page-buttons-margin-block, 4rem);
  --_form-page-fields-column-gap: var(--form-page-fields-column-gap, var(--gap));
  --_form-page-fields-row-gap: var(--form-page-fields-row-gap, 3rem);
  --_form-page-padding-block: var(--form-page-padding-block, 0);
  --_form-page-padding-inline: var(--form-page-padding-inline, 0);
  --_form-page-title-color: var(--form-page-title-color, var(--text-color));
  --_form-page-title-font-size: var(--form-page-title-font-size, 2rem);
  --_form-page-title-font-weight: var(--form-page-title-font-weight, var(--font-weight-bold));
  --_form-page-title-margin-block: var(--form-page-title-margin-block, 3rem);
  --_form-tab-color: var(--form-tab-color, var(--text-color));
  --_form-tab-current-font-weight: var(--form-tab-current-font-weight, var(--font-weight-bold));
  --_form-tab-font-size: var(--form-tab-font-size, var(--font-size-meta));
  --_form-tab-font-weight: var(--form-tab-font-weight, var(--font-weight-regular));
  --_form-tab-gap: var(--form-tab-gap, 1rem);
  --_form-tab-padding-block: var(--form-tab-padding-block, 1.5rem);
  --_form-tab-padding-inline: var(--form-tab-padding-inline, 2rem);
  --_form-tab-background-color: var(--form-tab-background-color, var(--secondary-background-color));
}

.form {
  container-name: form;
  container-type: inline-size;
}

.form,
.form__form {
  :first-child,
  .form__hidden-fields + * {
    margin-block-start: 0;
  }

  :last-child {
    margin-block-end: 0;
  }
}

.form__alert {
  margin-block: var(--_form-alert-margin-block);
}

.form__title {
  margin-block: var(--_form-title-margin-block);
}

.form__text {
  margin-block: var(--_form-text-margin-block);
}

.form__progress {
  margin-block: var(--_form-progress-margin-block);
}

.form__errors {
  --notice-color: var(--_form-errors-color);
  --notice-icon-color: var(--_form-errors-color);

  background-color: var(--_form-errors-background-color);
  border-radius: var(--border-radius-default);
  font-size: var(--_form-errors-font-size);
  margin-block: var(--_form-errors-margin-block);
  padding-block: var(--_form-errors-padding-block);
  padding-inline: var(--_form-errors-padding-inline);
}

.form__tabs {
  display: flex;
  gap: var(--_form-tab-gap);
  margin-block-end: calc(var(--_form-page-border-width) * -1);
  position: relative;
  z-index: 2;

  @container form (inline-size < 40rem) {
    display: none;
  }
}

.form__tab {
  background-color: var(--_form-tab-background-color);
  color: var(--_form-tab-color);
  display: block;
  font-size: var(--_form-tab-font-size);
  font-weight: var(--_form-tab-font-weight);
  padding-block: var(--_form-tab-padding-block);
  padding-inline: var(--_form-tab-padding-inline);

  &[data-conditionally-hidden='true'] {
    display: none;
  }
}

.form__tab--current {
  font-weight: var(--_form-tab-current-font-weight);
}

.form__pages {
  display: flex;
  flex-direction: column;
  gap: var(--_form-pages-gap);
  margin-block: var(--_form-pages-margin-block);
}

.form__page {
  background-color: var(--_form-page-border-color);
  border: var(--_form-page-border-width) solid var(--_form-page-border-color);
  padding-block: var(--_form-page-padding-block);
  padding-inline: var(--_form-page-padding-inline);
  position: relative;
  width: 100%;
  z-index: 2;
}

.form__page-title {
  color: var(--_form-page-title-color);
  font-size: var(--_form-page-title-font-size);
  font-weight: var(--_form-page-title-font-weight);
  margin-block-end: var(--_form-page-title-margin-block);

  @container (inline-size >= 40rem) {
    display: none;
  }
}

.form__page-fields {
  appearance: none;
  display: block;
}

.form__page-legend {
  @include use-clearfix();

  appearance: none;
  color: var(--_form-page-title-color);
  display: block;
  float: left;
  font-size: var(--_form-page-title-font-size);
  font-weight: var(--_form-page-title-font-weight);
  margin-block: var(--_form-page-title-margin-block);
  padding-inline: 0;
  width: 100%;

  @container form (inline-size < 40rem) {
    display: none;
  }
}

.form__page-legend--hidden {
  @container form (inline-size >= 40rem) {
    @include use-hidden-visually();
  }
}

.form__page-fields--hidden {
  display: none;
}

.form__row {
  clear: both;
  display: grid;
  gap: var(--_form-page-fields-column-gap);
  grid-auto-flow: row;
  margin-block: var(--_form-page-fields-row-gap);

  &:first-of-type {
    margin-block-start: 0;
  }

  &[hidden] {
    display: none;
  }

  @container form (inline-size >= 40rem) {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
}

.form__field {
  &[data-conditionally-hidden='true'] {
    display: none;
  }
}

.form__field--extended {
  grid-column-end: span 2;
}

.form__page-buttons {
  align-items: center;
  display: flex;
  gap: var(--_form-page-buttons-gap);
  margin-block-start: var(--_form-page-buttons-margin-block);
}

.form__page-buttons--right {
  justify-content: flex-end;
}

.form__page-buttons--center-save-right,
.form__page-buttons--center-save-left {
  justify-content: center;
}

.form__page-button {
  &[data-conditionally-hidden='true'] {
    display: none;
  }
}

.form__page-button--save {
  .form__page-buttons--right-save-left &,
  .form__page-buttons--center-save-left & {
    order: -2;
  }

  .form__page-buttons--save-right & {
    margin-inline-start: auto;
  }

  .form__page-buttons--save-left & {
    margin-inline-end: auto;
    order: -2;
  }
}

.form__page-button--back {
  order: -1;
}
