.table {
  --_table-body-border-color: var(--table-body-border-color, var(--line-color));
  --_table-body-border-style: var(--table-body-border-style, solid);
  --_table-body-border-width: var(--table-body-border-width, 2px);
  --_table-caption-color: var(--table-caption-color, var(--caption-color));
  --_table-caption-font-size: var(--table-caption-font-size, 0.8em);
  --_table-caption-gap: var(--table-caption-gap, 1.5rem);
  --_table-cell-background-color-even: var(--table-cell-background-color-even, transparent);
  --_table-cell-background-color-odd: var(--table-cell-background-color-odd, var(--color-gray-050));
  --_table-cell-border-color: var(--table-cell-border-color, transparent);
  --_table-cell-border-style: var(--table-cell-border-style, solid);
  --_table-cell-border-width: var(--table-cell-border-width, 0);
  --_table-cell-color: var(--table-cell-color, currentColor);
  --_table-cell-font-size: var(--table-cell-font-size, inherit);
  --_table-cell-padding-block: var(--table-cell-padding-block, 1.8rem);
  --_table-cell-padding-inline: var(--table-cell-padding-inline, 2.2rem);
  --_table-cell-text-align: var(--table-cell-text-align, left);
  --_table-cell-vertical-align: var(--table-cell-vertical-align, text-top);
  --_table-font-size: var(--table-font-size, inherit);
  --_table-foot-border-color: var(--table-foot-border-color, var(--text-color));
  --_table-foot-border-style: var(--table-foot-border-style, solid);
  --_table-foot-border-width: var(--table-foot-border-width, 1px);
  --_table-hcell-background-color: var(--table-hcell-background-color, transparent);
  --_table-hcell-border-color: var(--table-hcell-border-color, transparent);
  --_table-hcell-border-style: var(--table-hcell-border-style, solid);
  --_table-hcell-border-width: var(--table-hcell-border-width, 0);
  --_table-hcell-color: var(--table-hcell-color, currentColor);
  --_table-hcell-font-size: var(--table-hcell-font-size, inherit);
  --_table-hcell-font-weight: var(--table-hcell-font-weight, var(--font-weight-bold));
  --_table-hcell-padding-block: var(--table-hcell-padding-block, 1.8rem);
  --_table-hcell-padding-inline: var(--table-hcell-padding-inline, 2.2rem);
  --_table-hcell-text-align: var(--table-hcell-text-align, left);
  --_table-hcell-vertical-align: var(--table-hcell-vertical-align, text-top);
  --_table-head-border-color: var(--table-head-border-color, var(--line-color));
  --_table-head-border-style: var(--table-head-border-style, solid);
  --_table-head-border-width: var(--table-head-border-width, 1px);

  overflow-x: auto;
}

.table__table {
  border-collapse: collapse;
  color: var(--_table-color);
  font-size: var(--_table-font-size);
  line-height: var(--line-height-default);
  width: 100%;
}

.table__hcell {
  background-color: var(--_table-hcell-background-color);
  border-color: var(--_table-hcell-border-color);
  border-style: var(--_table-hcell-border-style);
  border-width: var(--_table-hcell-border-width);
  color: var(--_table-hcell-color);
  font-size: var(--_table-hcell-font-size);
  font-weight: var(--_table-hcell-font-weight);
  padding-block: var(--_table-hcell-padding-block);
  padding-inline: var(--_table-hcell-padding-inline);
  text-align: var(--_table-hcell-text-align);
  vertical-align: var(--_table-hcell-vertical-align);
}

.table__head .table__row:last-child .table__hcell {
  border-block-end-color: var(--_table-head-border-color);
  border-block-end-style: var(--_table-head-border-style);
  border-block-end-width: var(--_table-head-border-width);
}

.table__foot .table__row:first-child .table__hcell {
  border-block-start-color: var(--_table-foot-border-color);
  border-block-start-style: var(--_table-foot-border-style);
  border-block-start-width: var(--_table-foot-border-width);
}

.table__cell {
  background-color: var(--_table-cell-background-color);
  border-color: var(--_table-cell-border-color);
  border-style: var(--_table-cell-border-style);
  border-width: var(--_table-cell-border-width);
  font-size: var(--_table-cell-font-size);
  font-weight: var(--_table-cell-font-weight);
  padding-block: var(--_table-cell-padding-block);
  padding-inline: var(--_table-cell-padding-inline);
  text-align: var(--_table-cell-text-align);
  vertical-align: var(--_table-cell-vertical-align);
}

.table__body .table__row:nth-child(odd) .table__cell {
  background-color: var(--_table-cell-background-color-odd);
}

.table__body .table__row:nth-child(even) .table__cell {
  background-color: var(--_table-cell-background-color-even);
}

.table__body:first-child .table__row:first-child .table__cell {
  border-block-start-color: var(--_table-body-border-color);
  border-block-start-style: var(--_table-body-border-style);
  border-block-start-width: var(--_table-body-border-width);
}

.table__body .table__row:last-child .table__cell {
  border-block-end-color: var(--_table-body-border-color);
  border-block-end-style: var(--_table-body-border-style);
  border-block-end-width: var(--_table-body-border-width);
}

.table__caption {
  caption-side: bottom;
  color: var(--_table-caption-color);
  font-family: var(--font-headline);
  font-size: var(--_table-caption-font-size);
  line-height: var(--_table-line-height);
  margin-block-start: var(--_table-caption-gap);
  text-align: left;
}
