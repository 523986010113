.search-overlay {
  --_search-overlay-results-font-size: var(--search-overlay-results-font-size, var(--font-size-paragraph));
  --_search-overlay-max-width: var(--search-overlay-max-width, 60rem);

  // Overlay settings
  --overlay-animation: none;
  --overlay-max-width: 100%;
  --overlay-close-inset: 3rem;
  --overlay-content-padding-block: 0 * 1px;
}

.search-overlay__form {
  margin-inline: auto;
  max-width: var(--_search-overlay-max-width);
  padding-block-start: 3rem;
  width: 100%;
}

.search-overlay__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-block-start: 3rem;
  position: relative;
}

.search-overlay__search-results {
  background-color: var(--search-overlay-results-background-color, var(--color-white));
  display: flex;
  filter: blur(0) grayscale(0);
  flex-direction: column;
  flex-grow: 1;
  font-size: var(--_search-overlay-results-font-size);
  gap: 1em;
  line-height: var(--line-height-default);
  margin-inline: calc((100vw - 100%) / -2);
  padding-block: var(--search-overlay-results-padding-block, 3rem);
  padding-inline: calc((100vw - 100%) / 2);
  transition-property: filter, opacity;

  .is-loading & {
    filter: blur(0.5rem) grayscale(1);
    opacity: 0.5;
    pointer-events: none;
  }
}

.search-overlay__search-result {
  display: block;
  margin-inline: auto;
  max-width: var(--_search-overlay-max-width);
  width: 100%;
}

.search-overlay__loading {
  inset: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition-property: opacity;
  translate: -50% -50%;

  .is-loading & {
    opacity: 1;
  }
}
