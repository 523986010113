.links {
  --_links-row-gap: var(--links-row-gap, 1.4rem);
  --_links-icon-gap: var(--links-icon-gap, 1rem);
  --_links-font-size: var(--links-font-size, inherit);
  --_links-label-margin-block: var(--links-label-margin-block, 2rem);
  --_links-headline-margin-block: var(--links-headline-margin-block, 2rem);

  font-size: var(--_links-font-size);
  line-height: var(--line-height-body);
}

.links__label {
  margin-block-end: var(--_links-label-margin-block);
}

.links__headline {
  margin-block-end: var(--_links-headline-margin-block);
}

.links__links {
  column-gap: var(--gap);
  display: grid;
  row-gap: var(--_links-row-gap);

  .links:is([data-links-grid]) & {
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }

  .links:is([data-links-grid]):is([data-links-layout='tiles']) & {
    row-gap: var(--gap);
  }
}

.links__link {
  --link-icon-gap: var(--_links-icon-gap);
  --link-underline-puffer: 0;
  --link-icon-size: 1em;
  --link-icon-vertical-align: 0;

  display: grid;
}
