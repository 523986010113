@use 'mq';

.section {
  @include use-responsive-config(--section-padding-block, responsive-map(xs 4rem, xl 6rem));
  @include use-responsive-config(--section-content-margin-block, responsive-map(xs 3rem, xl 4.5rem));
  @include use-responsive-config(--section-block-margin-block, responsive-map(xs 2rem, xl 4rem));
  @include use-responsive-config(--section-headline-margin-block, responsive-map(xs 2rem, xl 2.5rem));

  --_section-background-color: var(--section-background-color, transparent);
  --_section-button-margin-block: var(--section-button-margin-block, 3rem);
  --_section-column-gap: var(--section-column-gap, var(--gap));
  --_section-headline-color: var(--section-headline-color, var(--primary-heading-color));
  --_section-headline-font-size: var(--section-headline-font-size, var(--font-size-headline-2));
  --_section-text-font-size: var(--section-text-font-size, var(--font-size-paragraph));
  --_section-text-margin-block: var(--section-text-margin-block, 1.5rem);
  --_section-text-max-width: var(--section-text-max-width, 80rem);
  --_section-text-color: var(--section-text-color, var(--text-color));
}

.section__inner {
  @include use-container();

  background-color: var(--_section-background-color);
  overflow: hidden;
  padding-block: var(--_section-padding-block);
  position: relative;

  > :first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

.section__headline {
  --headline-font-size: var(--_section-headline-font-size);
  --headline-color: var(--_section-headline-color);

  grid-area: grid-headline;
  margin-block: var(--_section-headline-margin-block);
}

.section__text {
  color: var(--_section-text-color);
  font-size: var(--_section-text-font-size);
  line-height: var(--line-height-body);
  margin-block: var(--_section-text-margin-block);
  max-width: var(--_section-text-max-width);
}

.section__text--intro {
  grid-area: grid-intro-text;
}

.section__text--outro {
  grid-area: grid-outro-text;
}

.section__content {
  display: grid;
  gap: calc(var(--_section-block-margin-block) * 1.5);
  grid-area: grid-content;
  grid-template-columns: 100%;
  margin-block: var(--_section-content-margin-block);
  position: relative;
}

.section__block {
  filter: blur(0) grayscale(0);
  transition-property: filter;

  .is-loading & {
    filter: blur(0.5rem) grayscale(1);
    opacity: 0.5;
    pointer-events: none;
  }

  > * + * {
    margin-block-start: 1rem;
  }

  > .headline {
    margin-block-start: calc(var(--_section-block-margin-block) * 1.5);
  }
}

.section__button {
  --button-background-color: var(--section-button-background-color, var(--secondary-interactive-color));
  --button-color: var(--section-button-color, var(--secondary-interactive-background-color));
  --button-border-color: var(--section-button-border-color, transparent);
  --button-background-color--enter: var(--section-button-background-color--enter, var(--secondary-interactive-color--enter));
  --button-color--enter: var(--section-button-color--enter, var(--secondary-interactive-background-color--enter));
  --button-border-color--enter: var(--section-button-border-color--enter, transparent);
  --button-background-color--disabled: var(--section-button-background-color--disabled, var(--secondary-interactive-color--disabled));
  --button-color--disabled: var(--section-button-color--disabled, var(--secondary-interactive-background-color--disabled));
  --button-border-color--disabled: var(--section-button-border-color--disabled, transparent);

  grid-area: grid-button;
  margin-block: var(--_section-button-margin-block);
}

.section__loading {
  inset: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition-property: opacity;
  translate: -50% -50%;
  user-select: none;

  .is-loading & {
    opacity: 1;
  }
}

@include mq.mq($from: m) {
  .section--gridded {
    --_section-content-column-width: #{column-width(7)};
    --_section-content-column-gap: 0;
    --_section-content-margin-block: 0;

    .section__inner {
      column-gap: var(--_section-column-gap);
      display: grid;
      grid-template-areas:
        'grid-headline . grid-content'
        'grid-intro-text . grid-content'
        'grid-outro-text . grid-content'
        'grid-button . grid-content'
        '. . grid-content';
      grid-template-columns: auto var(--_section-content-column-gap) var(--_section-content-column-width);
      grid-template-rows: min-content min-content min-content min-content auto;
    }
  }
}

@include mq.mq($from: l) {
  .section--gridded {
    --_section-content-column-gap: #{column-width(1)};
  }
}
