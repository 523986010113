.accordion {
  --_accordion-background-color: var(--accordion-background-color, var(--color-white));
  --_accordion-border-color: var(--accordion-border-color, transparent);
  --_accordion-border-style: var(--accordion-border-style, solid);
  --_accordion-border-width: var(--accordion-border-width, 0);
  --_accordion-content-border-color: var(--accordion-content-border-color, var(--line-color));
  --_accordion-content-border-style: var(--accordion-content-border-style, solid);
  --_accordion-content-border-width: var(--accordion-content-border-width, 0 0 1px);
  --_accordion-content-padding-block: var(--accordion-content-padding-block, 3rem);
  --_accordion-content-padding-inline: var(--accordion-content-padding-inline, 2rem);
  --_accordion-content-spacing: var(--accordion-content-spacing, 1em);
  --_accordion-font-size: var(--accordion-font-size, inherit);
  --_accordion-padding-inline: var(--accordion-padding-inline, 2rem);
  --_accordion-summary-background-color: var(--accordion-summary-background-color, var(--color-white));
  --_accordion-summary-color: var(--accordion-summary-color, var(--secondary-interactive-color));
  --_accordion-summary-font-size: var(--accordion-summary-font-size, var(--font-size-paragraph));
  --_accordion-summary-font-weight: var(--accordion-summary-font-weight, var(--font-weight-bold));
  --_accordion-summary-icon-background-color: var(--accordion-summary-icon-background-color, var(--secondary-interactive-color));
  --_accordion-summary-icon-color: var(--accordion-summary-icon-color, var(--secondary-interactive-background-color));
  --_accordion-summary-icon-padding: var(--accordion-summary-icon-padding, 0.5em);
  --_accordion-summary-icon-size: var(--accordion-summary-icon-size, 1.4rem);
  --_accordion-summary-padding-block: var(--accordion-summary-padding-block, 1.5rem);
  --_accordion-summary-padding-inline: var(--accordion-summary-padding-inline, 0);
  --_accordion-summary-text-transform: var(--accordion-summary-text-transform, none);
  --_accordion-summary-border-color: var(--accordion-summary-border-color, var(--line-color));
  --_accordion-summary-border-style: var(--accordion-summary-border-style, solid);
  --_accordion-summary-border-width: var(--accordion-summary-border-width, 1px 0);

  background-color: var(--_accordion-background-color);
  border-color: var(--_accordion-border-color);
  border-style: var(--_accordion-border-style);
  border-width: var(--_accordion-border-width);
  font-size: var(--_accordion-font-size);
  position: relative;

  & + & {
    border-block-start-width: 0;
  }
}

.accordion__summary {
  align-items: center;
  appearance: none;
  backdrop-filter: brightness(1000%) grayscale(1) blur(5px);
  background-color: var(--_accordion-summary-background-color);
  border-color: var(--_accordion-summary-border-color);
  border-style: var(--_accordion-summary-border-style);
  border-width: var(--_accordion-summary-border-width);
  color: var(--_accordion-summary-color);
  cursor: pointer;
  display: flex;
  font-size: var(--_accordion-summary-font-size);
  font-weight: var(--_accordion-summary-font-weight);
  inset-block-start: -1px;
  line-height: var(--line-height-body);
  padding-block: var(--_accordion-summary-padding-block);
  padding-inline: var(--_accordion-summary-padding-inline);
  position: sticky;
  text-transform: var(--_accordion-summary-text-transform);
  user-select: none;
  z-index: 2;

  .accordion + .accordion & {
    border-block-start-width: 0;
  }

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
}

.accordion__summary-icon {
  background-color: var(--_accordion-summary-icon-background-color);
  border-radius: 50%;
  color: var(--_accordion-summary-icon-color);
  flex-shrink: 0;
  font-size: var(--_accordion-summary-icon-size);
  line-height: 0;
  margin-inline-start: auto;
  padding: var(--_accordion-summary-icon-padding);
  transition-property: transform;

  .accordion[open] & {
    transform: rotate(180deg);
  }
}

.accordion__blocks {
  border-color: var(--_accordion-content-border-color);
  border-style: var(--_accordion-content-border-style);
  border-width: var(--_accordion-content-border-width);
  padding-block: var(--_accordion-content-padding-block);
  padding-inline: var(--_accordion-content-padding-inline);
  position: relative;
  z-index: 1;
}

.accordion__block {
  margin-block-end: var(--_accordion-content-spacing);

  &:first-child {
    margin-block-start: 0;
  }

  &:last-child {
    margin-block-end: 0;
  }

  > * + * {
    margin-block-start: var(--_accordion-content-spacing);
  }

  > .headline {
    margin-block-start: calc(var(--_accordion-content-spacing) * 1.5);
  }
}

.accordion__block--aside {
  margin-block: calc(var(--_accordion-content-spacing) * 1.25);
}
