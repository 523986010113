.menu {
  @include use-responsive-config(--menu-padding-inline, responsive-map(xs 3rem, xl 7rem));
  @include use-responsive-config(--menu-padding-block, responsive-map(xs 3rem, xl 8rem));
  @include use-responsive-config(--menu-items-gap, responsive-map(xs 1.5rem, xl 2.5rem));
  @include use-responsive-config(--menu-meta-bar-margin-block, responsive-map(xs 3rem, xl 4rem));
  @include use-responsive-config(--menu-submenu-gap, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-config(--menu-close-button-size, responsive-map(xs 3.6rem, xl 4.8rem));
  @include use-responsive-config(--menu-cards-padding-block, responsive-map(xs 3rem, xl 5rem));

  --_menu-card-inline-size: var(--menu-card-inline-size, clamp(26rem, 50%, 30rem));
  --_menu-cards-gap: var(--menu-cards-gap, 2.6rem);
  --_menu-cards-padding-inline: var(--menu-cards-padding-inline, var(--_menu-padding-inline));
  --_menu-close-inset: var(--menu-close-inset, 3rem);
  --_menu-content-background-color: var(--menu-content-background-color, var(--secondary-background-color));
  --_menu-link-font-size: var(--font-size-headline-5);
  --_menu-menu-inline-size: var(--menu-menu-inline-size, clamp(30rem, 33.33%, 45rem));
  --_menu-meta-bar-block-size: var(--menu-meta-bar-block-size, 5rem);

  container-name: menu;
  container-type: inline-size;
  inset: 0;
  position: fixed;
  z-index: var(--menu-z-index, #{z-index('menu')});

  &:is([aria-hidden='true']) {
    display: none;
  }
}

.menu__backdrop {
  animation: background-blur var(--duration-long);
  animation-fill-mode: forwards;
  inset: 0;
  position: absolute;
  z-index: 1;
}

.menu__close {
  --icon-button-font-size: var(--_menu-close-button-size);
  --icon-button-icon-size: calc(var(--_menu-close-button-size) * 0.5);
  --icon-button-icon-padding: calc(var(--_menu-close-button-size) * 0.25);

  animation: opacity var(--duration-long);
  inset-block-start: var(--_menu-close-inset);
  inset-inline-end: var(--_menu-close-inset);
  position: absolute;
  z-index: 100;
}

.menu__menu {
  background-color: var(--_menu-content-background-color);
  inset: 0;
  position: absolute;
  z-index: 4;

  @container menu (inline-size >= 65rem) {
    background-color: transparent;
    inline-size: var(--_menu-menu-inline-size);
    inset-inline-end: auto;
  }
}

.menu__menu-outer {
  block-size: 100%;
  position: relative;
  z-index: 2;
}

.menu__menu-inner {
  animation: opacity var(--duration-long);
  block-size: 100%;
  overflow-block: auto;
  overflow-inline: hidden;
  overscroll-behavior: contain;
  padding-block: var(--_menu-padding-block);
  position: relative;

  @container menu (inline-size >= 65rem) {
    animation:
      opacity var(--duration-long),
      slide-from-left var(--duration-long);
    background-color: var(--_menu-content-background-color);
  }
}

.menu__search {
  --icon-button-background-color: var(--menu-search-background-color, var(--color-white));
  --icon-button-background-color--enter: var(--menu-search-background-color--enter, var(--color-white));
  --icon-button-icon-background-color: transparent;
  --icon-button-icon-background-color--enter: transparent;
  --icon-button-color: var(--menu-item-color, var(--text-color));
  --icon-button-color--enter: var(--menu-item-color--enter, var(--primary-color));
  --icon-button-icon-color: var(--menu-item-color, var(--text-color));
  --icon-button-icon-color--enter: var(--menu-item-color--enter, var(--primary-color));
  --icon-button-icon-padding: 0;
  --icon-button-icon-padding: 0;
  --icon-button-underline-color--enter: currentColor;
  --icon-button-padding-inline: 2rem;
  --icon-button-icon-size: 1em;
  --icon-button-font-size: 2rem;
  --icon-button-line-block-size: 1.3;
  --icon-button-padding-block: calc((var(--_menu-meta-bar-block-size) - var(--icon-button-font-size) * var(--icon-button-line-block-size)) / 2);

  margin-block-end: var(--_menu-meta-bar-margin-block);
  padding-inline: var(--_menu-padding-inline);
}

.menu__items {
  display: grid;
}

.menu__item {
  display: block;
}

.menu__link {
  --icon-button-font-size: var(--_menu-link-font-size);
  --icon-button-icon-background-color: transparent;
  --icon-button-icon-background-color--enter: transparent;
  --icon-button-icon-color: var(--menu-item-color, var(--text-color));
  --icon-button-icon-color--enter: var(--menu-item-color--enter, var(--primary-color));
  --icon-button-underline-color--enter: currentColor;
  --icon-button-color: var(--menu-item-color, var(--text-color));
  --icon-button-color--enter: var(--menu-item-color--enter, var(--primary-color));
  --icon-button-icon-padding: 0;
  --icon-button-padding-inline: var(--_menu-padding-inline);
  --icon-button-padding-block: calc(var(--_menu-items-gap) / 2);
  --icon-button-width: 100%;
  --icon-button-icon-margin-inline: 0.6em 0;
  --icon-button-display: block;

  &:is([aria-expanded='true']) {
    --icon-button-background-color: var(--menu-item-background-color--active, var(--color-white));
    --icon-button-background-color--enter: var(--menu-item-background-color--active, var(--color-white));
    --icon-button-color: var(--menu-item-color--active, var(--text-color));
  }
}

.menu__submenu {
  animation:
    opacity var(--duration-long),
    slide-from-left var(--duration-long);
  background-color: var(--menu-submenu-background-color, var(--color-white));
  inset: 0;
  overflow-block: auto;
  overflow-inline: hidden;
  overscroll-behavior: contain;
  padding-block: var(--_menu-padding-block);
  padding-inline: var(--_menu-padding-inline);
  padding-inline-end: calc(var(--_menu-close-button-size) + var(--_menu-close-inset) + var(--_menu-padding-inline));
  position: fixed;
  z-index: 5;

  & & {
    animation: slide-from-left var(--duration-long);
    z-index: 0;
  }

  @container menu (inline-size >= 65rem) {
    inset-inline-start: var(--_menu-menu-inline-size);
    z-index: -1;
  }
}

.menu__submenu-meta {
  align-items: center;
  block-size: var(--_menu-meta-bar-block-size);
  display: flex;
  gap: 1rem;
  margin-block-end: var(--_menu-meta-bar-margin-block);
  position: relative;
}

.menu__submenu-items {
  display: flex;
  flex-wrap: wrap;
  gap: var(--_menu-submenu-gap);
  inline-size: 100%;
  position: relative;
}

.menu__submenu-item {
  display: block;
  flex-shrink: 0;
  width: 100%;

  @container menu (inline-size >= 65rem) {
    width: clamp(20rem, 25%, 25rem);
  }
}

.menu__submenu-link--mobile {
  --icon-button-font-size: var(--_menu-link-font-size);
  --icon-button-icon-background-color: transparent;
  --icon-button-icon-background-color--enter: transparent;
  --icon-button-icon-color: var(--menu-item-color, var(--text-color));
  --icon-button-icon-color--enter: var(--menu-item-color--enter, var(--primary-color));
  --icon-button-underline-color--enter: currentColor;
  --icon-button-color: var(--menu-item-color, var(--text-color));
  --icon-button-color--enter: var(--menu-item-color--enter, var(--primary-color));
  --icon-button-icon-padding: 0;
  --icon-button-width: 100%;
  --icon-button-icon-margin-inline: 0.4em 0;
  --icon-button-display: block;

  @container menu (inline-size >= 65rem) {
    display: none;
  }
}

.menu__submenu-link--desktop {
  --link-tile-aspect-ratio: 1;
  --link-tile-min-height: none;
  --link-tile-justify-content: flex-start;

  @container menu (inline-size < 65rem) {
    display: none;
  }
}

.menu__breadcrumb {
  block-size: 1.3em;
  color: var(--menu-breadcrumb-color, var(--meta-color));
  direction: rtl;
  line-height: 1.3em;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  user-select: none;
  white-space: nowrap;

  &::before {
    background-color: var(--menu-submenu-background-color, var(--color-white));
    content: '';
    inline-size: 3rem;
    inset-block: 0;
    inset-inline-end: 0;
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    position: absolute;
  }
}

.menu__breadcrumb-items {
  align-items: center;
  direction: ltr;
  display: inline;
  padding-inline-start: 3rem;
}

.menu__breadcrumb-item {
  display: inline;
}

.menu__breadcrumb-arrow {
  display: inline-block;
  font-size: 0.8em;
  margin-inline: 0.8em;
  vertical-align: -0.1em;
}

.menu__cards {
  animation-delay: 0.2s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: opacity;
  pointer-events: none;
  position: relative;
  z-index: 1;

  @container menu (inline-size >= 65rem) {
    inset-block: 0;
    inset-inline-end: 0;
    inset-inline-start: var(--_menu-menu-inline-size);
    opacity: 0;
    position: fixed;
  }
}

.menu__cards-scroller {
  align-items: center;
  block-size: 100%;
  display: flex;
  gap: var(--_menu-cards-gap);
  inline-size: calc(var(--_menu-card-inline-size) * 2 + var(--_menu-cards-padding-inline) * 2 + var(--_menu-cards-gap));
  margin-inline: auto;
  max-inline-size: 100%;
  overflow-block: hidden;
  overflow-inline: auto;
  padding-block: var(--_menu-cards-padding-block);
  padding-inline: var(--_menu-cards-padding-inline);

  @container menu (inline-size >= 65rem) {
    overscroll-behavior: contain;
  }
}

.menu__card {
  flex-shrink: 0;
  inline-size: var(--_menu-card-inline-size);
  pointer-events: all;
}
