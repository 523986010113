.tags__label {
  margin-block-end: var(--links-label-margin-block, 2rem);
}

.tags__tags {
  column-gap: var(--tags-column-gap, 1rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--tags-justify-content, flex-start);
  row-gap: var(--tags-row-gap, 1rem);
}

.tags__tag {
  display: block;
}
