@use 'mq';

.browser {
  --_browser-button-margin-block: var(--browser-button-margin-block, 4rem);
  --_browser-column-gap: var(--browser-column-gap, var(--gap));
  --_browser-row-gap: var(--browser-row-gap, 4rem);
  --_browser-link-background-color: var(--browser-link-background-color, var(--color-gray-050));
  --_browser-link-border-color: var(--browser-link-border-color, transparent);
  --_browser-link-border-radius: var(--browser-link-border-radius, 0);
  --_browser-link-border-style: var(--browser-link-border-style, solid);
  --_browser-link-border-width: var(--browser-link-border-width, 0);
  --_browser-link-headline-color: var(--browser-link-headline-color, var(--heading-color));
  --_browser-link-icon-background-color: var(--browser-link-icon-background-color, var(--text-color));
  --_browser-link-icon-border-color: var(--browser-link-icon-border-color, transparent);
  --_browser-link-icon-border-radius: var(--browser-link-icon-border-radius, 50%);
  --_browser-link-icon-border-style: var(--browser-link-icon-border-style, solid);
  --_browser-link-icon-border-width: var(--browser-link-icon-border-width, 0);
  --_browser-link-icon-box-size: var(--browser-link-icon-box-size, 2em);
  --_browser-link-icon-color: var(--browser-link-icon-color, var(--color-white));
  --_browser-link-icon-size: var(--browser-link-icon-size, 2em);
  --_browser-link-label-color: var(--browser-link-label-color, var(--text-color));
  --_browser-link-label-font-size: var(--browser-link-label-font-size, var(--font-size-meta));
  --_browser-link-padding-block: var(--browser-link-padding-block, 2rem);
  --_browser-link-padding-inline: var(--browser-link-padding-inline, 2rem);
  --_browser-link-underline-color: var(--browser-link-underline-color, transparent);
  --_browser-link-underline-height: var(--browser-link-underline-height, 0.08em);
  --_browser-link-underline-offset: var(--browser-link-underline-offset, 0.2em);

  // :hover, :focus & :active
  --_browser-link-background-color--enter: var(--browser-link-background-color--enter, var(--color-spd-red));
  --_browser-link-border-color--enter: var(--browser-link-border-color--enter, transparent);
  --_browser-link-color--enter: var(--browser-link-color--enter, var(--color-white));
  --_browser-link-headline-color--enter: var(--browser-link-headline-color--enter, var(--color-white));
  --_browser-link-icon-background-color--enter: var(--browser-link-icon-background-color--enter, var(--text-color));
  --_browser-link-icon-border-color--enter: var(--browser-link-icon-border-color--enter, transparent);
  --_browser-link-icon-color--enter: var(--browser-link-icon-color--enter, var(--color-white));
  --_browser-link-label-color--enter: var(--browser-link-label-color--enter, var(--color-white));
  --_browser-link-underline-color--enter: var(--browser-link-underline-color--enter, transparent);
  --_browser-link-underline-height--enter: var(--browser-link-underline-height--enter, 0.08em);
  --_browser-link-underline-offset--enter: var(--browser-link-underline-offset--enter, 0.2em);
}

.browser__links {
  column-gap: var(--_browser-column-gap);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  row-gap: var(--_browser-row-gap);

  @include mq.mq($from: s) {
    grid-template-columns: 1fr 1fr;
  }
}

.browser__link {
  --underline-color: var(--_browser-link-underline-color);
  --underline-color--enter: var(--_browser-link-underline-color--enter);
  --underline-height: var(--_browser-link-underline-height);
  --underline-offset: var(--_browser-link-underline-offset);
  --underline-transition-duration: var(--duration-default);
  --headline-font-size: var(--font-size-headline-4);
  --headline-color: var(--_browser-link-headline-color);
  --headline-color--enter: var(--_browser-link-headline-color--enter);

  background-color: var(--_browser-link-background-color);
  border-color: var(--_browser-link-border-color);
  border-radius: var(--_browser-link-border-radius);
  border-style: var(--_browser-link-border-style);
  border-width: var(--_browser-link-border-width);
  display: flex;
  flex-direction: column;
  padding-block: var(--_browser-link-padding-block);
  padding-inline: var(--_browser-link-padding-inline);
  row-gap: 1rem;
  transition-property: background-color, border-color, color;

  &:is(:hover:not([disabled]), :focus-visible:not(.has-invisible-focus)) {
    --_browser-link-background-color: var(--_browser-link-background-color--enter);
    --_browser-link-border-color: var(--_browser-link-border-color);
    --_browser-link-color: var(--_browser-link-color--enter);
    --_browser-link-icon-background-color: var(--_browser-link-icon-background-color--enter);
    --_browser-link-icon-border-color: var(--_browser-link-icon-border-color--enter);
    --_browser-link-icon-color: var(--_browser-link-icon-color--enter);
    --_browser-link-label-color: var(--_browser-link-label-color--enter);
    --underline-height: var(--_browser-link-underline-height--enter);
    --underline-offset: var(--_browser-link-underline-offset--enter);
  }
}

.browser__link--left {
  @include mq.mq($from: s) {
    grid-column-start: 1;
  }
}

.browser__link--right {
  align-items: flex-end;
  text-align: right;

  @include mq.mq($from: s) {
    grid-column-start: 2;
  }
}

.browser__link-label {
  color: var(--_browser-link-label-color);
  font-size: var(--_browser-link-label-font-size);
  line-height: var(--link-height-body);
  transition-property: color;
}

.browser__link-icon {
  align-items: center;
  background-color: var(--_browser-link-icon-background-color);
  block-size: var(--_browser-link-icon-size);
  border-color: var(--_browser-link-icon-border-color);
  border-radius: var(--_browser-link-icon-border-radius);
  border-style: var(--_browser-link-icon-border-style);
  border-width: var(--_browser-link-icon-border-width);
  color: var(--_browser-link-icon-color);
  display: flex;
  flex-shrink: 0;
  inline-size: var(--_browser-link-icon-size);
  justify-content: center;
  margin-block-end: calc(var(--_browser-link-icon-size) / -2 - var(--_browser-link-padding-block));
  margin-block-start: auto;
  transition-property: background-color, border-color, color;
}

.browser__button {
  margin-block-start: var(--_browser-button-margin-block);
  text-align: center;
}
