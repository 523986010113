.media {
  @include use-responsive-config(--media-lightbox-icon-button-font-size, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-config(--media-lightbox-padding, responsive-map(xs 2rem, xl 3rem));

  --_media-caption-color: var(--media-caption-color, var(--caption-color));
  --_media-caption-size: var(--media-caption-size, 1.4rem);
  --_media-caption-padding-inline: var(--media-caption-padding-inline, 0);
  --_media-caption-gap: var(--media-caption-gap, 1.5rem);
  --_media-lightbox-background-color: var(--media-lightbox-background-color, rgba(0, 0, 0, 0.8));
  --_media-lightbox-color: var(--media-lightbox-color, var(--color-white));

  display: block;
  transition-property: opacity;
}

.media__figure {
  --image-z-index: 2;

  container-name: media-figure;
  container-type: inline-size;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.media__button {
  --overlay-link-inset: -100vw;

  background-color: var(--color-white);
  display: flex;
  inset-block-start: 2rem;
  inset-inline-start: 2rem;
  padding-block: clamp(0.5rem, 2cqb, 1.5rem);
  padding-inline: clamp(1rem, 2cqb, 1.5rem);
  position: absolute;
  z-index: 3;
}

.media__caption {
  --link-color: currentColor;
  --link-icon-color: currentColor;
  --link-underline-color: currentColor;
  --link-underline-color--enter: transparent;
  --link-underline-height: 1px;
  --link-underline-offset: 0.2em;

  position: relative;
  text-align: left;
  z-index: 2;
}

.media__copyright {
  inset-block-start: 0;
  inset-inline-end: 1rem;
  line-height: 0;
  position: absolute;
  transform: translateY(calc(-100% - 1rem));
}

.media__text {
  color: var(--_media-caption-color);
  font-size: var(--_media-caption-size);
  line-height: var(--line-height-body);
  padding-block-start: var(--_media-caption-gap);
  padding-inline: var(--_media-caption-padding-inline);
}

.media__lightbox {
  --_media-lightbox-close-icon-button-height: calc(var(--_media-lightbox-icon-button-font-size) * 1.44);

  animation-duration: var(--duration-fast);
  animation-name: opacity;
  backdrop-filter: blur(10px);
  background-color: var(--_media-lightbox-background-color);
  color: var(--_media-lightbox-color);
  display: flex;
  flex-direction: column;
  inset: 0;
  overflow: hidden;
  padding-block-end: var(--_media-lightbox-padding);
  padding-block-start: calc(var(--_media-lightbox-padding) + var(--_media-lightbox-close-icon-button-height) + 2rem);
  padding-inline: var(--_media-lightbox-padding);
  position: fixed;
  z-index: z-index('overlay');

  &:is([aria-hidden='true']) {
    display: none;
  }
}

.media__lightbox-inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.media__lightbox-image {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
}

.media__lightbox-close {
  --icon-button-color--enter: var(--_media-lightbox-color);
  --icon-button-color: var(--_media-lightbox-color);
  --icon-button-icon-background-color--enter: var(--_media-lightbox-color);
  --icon-button-icon-background-color: var(--_media-lightbox-color);
  --icon-button-underline-color--enter: var(--_media-lightbox-color);
  --icon-button-font-size: var(--_media-lightbox-close-icon-button-font-size);

  inset-block-start: var(--_media-lightbox-padding);
  inset-inline-end: var(--_media-lightbox-padding);
  line-height: 0;
  pointer-events: all;
  position: absolute;
}
