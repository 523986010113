@use 'mq';

.image-slide {
  @include use-responsive-config(--image-slide-padding-block, responsive-map(xs 4rem, xl 6rem));

  --_image-slide-background-color: var(--image-slide-background-color, var(--secondary-background-color));
  --_image-slide-padding-inline: var(--image-slide-padding-inline, var(--inner-container-padding));
  --_image-slide-color: var(--image-slide-color, var(--color-white));
  --_image-slide-copyright-inset-inline: var(--image-slide-copyright-inset-inline, 1rem);
  --_image-slide-copyright-inset-block: var(--image-slide-copyright-inset-block, 1rem);
  --_image-slide-font-size: var(--image-slide-font-size, var(--font-size-teaser));

  background-color: var(--_image-slide-background-color);
  display: flex;
  flex-direction: column;
  position: relative;
}

.image-slide__media {
  position: relative;
  z-index: 1;
}

.image-slide__image {
  --image-z-index: 1;

  display: block;
  line-height: 0;
}

.image-slide__copyright {
  inset-block-end: var(--_image-slide-copyright-inset-block);
  inset-inline-end: var(--_image-slide-copyright-inset-inline);
  position: absolute;
  z-index: 2;
}

.image-slide__content {
  display: flex;
  flex-direction: column;
  order: 1;
  padding-block: var(--_image-slide-padding-block);
  padding-inline: var(--_image-slide-padding-inline);
  row-gap: 2rem;

  @include mq.mq($from: l) {
    inset-block-end: 0;
    inset-inline-start: var(--_image-slide-padding-inline);
    padding-inline: 0;
    position: absolute;
    width: column-width(4, $is-absolute: true);
    z-index: 2;
  }
}

.image-slide__headline {
  --headline-text-transform: uppercase;

  @include mq.mq($from: l) {
    --headline-color: var(--_image-slide-color);
    --headline-line-height: 1.4;
    --headline-text-background-color: var(--image-slide-headline-background-color, var(--primary-color));
    --headline-text-padding-block: var(--image-slide-headline-padding-block, 0);
    --headline-text-padding-inline: var(--image-slide-headline-padding-inline, 0.2em);
  }
}

.image-slide__button {
  font-size: var(--_image-slide-font-size);
  line-height: var(--line-height-body);

  @include mq.mq($from: l) {
    --link-background-color: var(--image-slide-button-background-color, var(--primary-color));
    --link-color: var(--_image-slide-color);
    --link-color--enter: var(--_image-slide-color);
    --link-padding-block: var(--image-slide-button-padding-block, 0.2em);
    --link-padding-inline: var(--image-slide-button-padding-inline, 0.4em);

    color: var(--_image-slide-color);
  }
}
