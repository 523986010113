.grid {
  @include use-responsive-config(--grid-row-gap, responsive-map(xs 2rem, xl 4rem));

  container-name: grid;
  container-type: inline-size;
}

.grid__items {
  display: grid;
  gap: var(--grid-column-gap, var(--gap));
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  row-gap: var(--_grid-row-gap);

  @container grid (min-width: 50rem) {
    .grid--3 & {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @container grid (min-width: 60rem) {
    .grid--2 & {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @container grid (min-width: 90rem) {
    .grid--3 & {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.grid__item {
  display: grid;
}

.grid__item--large {
  @container grid (min-width: 50rem) {
    .grid--3 & {
      grid-column: span 2;
    }
  }

  @container grid (min-width: 60rem) {
    .grid--2 & {
      grid-column: span 2;
    }
  }

  @container grid (min-width: 90rem) {
    .grid--3 & {
      grid-column: span 3;
    }

    .grid--3 &:nth-child(3n + 2) {
      grid-column: span 2;
    }

    .grid--3 &:nth-child(3n + 3) {
      grid-column: span 1;
      grid-row: span 2;
    }
  }
}
