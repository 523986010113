// stylelint-disable selector-no-qualifying-type
button::-moz-focus-inner,
input[type='color']::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner {
  border: 0;
  padding-block: 0;
  padding-inline: 0;
}
// stylelint-enable selector-no-qualifying-type

:focus {
  outline: 0;
}

:focus-visible:not(.has-invisible-focus) {
  @include use-focus-outline();
}

// Scroll padding allowance above anchor links
:target {
  scroll-padding-block-start: 2rem;
}

// Scroll padding allowance below focused elements to ensure they are clearly in view
:focus {
  scroll-padding-block-end: 8vh;
}
