.progress {
  --_progress-height: var(--progress-height, 3rem);
  --_progress-color: var(--progress-color, var(--text-color));
  --_progress-font-size: var(--progress-font-size, 1.4rem);
  --_progress-background-color: var(--progress-background-color, var(--color-gray-100));
  --_progress-border-color: var(--progress-border-color, var(--color-gray-300));
  --_progress-border-width: var(--progress-border-width, 1px);

  border: var(--_progress-border-width) solid var(--_progress-border-color);
  overflow: hidden;
  position: relative;
}

.progress__progress {
  background-color: var(--_progress-background-color);
  height: var(--_progress-height);
  text-align: center;
}

.progress__label {
  color: var(--_progress-color);
  font-size: var(--_progress-font-size);
  inset-block-start: 50%;
  inset-inline-start: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
