.alert {
  @include use-responsive-config(--alert-padding-block --alert-padding-inline, responsive-map(xs 2rem, xl 3rem));

  // Alert
  --_alert-background-color: var(--alert-background-color, var(--secondary-background-color));
  --_alert-border-radius: var(--alert-border-radius, 0);
  --_alert-border: var(--alert-border, none);
  --_alert-color: var(--alert-color, var(--text-color));
  --_alert-font-size: var(--alert-font-size, var(--font-size-default));
  --_alert-line-height: var(--alert-line-height, var(--line-height-body));

  // Icon
  --_alert-icon-background-color: var(--alert-icon-background-color, var(--primary-color));
  --_alert-icon-border-radius: var(--alert-icon-border-radius, 0);
  --_alert-icon-border-color: var(--alert-icon-border-color, transparent);
  --_alert-icon-border-width: var(--alert-icon-border-width, 0);
  --_alert-icon-border-style: var(--alert-icon-border-style, solid);
  --_alert-icon-color: var(--alert-icon-color, var(--color-white));

  // Calculated values
  --_alert-icon-size: var(--alert-icon-size, calc(var(--_alert-font-size) * 1.8));
  --_alert-icon-box-size: var(--alert-icon-box-size, calc(var(--_alert-icon-size) * 1.6));

  font-size: var(--_alert-font-size);
}

.alert__icon {
  align-items: center;
  background-color: var(--_alert-icon-background-color);
  border-color: var(--_alert-icon-border-color);
  border-radius: var(--_alert-icon-border-radius);
  border-style: var(--_alert-icon-border-style);
  border-width: var(--_alert-icon-border-width);
  color: var(--_alert-icon-color);
  display: flex;
  font-size: var(--_alert-icon-size);
  height: var(--_alert-icon-box-size);
  justify-content: center;
  margin-block-end: calc(var(--_alert-icon-box-size) * -0.5);
  margin-inline-start: var(--_alert-padding-inline);
  position: relative;
  width: var(--_alert-icon-box-size);
}

.alert__text {
  background-color: var(--_alert-background-color);
  border: var(--_alert-border);
  border-radius: var(--_alert-border-radius);
  color: var(--_alert-color);
  line-height: var(--_alert-line-height);
  padding-block: var(--_alert-padding-block);
  padding-inline: var(--_alert-padding-inline);

  .alert__icon + & {
    padding-block-start: calc(var(--_alert-padding-block) + var(--_alert-icon-box-size) * 0.33);
  }
}
