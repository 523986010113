.switch {
  --_switch-gap: var(--switch-gap, 1.5rem);
  --_switch-foreground-color: var(--switch-foreground-color, var(--control-switch-foreground-color));
  --_switch-background-color: var(--switch-background-color, var(--control-switch-background-color));
  --_switch-text-color: var(--switch-text-color, var(--control-color));
  --_switch-size: var(--switch-size, 2.8rem);
  --_switch-font-size: var(--switch-font-size, var(--font-size-meta));
  --_switch-inner-padding: var(--switch-inner-padding, 0.5rem);

  // :checked
  --_switch-background-color--checked: var(--switch-background-color--checked, var(--control-switch-background-color--checked));
  --_switch-foreground-color--checked: var(--switch-foreground-color--checked, var(--control-switch-foreground-color--checked));

  align-items: center;
  display: flex;
  gap: var(--_switch-gap);
}

.switch__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;

  &:focus {
    outline: 0;
  }
}

.switch__switch {
  --_switch-bullet-size: calc(var(--_switch-size) - var(--_switch-inner-padding) * 2);

  background-color: var(--_switch-background-color);
  border-radius: calc(var(--_switch-size) / 2);
  cursor: pointer;
  display: block;
  height: var(--_switch-size);
  position: relative;
  transition-property: background-color;
  width: calc(var(--_switch-bullet-size) * 2 + var(--_switch-inner-padding) * 2);

  .switch__input:focus-visible ~ & {
    @include use-focus-outline();
  }

  &::before {
    background-color: var(--_switch-foreground-color);
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
    content: '';
    height: var(--_switch-bullet-size);
    inset-block-start: var(--_switch-inner-padding);
    inset-inline-start: var(--_switch-inner-padding);
    position: absolute;
    transition-property: background-color, translate;
    width: var(--_switch-bullet-size);
  }
}

.switch__input:checked ~ .switch__switch {
  background-color: var(--_switch-background-color--checked);

  &::before {
    background-color: var(--_switch-foreground-color--checked);
    translate: 100% 0;
  }
}

.switch__label {
  --line-clamp: 1;

  color: var(--_switch-text-color);
  display: flex;
  font-size: var(--_switch-font-size);
  line-height: var(--_switch-size);
}

.switch__label-on {
  display: none;
}

.switch__input:checked ~ .switch__switch ~ .switch__label .switch__label-on {
  display: block;
}

.switch__input:checked ~ .switch__switch ~ .switch__label .switch__label-off {
  display: none;
}
