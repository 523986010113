@use 'sass:color';

.t-spd-mvp-2023 {
  // Colors
  --primary-color: var(--color-mvp-23-blue-900);
  --secondary-color: var(--color-spd-red);

  // Color configuration
  --caption-color: var(--color-mvp-23-blue-800);
  --control-background-color--enter: var(--color-white);
  --control-background-color: var(--color-white);
  --control-border-color--enter: var(--primary-color);
  --control-border-color: var(--primary-color);
  --control-color--enter: var(--color-black);
  --control-color: var(--primary-color);
  --control-switch-background-color--checked: var(--color-mvp-23-blue-900);
  --control-switch-background-color: var(--color-mvp-23-blue-200);
  --control-switch-foreground-color--checked: var(--color-white);
  --control-switch-foreground-color: var(--color-white);
  --line-color: var(--color-mvp-23-blue-200);
  --preview-background-color: var(--color-white);
  --primary-background-color: var(--color-primary);
  --primary-heading-color: var(--primary-color);
  --primary-interactive-background-color--disabled: var(--color-white);
  --primary-interactive-background-color--enter: var(--color-white);
  --primary-interactive-background-color: var(--color-white);
  --primary-interactive-color--disabled: var(--color-gray-200);
  --primary-interactive-color--enter: var(--color-mvp-23-blue-950);
  --primary-interactive-color: var(--primary-color);
  --secondary-background-color: var(--color-mvp-23-blue-050);
  --secondary-interactive-background-color--disabled: var(--color-white);
  --secondary-interactive-background-color--enter: var(--color-white);
  --secondary-interactive-background-color: var(--color-white);
  --secondary-interactive-color--disabled: var(--color-mvp-23-blue-200);
  --secondary-interactive-color--enter: var(--color-mvp-23-blue-950);
  --secondary-interactive-color: var(--primary-color);
  --text-color: var(--color-gray-900);
  --meta-color: var(--color-gray-600);
  --tooltip-background-color: var(--color-black);
  --tooltip-color: var(--color-white);

  // Font
  --root-font-family: var(--font-family-metropolis);
  --root-color: var(--text-color);
  --root-font-width: var(--font-width-normal);
  --root-font-weight: var(--font-weight-regular);

  // Body
  --body-background-color: var(--color-mvp-23-blue-100);
  --body-font-size: var(--font-size-default);

  // Focus outlines
  --focus-outline-width: 3px;
  --focus-outline-color: var(--color-black);
  --focus-outline-offset: 3px;

  // Selection
  --selection-background-color: var(--color-black);
  --selection-foreground-color: var(--color-white);

  // @alert
  --alert-icon-background-color: var(--secondary-color);

  // @bullet-list
  --bullet-list-unordered-marker: square;

  // @button
  --button-underline-color--enter: currentColor;
  --button-underline-height: 0.12em;

  // @date-square
  --date-square-background-color: var(--secondary-color);

  // @icon-button
  --icon-button-underline-color--enter: currentColor;

  // @label
  --label-color: var(--primary-color);

  // @link
  --link-color: var(--secondary-color);
  --link-color--enter: var(--secondary-color);

  // @notice
  --notice-color: var(--primary-color);
  --notice-icon-background-color: var(--primary-color);

  // @quote
  --quote-color: var(--secondary-color);
  --quote-caption-color: var(--primary-color);

  // @table
  --table-cell-background-color-odd: var(--color-mvp-23-blue-050);
  --table-hcell-color: var(--primary-color);

  // @breadcrumb
  --breadcrumb-text-color: var(--primary-color);
  --breadcrumb-arrow-color: var(--primary-color);
  --breadcrumb-icon-color: var(--primary-color);

  // @card
  --card-background-color: var(--primary-color);
  --card-background-color--enter: var(--color-mvp-23-blue-950);

  // @grid-teaser
  --grid-teaser-link-color: var(--secondary-color);
  --grid-teaser-link-color--enter: var(--secondary-color);

  // @social-media-post
  --social-media-post-icon-background-color: var(--primary-color);
  --social-media-post-meta-color: var(--primary-color);

  // @tab-bar
  --tab-bar-label-color: var(--secondary-color);
  --tab-bar-option-color: var(--primary-color);
  --tab-bar-option-color--selected: var(--primary-color);

  // @teaser
  --teaser-meta-color: var(--primary-color);

  // @text-breaker
  --text-breaker-link-color: var(--secondary-color);
  --text-breaker-link-color--enter: var(--secondary-color);

  // @banderole
  --banderole-background-color: var(--color-spd-red);
  --banderole-color: var(--color-white);

  // @footer
  --footer-logo-title-color: var(--primary-color);
  --footer-logo-subtitle-color: var(--primary-color);
  --footer-social-media-color: var(--primary-color);

  // @header
  --header-title-color: var(--primary-color);
  --header-subtitle-color: var(--primary-color);

  // @page
  --page-inner-background-color: var(--color-white);

  // @slider
  --slider-scroll-bar-background-color: var(--color-mvp-23-blue-100);
  --slider-scroll-bar-foreground-color: var(--primary-color);

  // @stage
  --stage-tile-background-color: var(--secondary-color);
  --stage-tile-color: var(--color-white);
  --stage-inner-background-color: var(--color-white);

  // @section
  --section-background-color--light: var(--secondary-background-color);
  --section-background-color--dark: var(--primary-color);

  // @portal
  --portal-font-factor: 0.88;

  // @footer
  .footer {
    @include use-container($padding: false);

    --body-padding: var(--inner-container-padding);
  }

  // @section
  [data-theme='light'] {
    --section-background-color: var(--secondary-background-color);
    --teaser-background-color: var(--color-white);
    --grid-teaser-background-color: var(--color-white);
    --tab-bar-background-color: var(--secondary-background-color);
    --social-media-post-background-color: var(--color-white);
    --social-media-post-link-background-color: var(--secondary-background-color);
    --social-media-post-repost-background-color: var(--secondary-background-color);
    --slider-scroll-bar-background-color: var(--color-white);
    --slider-scroll-bar-foreground-color: var(--primary-color);
    --tile-background-color: var(--color-white);
    --form-breaker-background-color: var(--secondary-background-color);
    --form-breaker-alert-background-color: var(--color-white);
  }

  [data-theme='dark'] {
    --section-button-background-color--enter: var(--color-mvp-23-blue-200);
    --section-button-background-color: var(--color-white);
    --section-button-color--enter: var(--primary-color);
    --section-button-color: var(--primary-color);
    --icon-button-icon-background-color--enter: var(--color-mvp-23-blue-200);
    --icon-button-icon-background-color: var(--color-white);
    --icon-button-icon-color--enter: var(--primary-color);
    --icon-button-icon-color: var(--primary-color);
    --secondary-interactive-background-color--disabled: var(--color-mvp-23-blue-400);
    --secondary-interactive-color--disabled: var(--color-mvp-23-blue-700);
    --section-background-color: var(--primary-color);
    --section-headline-color: var(--color-white);
    --section-text-color: var(--color-white);
    --slider-scroll-bar-background-color: var(--color-mvp-23-blue-600);
    --slider-scroll-bar-foreground-color: var(--color-white);
    --grid-teaser-background-color: var(--color-white);
    --grid-teaser-headline-color: var(--primary-color);
    --grid-teaser-headline-color--enter: var(--primary-color);
    --text-breaker-color: var(--color-white);
    --text-breaker-link-color: var(--color-white);
    --text-breaker-link-color--enter: var(--color-white);
    --tab-bar-background-color: var(--primary-color);
    --tab-bar-label-color: var(--color-white);
    --tab-bar-option-color: var(--color-white);
    --tab-bar-option-color--selected: var(--color-white);
    --tab-bar-option-underline-color--selected: var(--color-white);
    --icon-button-color: var(--color-white);
    --icon-button-color--enter: var(--color-white);
    --quote-color: var(--color-white);
    --quote-caption-color: var(--color-white);
    --quote-copyright-color: #{color.mix($color-spd-red, $color-white, $weight: 4.9%)};
    --form-breaker-background-color: var(--primary-color);
    --form-breaker-headline-color: var(--color-white);
    --form-breaker-color: var(--color-white);
    --form-breaker-control-color: var(--color-white);
    --form-breaker-link-color: var(--color-white);
    --form-breaker-link-color--enter: var(--color-white);
    --form-breaker-label-color: var(--color-white);
    --form-breaker-alert-icon-border-color: var(--color-gray-050);
    --form-breaker-alert-icon-border-width: 2px;
    --button-background-color: var(--color-white);
    --button-color: var(--primary-color);
    --required-color: var(--color-white);
  }
}
