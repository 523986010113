.portal {
  --_portal-padding-block: var(--portal-padding-block, 2rem);
  --_portal-padding-inline: var(--portal-padding-inline, 2rem);
  --_portal-headline-color: var(--portal-headline-color, var(--color-white));
  --_portal-arrow-color: var(--portal-arrow-color, var(--color-white));

  height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
  position: relative;
}

.portal {
  background-color: var(--portal-background-color, var(--primary-color));
  container-name: portal;
  container-type: inline-size;

  @supports (-webkit-touch-callout: none) {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    height: -webkit-fill-available;
  }
}

.portal__image {
  --image-z-index: 1;

  display: flex;
  flex-direction: column;
  inset: 0;
  pointer-events: none;
  position: absolute;
  user-select: none;
  z-index: 1;

  &::after {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), cubic-bezier(0.48, 0.3, 0.64, 1), transparent);
    content: '';
    height: 40%;
    inset-block-end: 0;
    inset-inline: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
}

.portal__copyright {
  inset-block-start: var(--_portal-padding-block);
  inset-inline-end: var(--_portal-padding-inline);
  position: absolute;
  z-index: 3;
}

.portal__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: flex-end;
  padding-inline: var(--_portal-padding-inline);
  position: absolute;
  text-align: center;
  z-index: 2;
}

.portal__headline {
  --headline-color: var(--_portal-headline-color);
  --headline-kicker-color: var(--_portal-headline-color);
  --headline-font-size: calc(max(2.2rem, 4cqi) * var(--portal-font-factor, 1));
  --headline-kicker-font-size: max(1.4rem, 0.4em);
  --headline-kicker-spacing: 0.7em;
  --headline-text-transform: var(--portal-headline-text-transform, none);

  @container (inline-size >= 55rem) {
    margin-inline: auto;
    max-width: 60cqi;
  }
}

.portal__headline--long {
  --headline-font-size: max(2.2rem, 3.2cqi);

  text-wrap: balance;
}

.portal__arrow {
  color: var(--_portal-arrow-color);
  display: inline-block;
  font-size: clamp(4rem, 3cqi, 5rem);
  padding-block: clamp(var(--_portal-padding-block), 2cqi, 4rem);
}
