.buttons--horizontal {
  --buttons-align-items: center;
  --buttons-flex-direction: row;
}

.buttons--centered {
  --buttons-align-items: center;
  --buttons-text-align: center;
  --buttons-justify-content: center;
}

.buttons__label {
  margin-block-end: var(--buttons-label-margin-block, 2rem);
}

.buttons__headline {
  margin-block-end: var(--buttons-headline-margin-block, 2rem);
}

.buttons__buttons {
  align-items: var(--buttons-align-items, flex-start);
  display: flex;
  flex-direction: var(--buttons-flex-direction, column);
  flex-wrap: wrap;
  gap: var(--buttons-gap, 2rem);
  justify-content: var(--buttons-justify-content, flex-start);
  padding-block: var(--buttons-padding-block, 0);
  padding-inline: var(--buttons-padding-inline, 0);
  text-align: var(--buttons-text-align, left);
}
