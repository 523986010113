.form-breaker {
  @include use-responsive-config(--form-breaker-padding-block, responsive-map(xs 4rem, xl 6rem));

  --headline-color: var(--form-breaker-headline-color, var(--primary-heading-color));
  --control-color: var(--form-breaker-control-color, var(--text-color));
  --label-color: var(--form-breaker-label-color, var(--text-color));
  --link-color: var(--form-breaker-link-color, var(--primary-interactive-color));
  --link-color--enter: var(--form-breaker-link-color--enter, var(--primary-interactive-color--enter));
  --alert-background-color: var(--form-breaker-alert-background-color, var(--secondary-background-color));
  --alert-icon-background-color: var(--form-breaker-alert-icon-background-color, var(--primary-color));
  --alert-icon-color: var(--form-breaker-alert-icon-color, var(--color-white));
  --alert-icon-border-color: var(--form-breaker-alert-icon-border-color, transparent);
  --alert-icon-border-width: var(--form-breaker-alert-icon-border-width, 0);

  color: var(--form-breaker-color, var(--text-color));
  container-name: form-breaker;
  container-type: inline-size;
}

.form-breaker__inner {
  @include use-container();

  background-color: var(--form-breaker-background-color, transparent);
  column-gap: var(--form-breaker-column-gap, var(--gap));
  display: grid;
  padding-block: var(--_form-breaker-padding-block);
  row-gap: var(--form-breaker-row-gap, 4rem);

  @container form-breaker (min-width: 70rem) {
    grid-template-columns: auto #{column-width(6)};
  }

  @container form-breaker (min-width: 90rem) {
    grid-template-columns: auto #{column-width(7)};
  }
}

.form-breaker__head {
  display: flex;
  flex-direction: column;
  row-gap: var(--form-breaker-head-gap, 2rem);
}

.form-breaker__text {
  font-size: var(--form-breaker-text-font-size, var(--font-size-paragraph));
  line-height: var(--line-height-body);

  > * + * {
    margin-block-start: 0.25em;
  }
}
