.tile {
  --_tile-font-size: var(--tile-font-size, var(--font-size-meta));
  --_tile-background-color: var(--tile-background-color, var(--secondary-background-color));
  --_tile-headline-kicker-color: var(--tile-headline-kicker-color, var(--text-color));
  --_tile-headline-kicker-font-weight: var(--tile-headline-kicker-font-weight, var(--font-weight-bold));
  --_tile-icon-background-color: var(--tile-icon-background-color, var(--text-color));
  --_tile-icon-border-color: var(--tile-icon-border-color, transparent);
  --_tile-icon-border-radius: var(--tile-icon-border-radius, 50%);
  --_tile-icon-border-style: var(--tile-icon-border-style, solid);
  --_tile-icon-border-width: var(--tile-icon-border-width, 0);
  --_tile-icon-color: var(--tile-icon-color, var(--color-white));
  --_tile-icon-size: var(--tile-icon-size, 3.2rem);
  --_tile-padding-block: var(--tile-padding-block, 2rem);
  --_tile-padding-inline: var(--tile-padding-inline, 2rem);

  // :hover, :focus & :active
  --_tile-background-color--enter: var(--tile-background-color--enter, var(--secondary-background-color));
  --_tile-headline-color--enter: var(--tile-headline-color--enter, var(--color-spd-red));
  --_tile-headline-kicker-color--enter: var(--tile-headline-kicker-color--enter, var(--text-color));
  --_tile-icon-background-color--enter: var(--tile-icon-background-color--enter, var(--color-spd-red));
  --_tile-icon-border-color--enter: var(--tile-icon-border-color--enter, transparent);
  --_tile-icon-color--enter: var(--tile-icon-color--enter, var(--color-white));

  container-name: tile;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &:has(:any-link:is(:hover:not([disabled]), :focus-visible:not(.has-invisible-focus))),
  &.tile--active {
    --_tile-background-color: var(--_tile-background-color--enter);
    --_tile-headline-color: var(--_tile-headline-color--enter);
    --_tile-headline-kicker-color: var(--_tile-headline-kicker-color--enter);
    --_tile-icon-background-color: var(--_tile-icon-background-color--enter);
    --_tile-icon-border-color: var(--_tile-icon-border-color--enter);
    --_tile-icon-color: var(--_tile-icon-color--enter);
  }
}

.tile__image {
  --image-z-index: 1;

  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: relative;
  z-index: 3;
}

.tile__copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  pointer-events: all;
  position: absolute;
  z-index: 2;
}

.tile__content {
  background-color: var(--_tile-background-color);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-block-end: calc(var(--_tile-icon-size) / 2 + var(--_tile-padding-block));
  padding-block-start: var(--_tile-padding-block);
  padding-inline: var(--_tile-padding-inline);
  position: relative;
  row-gap: var(--tile-row-gap, 1rem);
  transition-property: background-color;
  z-index: 1;
}

.tile__headline {
  --overlay-link-inset: 0 0 calc(var(--_tile-icon-size) / -2);
  --headline-kicker-text-transform: none;
  --headline-kicker-font-weight: var(--_tile-headline-kicker-font-weight);
  --headline-kicker-color: var(--_tile-headline-kicker-color);

  .tile:has(.tile__image) & {
    --overlay-link-inset: -100cqi 0 calc(var(--_tile-icon-size) / -2);
  }
}

.tile__description {
  font-size: var(--_tile-font-size);
  line-height: var(--line-height-body);
}

.tile__icon {
  align-items: center;
  background-color: var(--_tile-icon-background-color);
  block-size: var(--_tile-icon-size);
  border-color: var(--_tile-icon-border-color);
  border-radius: var(--_tile-icon-border-radius);
  border-style: var(--_tile-icon-border-style);
  border-width: var(--_tile-icon-border-width);
  color: var(--_tile-icon-color);
  display: flex;
  font-size: calc(var(--_tile-icon-size) * 0.5);
  inline-size: var(--_tile-icon-size);
  justify-content: center;
  margin-block-start: calc(var(--_tile-icon-size) / -2);
  margin-inline-end: var(--_tile-padding-inline);
  margin-inline-start: auto;
  position: relative;
  transition-property: background-color, border-color, color;
  z-index: 2;
}
