.date-square {
  align-items: center;
  background-color: var(--date-square-background-color, var(--primary-color));
  block-size: var(--date-square-block-size, 15rem);
  color: var(--date-square-color, var(--color-white));
  container-name: date-square;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  font-weight: var(--date-square-font-weight, var(--font-weight-bold));
  inline-size: var(--date-square-inline-size, 15rem);
  justify-content: center;
  position: relative;
  z-index: 4;
}

.date-square__weekday {
  font-size: 14cqw;
  margin-block-end: 1cqw;
  width: 75cqw;
}

.date-square__day {
  font-size: 43cqw;
  width: 75cqw;
}

.date-square__month {
  font-size: 14cqw;
  width: 75cqw;
}
