@use 'mq';

.header {
  @include use-responsive-config(--header-column-gap, responsive-map(xs 1.5rem, xl 2rem));
  @include use-responsive-config(--header-row-gap, responsive-map(xs 1.5rem, xl 2rem));
  @include use-responsive-config(--header-padding-block, responsive-map(xs 1.6rem, xl 3rem));
  @include use-responsive-config(--header-font-size, responsive-map(xs 1.6rem, xl 2.4rem));
  @include use-responsive-config(--header-logo-max-height, responsive-map(xs 5.6rem, xl 9rem));
  @include use-responsive-config(--header-button-font-size, responsive-map(xs 4.4rem, xl 6rem));

  --_header-button-background-color: var(--header-button-background-color, transparent);
  --_header-button-color: var(--header-button-color, var(--primary-interactive-color));

  // Title
  --_header-title-color: var(--header-title-color, var(--text-color));
  --_header-title-font-family: var(--header-title-font-family, inherit);
  --_header-title-font-weight: var(--header-title-font-weight, var(--font-weight-bold));
  --_header-title-letter-spacing: var(--header-title-letter-spacing, 0);
  --_header-title-text-transform: var(--header-title-text-transform, none);

  // Subtitle
  --_header-subtitle-color: var(--header-subtitle-color, var(--text-color));
  --_header-subtitle-font-family: var(--header-subtitle-font-family, inherit);
  --_header-subtitle-font-size: var(--header-subtitle-font-size, 0.8em);
  --_header-subtitle-font-weight: var(--header-subtitle-font-weight, var(--font-weight-regular));
  --_header-subtitle-letter-spacing: var(--header-subtitle-letter-spacing, 0);
  --_header-subtitle-text-transform: var(--header-subtitle-text-transform, none);

  // :hover, :focus & :active
  --_header-button-background-color--enter: var(--header-button-background-color--enter, var(--primary-interactive-color));
  --_header-button-color--enter: var(--header-button-color--enter, var(--color-white));
}

.header__inner {
  @include use-container();

  column-gap: var(--_header-column-gap);
  display: grid;
  padding-block: var(--_header-padding-block);
  row-gap: var(--_header-row-gap);
}

.header__sender {
  align-items: center;
  column-gap: var(--_header-column-gap);
  display: flex;
  grid-area: sender;
  justify-content: flex-start;
}

.header__logo {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  grid-area: logo;
}

.header__logo-img {
  flex-shrink: 0;
  height: var(--_header-logo-max-height);
  width: auto;
}

.header__logo-img--long {
  height: calc(var(--_header-logo-max-height) * 0.75);
}

.header__text {
  column-gap: 0.7em;
  display: flex;
  flex-direction: column;
  font-size: var(--_header-font-size);
  grid-area: logo-text;
  justify-content: center;
  line-height: var(--line-height-small);
  row-gap: 0.3em;
}

.header__title {
  color: var(--_header-title-color);
  font-family: var(--_header-title-font-family);
  font-weight: var(--_header-title-font-weight);
  letter-spacing: var(--_header-title-letter-spacing);
  text-transform: var(--_header-title-text-transform);
}

.header__subtitle {
  color: var(--_header-subtitle-color);
  font-family: var(--_header-subtitle-font-family);
  font-size: var(--_header-subtitle-font-size);
  font-weight: var(--_header-subtitle-font-weight);
  letter-spacing: var(--_header-subtitle-letter-spacing);
  text-transform: var(--_header-subtitle-text-transform);
}

.header__actions {
  column-gap: var(--_header-column-gap);
  display: flex;
  grid-area: actions;
  justify-content: flex-end;
}

.header__menu {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  grid-area: menu;
}

.header__search {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  grid-area: search;
}

.header__button {
  align-items: center;
  aspect-ratio: 1;
  color: var(--_header-button-color);
  container-name: header-button;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-bold);
  justify-content: center;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  transition-property: background-color, color;
  width: var(--_header-button-font-size);

  &:is(:hover, :focus-visible:not(.has-invisible-focus)) {
    background-color: var(--_header-button-background-color--enter);
    color: var(--_header-button-color--enter);
  }
}

.header__button-icon {
  font-size: 60cqi;
}

.header__button-text {
  font-size: 22cqi;
  margin-block-start: 7cqi;
}

.header--center {
  @include mq.mq($until: m) {
    .header__inner {
      grid-template-areas: 'menu logo search' 'logo-text logo-text logo-text';
      grid-template-columns: min-content auto min-content;
    }

    .header__sender,
    .header__actions {
      display: contents;
    }

    .header__logo {
      justify-content: center;
    }

    .header__text {
      text-align: center;
    }
  }

  @include mq.mq($from: m) {
    .header__inner {
      grid-template-areas: 'logo-text logo actions';
      grid-template-columns: column-width(4) column-width(4) column-width(4);
      justify-content: space-between;
    }

    .header__logo {
      justify-content: center;
    }

    .header__sender {
      display: contents;
    }
  }
}

.header--left,
.header--left-stacked {
  @include mq.mq($until: m) {
    .header__inner {
      grid-template-areas: 'logo-text actions';
      justify-content: space-between;
    }

    &.header--with-logo .header__inner {
      grid-template-areas: 'logo actions' 'logo-text logo-text';
    }

    .header__sender {
      display: contents;
    }
  }
}

.header--left {
  @include mq.mq($from: m) {
    .header__inner {
      grid-template-areas: 'sender actions';
      justify-content: space-between;
    }
  }
}

.header--left-stacked {
  @include mq.mq($from: m) {
    .header__logo-img {
      max-height: calc(var(--_header-logo-max-height) * 0.75);
    }

    .header__text {
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
    }

    .header__subtitle {
      font-size: 1em;
    }
  }
}
