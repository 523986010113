html {
  color: var(--text-color);
  font-family: var(--root-font-family);
  font-optical-sizing: none;
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-synthesis-weight: none;
  height: auto;
  line-height: 1;
  min-height: 100%;
  min-width: var(--root-min-width);
  scroll-behavior: smooth;
}
