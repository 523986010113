@use 'mq';

.external-video-slide {
  @include use-responsive-config(--external-video-slide-padding-block, responsive-map(xs 4rem, xl 6rem));

  --_external-video-slide-background-color: var(--external-video-slide-background-color, var(--secondary-background-color));
  --_external-video-slide-padding-inline: var(--external-video-slide-padding-inline, var(--inner-container-padding));
  --_external-video-slide-color: var(--external-video-slide-color, var(--color-white));
  --_external-video-slide-font-size: var(--external-video-slide-font-size, var(--font-size-teaser));

  background-color: var(--_external-video-slide-background-color);
  display: flex;
  flex-direction: column;
  position: relative;
}

.external-video-slide__media {
  --external-video-aspect-ratio: 1364 / 660;

  position: relative;
  z-index: 1;

  @include mq.mq($from: l) {
    --external-video-play-size: 0.1;
    --external-video-warning-padding-block: var(--_external-video-slide-padding-block);
    --external-video-warning-padding-inline: var(--_external-video-slide-padding-inline);
    --external-video-warning-text-align: right;
    --external-video-warning-justify-content: flex-end;
  }
}

.external-video-slide__content {
  display: flex;
  flex-direction: column;
  order: 1;
  padding-block: var(--_external-video-slide-padding-block);
  padding-inline: var(--_external-video-slide-padding-inline);
  pointer-events: none;
  row-gap: 2rem;

  @include mq.mq($from: l) {
    inset-block-end: 0;
    inset-inline-start: var(--_external-video-slide-padding-inline);
    padding-inline: 0;
    position: absolute;
    width: column-width(4, $is-absolute: true);
    z-index: 2;
  }
}

.external-video-slide__headline {
  --headline-text-transform: uppercase;

  pointer-events: none;

  @include mq.mq($from: l) {
    --headline-color: var(--_external-video-slide-color);
  }
}
