.link-tile {
  @include use-responsive-config(--link-tile-min-height, (xs none, s 18rem, m 22rem));

  --_link-tile-background-color: var(--link-tile-background-color, var(--secondary-background-color));
  --_link-tile-border-color: var(--link-tile-border-color, transparent);
  --_link-tile-border-radius: var(--link-tile-border-radius, 0);
  --_link-tile-border-style: var(--link-tile-border-style, solid);
  --_link-tile-border-width: var(--link-tile-border-width, 0);
  --_link-tile-color: var(--link-tile-color, var(--text-color));
  --_link-tile-font-size: var(--link-tile-font-size, var(--font-size-default));
  --_link-tile-font-weight: var(--link-tile-font-weight, var(--font-weight-bold));
  --_link-tile-padding-block: var(--link-tile-padding-block, 2rem);
  --_link-tile-padding-inline: var(--link-tile-padding-inline, 2rem);
  --_link-tile-underline-color: var(--link-tile-underline-color, transparent);
  --_link-tile-underline-height: var(--link-tile-underline-height, 0.08em);
  --_link-tile-underline-offset: var(--link-tile-underline-offset, 0.2em);

  // Icon
  --_link-tile-icon-background-color: var(--link-tile-icon-background-color, var(--primary-interactive-color));
  --_link-tile-icon-border-color: var(--link-tile-icon-border-color, transparent);
  --_link-tile-icon-border-radius: var(--link-tile-icon-border-radius, 50%);
  --_link-tile-icon-border-style: var(--link-tile-icon-border-style, solid);
  --_link-tile-icon-border-width: var(--link-tile-icon-border-width, 0);
  --_link-tile-icon-box-size: var(--link-tile-icon-box-size, 2em);
  --_link-tile-icon-color: var(--link-tile-icon-color, var(--primary-interactive-background-color));
  --_link-tile-icon-size: var(--link-tile-icon-size, 2em);

  // :hover, :focus & :active
  --_link-tile-background-color--enter: var(--link-tile-background-color--enter, var(--secondary-interactive-color--enter));
  --_link-tile-border-color--enter: var(--link-tile-border-color--enter, transparent);
  --_link-tile-color--enter: var(--link-tile-color--enter, var(--primary-interactive-background-color--enter));
  --_link-tile-icon-background-color--enter: var(--link-tile-icon-background-color--enter, var(--secondary-interactive-background-color--enter));
  --_link-tile-icon-border-color--enter: var(--link-tile-icon-border-color--enter, transparent);
  --_link-tile-icon-color--enter: var(--link-tile-icon-color--enter, var(--primary-interactive-color--enter));
  --_link-tile-underline-color--enter: var(--link-tile-underline-color--enter, transparent);
  --_link-tile-underline-height--enter: var(--link-tile-underline-height--enter, 0.08em);
  --_link-tile-underline-offset--enter: var(--link-tile-underline-offset--enter, 0.2em);

  align-items: flex-start;
  aspect-ratio: var(--link-tile-aspect-ratio, auto);
  background-color: var(--_link-tile-background-color);
  border-color: var(--_link-tile-border-color);
  border-radius: var(--_link-tile-border-radius);
  border-style: var(--_link-tile-border-style);
  border-width: var(--_link-tile-border-width);
  color: var(--_link-tile-color);
  column-gap: 1em;
  display: flex;
  flex-direction: column;
  font-size: var(--_link-tile-font-size);
  font-weight: var(--_link-tile-font-weight);
  justify-content: var(--link-tile-justify-content, center);
  line-height: var(--line-height-body);
  min-height: var(--_link-tile-min-height);
  padding-block: var(--_link-tile-padding-block);
  padding-inline: var(--_link-tile-padding-inline);
  row-gap: 1em;
  text-align: var(--link-tile-text-align, left);
  transition-property: background-color, border-color, color;
  width: 100%;

  &:is(:hover:not([disabled]), :focus-visible:not(.has-invisible-focus)) {
    --_link-tile-background-color: var(--_link-tile-background-color--enter);
    --_link-tile-color: var(--_link-tile-color--enter);
    --_link-tile-border-color: var(--_link-tile-border-color);
    --_link-tile-icon-background-color: var(--_link-tile-icon-background-color--enter);
    --_link-tile-icon-border-color: var(--_link-tile-icon-border-color--enter);
    --_link-tile-icon-color: var(--_link-tile-icon-color--enter);
    --underline-height: var(--_link-tile-underline-height--enter);
    --underline-offset: var(--_link-tile-underline-offset--enter);
  }
}

.link-tile__icon {
  align-items: center;
  background-color: var(--_link-tile-icon-background-color);
  block-size: var(--_link-tile-icon-size);
  border-color: var(--_link-tile-icon-border-color);
  border-radius: var(--_link-tile-icon-border-radius);
  border-style: var(--_link-tile-icon-border-style);
  border-width: var(--_link-tile-icon-border-width);
  color: var(--_link-tile-icon-color);
  display: flex;
  flex-shrink: 0;
  inline-size: var(--_link-tile-icon-size);
  justify-content: center;
  transition-property: background-color, border-color, color;
}

.link-tile__text {
  --underline-color: var(--_link-tile-underline-color);
  --underline-color--enter: var(--_link-tile-underline-color--enter);
  --underline-height: var(--_link-tile-underline-height);
  --underline-offset: var(--_link-tile-underline-offset);
  --underline-transition-duration: var(--duration-default);

  hyphens: auto;
  width: 100%;
}
