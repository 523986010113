@use 'mq';

.breadcrumb {
  --_breadcrumb-arrow-color: var(--breadcrumb-arrow-color, var(--text-color));
  --_breadcrumb-font-size: var(--breadcrumb-font-size, var(--font-size-default));
  --_breadcrumb-icon-color: var(--breadcrumb-icon-color, var(--text-color));
  --_breadcrumb-text-color: var(--breadcrumb-text-color, var(--text-color));
}

.breadcrumb__crumbs {
  --focus-outline-offset: 5px;

  color: var(--_breadcrumb-text-color);
  font-size: var(--_breadcrumb-font-size);
  line-height: 1.5;
  list-style: none;
  padding-block-end: 0.4em;
}

.breadcrumb__crumb {
  display: inline;

  @include mq.mq($until: m) {
    &:not(:nth-last-child(2)) {
      display: none;
    }
  }
}

.breadcrumb__arrow {
  color: var(--_breadcrumb-arrow-color);
  font-size: 0.8em;
  margin-inline: 1em;
  vertical-align: -0.1em;

  @include mq.mq($until: m) {
    display: none;
  }
}

.breadcrumb__link {
  &:is([aria-current='page']) {
    font-weight: var(--font-weight-bold);
  }
}

.breadcrumb__link-icon {
  color: var(--_breadcrumb-icon-color);
  font-size: 1.2em;
  margin-inline-end: 0.5em;
  vertical-align: 0.1em;

  @include mq.mq($from: m) {
    .breadcrumb__crumb:first-child & {
      margin-inline-end: 0;
    }

    .breadcrumb__crumb:not(:first-child) & {
      display: none;
    }
  }
}
