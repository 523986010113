.app {
  font-size: var(--app-font-size, inherit);
  line-height: var(--app-line-height, var(--line-height-body));
}

.app__label {
  margin-block: var(--app-label-margin-block, 0 2rem);
}

.app__content :is(audio, canvas, iframe, img, picture, video) {
  height: revert-layer;
}
