@use 'mq';

.page {
  @include use-responsive-config(--page-header-padding-block, responsive-map(xs 3rem, xl 5rem));
  @include use-responsive-config(--page-easy-language-padding-block, responsive-map(xs 1.5rem, xl 2rem));
  @include use-responsive-config(--page-easy-language-icon-size, responsive-map(xs 3.6rem, xl 4.5rem));
  @include use-responsive-config(--page-bar-padding-block, responsive-map(xs 1.5rem, xl 2.5rem));
  @include use-responsive-config(--page-readspeaker-padding-block, responsive-map(xs 1.5rem, xl 2.5rem));
  @include use-responsive-config(--page-tools-column-gap, responsive-map(xs 1.5rem, xl 2.5rem));
  @include use-responsive-config(--page-block-spacing, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-config(--page-block-spacing-factor, responsive-map(xs 1.5, xl 2));
  @include use-responsive-config(--page-section-padding-block, responsive-map(xs 4rem, xl 6rem));
  @include use-responsive-config(--page-stage-margin-block, responsive-map(xs 4rem, xl 6rem));
  @include use-responsive-config(--page-event-size, responsive-map(xs 13rem, xl 16rem));

  --_page-block-spacing-extended: var(--page-block-spacing-extended, calc(var(--_page-block-spacing) * var(--_page-block-spacing-factor)));
  --_page-block-spacing-full: var(--page-block-spacing-full, var(--_page-section-padding-block));
  --_page-block-spacing-paragraph: var(--page-block-spacing-paragraph, 1em);
  --_page-blocks-padding-block: var(--page-blocks-padding-block, var(--_page-block-spacing-full));
  --_page-date-color: var(--page-date-color, var(--primary-heading-color));
  --_page-date-font-weight: var(--page-date-font-weight, var(--font-weight-bold));
  --_page-sender-color: var(--page-sender-color, var(--meta-color));
  --_page-sender-font-weight: var(--page-date-font-weight, var(--font-weight-regular));
  --_page-header-background-color: var(--page-header-background-color, var(--secondary-background-color));
  --_page-heading-row-gap: var(--page-heading-row-gap, 2rem);
  --_page-headline-font-size: var(--page-headline-font-size, var(--font-size-headline-2));
  --_page-paragraph-font-size: var(--page-paragraph-font-size, var(--font-size-paragraph));
  --_page-bar-border-color: var(--page-bar-border-color, var(--secondary-background-color));
  --_page-bar-border-style: var(--page-bar-border-style, solid);
  --_page-bar-border-width: var(--page-bar-border-width, 0.2rem);
  --_page-inner-background-color: var(--page-inner-background-color, transparent);
  --_page-sender-font-size: var(--page-sender-font-size, var(--font-size-meta));
  --_page-date-font-size: var(--page-date-font-size, var(--font-size-teaser));
  --_page-easy-language-font-size: var(--page-easy-language-font-size, var(--font-size-paragraph));

  background-color: var(--page-background-color, transparent);
  overflow-block: visible;
  overflow-inline: hidden;

  &:has(.page__block:first-child:is([data-block-theme='light'])) {
    --stage-tiles-background-color: var(--section-background-color--light, transparent);
  }

  &:has(.page__block:first-child:is([data-block-theme='dark'])) {
    --stage-tiles-background-color: var(--section-background-color--dark, transparent);
  }
}

.page__easy-language {
  @include use-container();

  --link-color: var(--color-white);
  --link-color--enter: var(--color-white);

  align-items: center;
  background-color: var(--color-easy-language-blue);
  color: var(--color-white);
  display: flex;
  font-size: var(--_page-easy-language-font-size);
  gap: 2rem;
  line-height: var(--line-height-body);
  padding-block: var(--_page-easy-language-padding-block);
  position: relative;
}

.page__easy-language-icon {
  flex-shrink: 0;
  font-size: var(--_page-easy-language-icon-size);
}

.page__header {
  @include use-container();

  background-color: var(--_page-header-background-color);
  display: grid;
  padding-block: var(--_page-header-padding-block);
  position: relative;
  row-gap: var(--_page-header-padding-block);

  .page--event & {
    margin-block-start: calc(var(--_page-event-size) / 2);
    padding-block-start: calc(var(--_page-header-padding-block) + min(var(--_page-event-size) / 2, 6.5rem));
  }

  .page--event .page__easy-language + & {
    margin-block-start: calc(var(--_page-event-size) / 1.5);
  }
}

.page__event {
  --date-square-inline-size: var(--_page-event-size);
  --date-square-block-size: var(--_page-event-size);

  inline-size: var(--_page-event-size);
  inset-block-start: calc(var(--_page-event-size) / -2);
  inset-inline-start: var(--inner-container-padding);
  position: absolute;
  z-index: 2;
}

.page__head {
  align-items: center;
  column-gap: var(--gap);
  display: flex;
  justify-content: space-between;
  row-gap: 4rem;

  @include mq.mq($until: m) {
    flex-wrap: wrap;
  }
}

.page__heading {
  @include use-column-width(max-width, (xs 12, m 8));

  display: flex;
  flex-direction: column;
  row-gap: var(--_page-heading-row-gap);
}

.page__headline {
  --headline-font-size: var(--_page-headline-font-size);
}

.page__date {
  color: var(--_page-date-color);
  display: block;
  font-size: var(--_page-date-font-size);
  font-weight: var(--_page-date-font-weight);
}

.page__sender {
  --link-color: var(--_page-sender-color);
  --link-underline-color: currentColor;
  --link-color--enter: var(--_page-sender-color);
  --link-underline-color--enter: transparent;

  color: var(--_page-sender-color);
  display: block;
  font-size: var(--_page-sender-font-size);
  font-weight: var(--_page-sender-font-weight);
}

.page__image {
  @include use-column-width(width, (xs 12, m 6));

  --image-z-index: 1;

  align-self: flex-start;
  flex-shrink: 0;
  position: relative;

  @include mq.mq($from: m) {
    .page--event & {
      margin-block-start: calc(max(var(--_page-event-size) / -2, -6.5rem));
    }
  }
}

.page__inner {
  @include use-container($padding: false);

  background-color: var(--_page-inner-background-color);
}

.page__bar {
  @include use-container();

  border-block-end-width: var(--_page-bar-border-width);
  border-color: var(--_page-bar-border-color);
  border-style: var(--_page-bar-border-style);
  column-gap: var(--gap);
  display: flex;
  padding-block: var(--_page-bar-padding-block);

  @include mq.mq($until: m) {
    flex-direction: column;
    row-gap: var(--_page-bar-padding-block);
  }
}

.page__tools {
  column-gap: var(--_page-tools-column-gap);
  display: flex;

  @include mq.mq($from: m) {
    justify-content: flex-end;
    margin-inline-start: auto;
  }
}

.page__tool {
  display: block;
}

.page__readspeaker {
  @include use-body-padding();

  padding-block: var(--_page-readspeaker-padding-block);
}

.page__content {
  @include use-body-padding();

  padding-block: var(--_page-blocks-padding-block);
}

.page__blocks {
  @include use-column-width(width, (xs 12, m 8));

  clear: both;
  margin-inline: auto;
}

.page__clearfix {
  @include use-clearfix();

  margin-block: var(--_page-block-spacing-extended);

  &:first-child {
    margin-block-start: 0;
  }

  &:last-child > *:last-child {
    margin-block-end: 0;
  }
}

.page__block {
  --links-headline-margin-block: var(--_page-block-spacing);

  font-size: var(--_page-paragraph-font-size);
  margin-block: var(--_page-block-spacing);

  &:first-child,
  &:first-child > *:first-child {
    margin-block-start: 0;
  }

  &:last-child,
  &:last-child > *:last-child {
    margin-block-end: 0;
  }

  > * + * {
    margin-block-start: var(--_page-block-spacing-paragraph);
  }

  > .accordion + .accordion {
    margin-block-start: 0;
  }

  > .headline {
    margin-block-end: var(--_page-block-spacing);
    margin-block-start: calc(var(--_page-block-spacing) * 2);
  }

  > .horizontal-rule {
    @include use-column-offset(--horizontal-rule-margin-inline, (xs 0, m -1 of 8));

    --horizontal-rule-width: auto;

    margin-block: var(--_page-block-spacing-full);
  }

  > .table {
    --table-cell-padding-inline: 2.2rem;

    margin-block: calc(var(--_page-block-spacing) * 1.5);
    margin-inline: calc(var(--table-cell-padding-inline) * -1);
    width: calc(100% + var(--table-cell-padding-inline) * 2);
  }
}

.page__block--extended {
  margin-block: var(--_page-block-spacing-extended);
}

.page__block--left,
.page__block--right,
.page__block--left-narrow,
.page__block--right-narrow {
  margin-block: var(--_page-block-spacing-extended);

  + .page__block {
    margin-block-start: 0;
  }

  @include mq.mq($from: m) {
    .page__clearfix + & {
      margin-block-start: calc(var(--line-height-body) * 0.3em);
    }
  }
}

.page__block--left,
.page__block--right {
  @include use-column-width(width, (xs 12, m 5 of 8));
  @include use-column-offset(--_page-block-left-right-offset, (xs 0, m -2 of 8));
}

.page__block--left-narrow,
.page__block--right-narrow {
  @include use-column-width(width, (xs 12, m 3 of 8));
  @include use-column-offset(--_page-block-left-right-offset, (xs 0, m -1 of 8));
}

.page__block--left,
.page__block--left-narrow {
  @include mq.mq($from: m) {
    float: left;
    margin-inline-end: var(--gap);
    margin-inline-start: var(--_page-block-left-right-offset);
  }
}

.page__block--right,
.page__block--right-narrow {
  @include mq.mq($from: m) {
    float: right;
    margin-inline-end: var(--_page-block-left-right-offset);
    margin-inline-start: var(--gap);
  }
}

.page__block--popout {
  @include use-column-offset(margin-inline, (xs 0, m -1 of 8));

  margin-block: var(--_page-block-spacing-extended);

  @include mq.mq($from: l) {
    --box-inner-offset: #{column-offset(1, 10)};
    --box-image-offset: calc(#{column-offset(1, 9)} * -1);
    --box-image-max-width: #{column-width(3, 9)};
  }
}

.page__block--feature {
  @include use-column-offset(margin-inline, (xs 0, m -2 of 8));

  margin-block: var(--_page-block-spacing-extended);
}

.page__block--full {
  @include use-break-out();

  --section-padding-block: var(--_page-section-padding-block);

  clear: both;
  margin-block: var(--_page-block-spacing-full);

  & + &,
  & + .page__block--hidden + & {
    margin-block-start: calc(var(--_page-block-spacing-full) * -1);
  }

  & + &,
  & + .page__block--hidden + & {
    margin-block-start: calc(var(--_page-block-spacing-full) * -1 + var(--page-block-full-gap, 0.5rem));
  }

  &:first-child {
    margin-block-start: calc(var(--_page-blocks-padding-block) * -1);
  }

  &:last-child {
    margin-block-end: calc(var(--_page-blocks-padding-block) * -1);
  }

  > .media {
    @include use-container($padding: 0);
  }
}

.page__block--hidden {
  .page__block--full + & {
    margin-block-start: calc(var(--_page-block-spacing-full) * -1);
  }
}

.page__footer {
  @include use-column-width(width, (xs 12, m 8));

  display: grid;
  gap: calc(var(--_page-section-padding-block) * 1.5);
  margin-block-start: calc(var(--_page-section-padding-block) * 1.5);
  margin-inline: auto;
}

.page__tags {
  --tag-font-size: var(--font-size-default);
}

.page__browser {
  @include use-column-offset(margin-inline, (xs 0, m -2 of 8));
}
