@use 'mq';

.footer {
  @include use-responsive-config(--footer-breadcrumb-padding-block, responsive-map(xs 3rem, xl 4rem));
  @include use-responsive-config(--footer-meta-links-padding-block, responsive-map(xs 1rem, xl 1.5rem));
  @include use-responsive-config(--footer-content-padding-block, responsive-map(xs 3rem, xl 5rem));
  @include use-responsive-config(--footer-content-row-gap, responsive-map(xs 3rem, xl 5rem));
  @include use-responsive-config(--footer-logo-img-max-height, responsive-map(xs 5.6rem, xl 9rem));
  @include use-responsive-config(--footer-logo-font-size, responsive-map(xs 2rem, xl 2.6rem));
  @include use-responsive-config(--footer-social-media-icon-button-font-size, responsive-map(xs 2.4rem, xl 3rem));

  --_footer-content-background-color: var(--footer-content-background-color, var(--secondary-background-color));

  // Breadcrumb
  --_footer-breadcrumb-border-color: var(--footer-breadcrumb-border-color, var(--secondary-background-color));
  --_footer-breadcrumb-border-style: var(--footer-breadcrumb-border-style, solid);
  --_footer-breadcrumb-border-width: var(--footer-breadcrumb-border-width, 0.5rem);

  // Cards
  --_footer-cards-offset: var(--footer-cards-offset, 8rem);
  --_footer-cards-gap: var(--footer-cards-gap, 1rem);
  --_footer-cards-background-color: var(--footer-cards-background-color, var(--color-white));

  // Meta
  --_footer-meta-links-background-color: var(--footer-meta-links-background-color, var(--primary-interactive-color));
  --_footer-meta-links-color: var(--footer-meta-links-color, var(--primary-interactive-background-color));
  --_footer-meta-links-font-size: var(--footer-meta-links-font-size, var(--font-size-meta));

  // Logo
  --_footer-logo-color: var(--footer-logo-color, var(--text-color));
  --_footer-logo-font-family: var(--footer-logo-font-family, inherit);
  --_footer-logo-font-weight: var(--footer-logo-font-weight, var(--font-weight-bold));
  --_footer-logo-letter-spacing: var(--footer-logo-letter-spacing, 0);
  --_footer-logo-text-transform: var(--footer-logo-text-transform, none);

  // Logo subtitle
  --_footer-logo-title-color: var(--footer-logo-title-color, inherit);
  --_footer-logo-title-font-family: var(--footer-logo-title-font-family, inherit);
  --_footer-logo-title-font-weight: var(--footer-logo-title-font-weight, inherit);
  --_footer-logo-title-letter-spacing: var(--footer-logo-title-letter-spacing, inherit);
  --_footer-logo-title-text-transform: var(--footer-logo-title-text-transform, inherit);

  // Logo title
  --_footer-logo-subtitle-color: var(--footer-logo-subtitle-color, inherit);
  --_footer-logo-subtitle-font-family: var(--footer-logo-subtitle-font-family, inherit);
  --_footer-logo-subtitle-font-size: var(--footer-logo-subtitle-font-size, 0.8em);
  --_footer-logo-subtitle-font-weight: var(--footer-logo-subtitle-font-weight, inherit);
  --_footer-logo-subtitle-letter-spacing: var(--footer-logo-subtitle-letter-spacing, inherit);
  --_footer-logo-subtitle-text-transform: var(--footer-logo-subtitle-text-transform, inherit);

  // Social media
  --_footer-social-media-color: var(--footer-social-media-color, var(--text-color));
  --_footer-social-media-font-size: var(--footer-social-media-font-size, 2rem);
  --_footer-social-media-font-weight: var(--footer-social-media-font-weight, var(--font-weight-bold));
  --_footer-social-media-text-transform: var(--footer-social-media-text-transform, none);

  border-block-start-color: var(--_footer-breadcrumb-border-color);
  border-block-start-style: var(--_footer-breadcrumb-border-style);
  border-block-start-width: var(--_footer-breadcrumb-border-width);
}

.footer__breadcrumb {
  @include use-container();

  padding-block: var(--_footer-breadcrumb-padding-block);
}

.footer__content {
  align-items: flex-end;
  background-color: var(--_footer-content-background-color);
  column-gap: var(--gap);
  display: flex;
  flex-wrap: wrap;
  padding-block: var(--_footer-content-padding-block);
  padding-inline: var(--body-padding);
  row-gap: var(--_footer-content-row-gap);

  @include mq.mq($from: m) {
    justify-content: space-between;
  }
}

.footer__content--with-cards {
  margin-block-start: var(--_footer-cards-offset);
}

.footer__logo {
  align-items: flex-start;
  color: var(--_footer-logo-color);
  display: flex;
  flex-direction: column;
  font-family: var(--_footer-logo-font-family);
  font-size: var(--_footer-logo-font-size);
  font-weight: var(--_footer-logo-font-weight);
  letter-spacing: var(--_footer-logo-letter-spacing);
  line-height: var(--line-height-default);
  text-transform: var(--_footer-logo-text-transform);
  width: 100%;

  @include mq.mq($from: m) {
    width: column-width(4);
  }

  @include mq.mq($from: l) {
    width: column-width(5);
  }
}

.footer__logo-img {
  flex-shrink: 0;
  margin-block-end: 1em;
  max-height: var(--_footer-logo-img-max-height);
  max-width: 100%;
  width: auto;
}

.footer__logo-img--long {
  max-height: calc(var(--_footer-logo-img-max-height) * 0.75);
}

.footer__logo-title {
  color: var(--_footer-logo-title-color);
  font-family: var(--_footer-logo-title-font-family);
  font-size: var(--_footer-logo-title-font-size);
  font-weight: var(--_footer-logo-title-font-weight);
  letter-spacing: var(--_footer-logo-title-letter-spacing);
  text-transform: var(--_footer-logo-title-text-transform);
}

.footer__logo-subtitle {
  color: var(--_footer-logo-subtitle-color);
  font-family: var(--_footer-logo-subtitle-font-family);
  font-size: var(--_footer-logo-subtitle-font-size);
  font-weight: var(--_footer-logo-subtitle-font-weight);
  letter-spacing: var(--_footer-subtitle-letter-spacing);
  text-transform: var(--_footer-logo-subtitle-text-transform);
}

.footer__cards {
  display: flex;
  justify-content: flex-end;
  margin-block-start: calc(var(--_footer-content-padding-block) * -1 - var(--_footer-cards-offset));
  width: 100%;

  @include mq.mq($from: m) {
    order: 1;
    width: column-width(8);
  }

  @include mq.mq($from: l) {
    width: column-width(7);
  }

  @include mq.mq($from: xl) {
    width: column-width(6);
  }
}

.footer__card {
  background-color: var(--_footer-cards-background-color);
  flex-grow: 1;
  max-width: calc(50% + var(--_footer-cards-gap) / 2);
  padding: var(--_footer-cards-gap);

  &:first-child:not(:last-child) {
    max-width: 50%;
    padding-inline-end: calc(var(--_footer-cards-gap) / 2);
  }

  &:last-child:not(:first-child) {
    max-width: 50%;
    padding-inline-start: calc(var(--_footer-cards-gap) / 2);
  }
}

.footer__links {
  align-items: flex-end;
  column-gap: var(--gap);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  order: 2;
  row-gap: var(--_footer-content-row-gap);
  width: 100%;
}

.footer__social-media {
  width: 100%;

  @include mq.mq($from: m) {
    width: column-width(6);
  }
}

.footer__social-media-title {
  color: var(--_footer-social-media-color);
  font-size: var(--_footer-social-media-font-size);
  font-weight: var(--_footer-social-media-font-weight);
  margin-block-end: 1.5rem;
  text-transform: var(--_footer-social-media-text-transform);
}

.footer__social-media-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.footer__social-media-link {
  --icon-button-font-size: var(--_footer-social-media-icon-button-font-size);

  display: block;
}

.footer__action {
  width: 100%;

  @include mq.mq($from: m) {
    margin-inline-start: auto;
    width: column-width(6);
  }
}

.footer__action-links {
  column-gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;

  @include mq.mq($from: m) {
    justify-content: flex-end;
  }
}

.footer__action-link {
  display: block;
}

.footer__meta-links {
  --link-color: var(--_footer-meta-links-color);
  --link-color--enter: var(--_footer-meta-links-color);

  background-color: var(--_footer-meta-links-background-color);
  color: var(--_footer-meta-links-color);
  font-size: var(--_footer-meta-links-font-size);
  line-height: var(--line-height-body);
  padding-block: var(--_footer-meta-links-padding-block);
  padding-inline: var(--body-padding);
  text-align: center;
}

.footer__meta-links {
  align-items: center;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 0.5em;
}

.footer__meta-link {
  display: block;
}
