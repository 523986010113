.gallery {
  @include use-responsive-config(--gallery-padding, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-config(--gallery-navigation-icon-button-font-size, responsive-map(xs 2rem, xl 3rem));

  --_gallery-background-color: var(--gallery-background-color, rgba(0, 0, 0, 0.8));
  --_gallery-box-background-color: var(--gallery-box-background-color, var(--secondary-background-color));
  --_gallery-box-font-size: var(--gallery-box-font-size, var(--font-size-paragraph));
  --_gallery-box-padding-block: var(--gallery-box-padding-block, clamp(1.5rem, 4cqi, 4rem));
  --_gallery-box-padding-inline: var(--gallery-box-padding-inline, clamp(1.5rem, 4cqi, 4rem));
  --_gallery-close-icon-button-font-size: var(--gallery-close-icon-button-font-size, 1.6rem);
  --_gallery-color: var(--gallery-color, var(--color-white));
  --_gallery-preview-max-width: var(--gallery-preview-max-width, 60rem);
}

.gallery__box {
  background-color: var(--_gallery-box-background-color);
  container-name: gallery-box;
  container-type: inline-size;
}

.gallery__box-inner {
  column-gap: var(--gap);
  display: flex;
  flex-direction: column;
  padding-block: var(--_gallery-box-padding-block);
  padding-inline: var(--_gallery-box-padding-inline);
  row-gap: var(--_gallery-box-padding-block);

  @container gallery-box (min-width: 50rem) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
}

.gallery__preview {
  flex-shrink: 0;
  max-width: var(--_gallery-preview-max-width);
  width: 100%;

  @container gallery-box (min-width: 50rem) {
    width: 60%;
  }
}

.gallery__content {
  font-size: var(--_gallery-box-font-size);
  line-height: var(--line-height-body);

  @container gallery-box (min-width: 50rem) {
    align-self: flex-end;
    order: -1;
  }
}

.gallery__text {
  font-weight: var(--font-weight-bold);
}

.gallery__description {
  font-size: 0.8em;
}

.gallery__button {
  margin-block-start: var(--_gallery-box-padding-block);
}

.gallery__overlay {
  --_gallery-close-icon-button-height: calc(var(--_gallery-close-icon-button-font-size) * 1.44);
  --_gallery-navigation-icon-button-height: calc(var(--_gallery-navigation-icon-button-font-size) * 1.44);

  animation-duration: var(--duration-fast);
  animation-name: opacity;
  backdrop-filter: blur(10px);
  background-color: var(--_gallery-background-color);
  color: var(--_gallery-color);
  display: flex;
  flex-direction: column;
  inset: 0;
  overflow: hidden;
  padding-block-end: calc(var(--_gallery-padding) + var(--_gallery-navigation-icon-button-height) + 2rem);
  padding-block-start: calc(var(--_gallery-padding) + var(--_gallery-close-icon-button-height) + 2rem);
  padding-inline: var(--_gallery-padding);
  position: fixed;
  z-index: z-index('overlay');

  &:is([aria-hidden='true']) {
    display: none;
  }
}

.gallery__overlay-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.gallery__images {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 1;
}

.gallery__image {
  --image-z-index: 1;

  animation-duration: var(--duration-x-long);
  animation-name: opacity;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-block: auto;
  position: relative;
  width: 100%;

  &:is([data-move-direction='left']) {
    animation-name: opacity, fade-in-from-left;
  }

  &:is([data-move-direction='right']) {
    animation-name: opacity, fade-in-from-right;
  }
}

.gallery__copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  line-height: 0;
  position: absolute;
  z-index: 2;
}

.gallery__caption {
  --link-color: var(--_gallery-color);
  --link-color--enter: var(--_gallery-color);

  backdrop-filter: blur(10px);
  background-color: rgba(#000, 0.6);
  border-radius: 0.5rem;
  font-size: 1.2rem;
  inset-block-end: 1rem;
  inset-inline-start: 1rem;
  line-height: var(--line-height-default);
  max-width: calc(100% - 3rem - var(--copyright-size));
  padding: 0.5rem 1rem;
  position: absolute;
  z-index: 2;
}

.gallery__image--hidden {
  display: none;
}

.gallery__controls {
  inset: 0;
  pointer-events: none;
  position: absolute;
  user-select: none;
  z-index: 2;
}

.gallery__close {
  --icon-button-color--enter: var(--_gallery-color);
  --icon-button-color: var(--_gallery-color);
  --icon-button-icon-background-color--enter: var(--_gallery-color);
  --icon-button-icon-background-color: var(--_gallery-color);
  --icon-button-underline-color--enter: var(--_gallery-color);
  --icon-button-font-size: var(--_gallery-close-icon-button-font-size);

  inset-block-start: var(--_gallery-padding);
  inset-inline-end: var(--_gallery-padding);
  line-height: 0;
  pointer-events: all;
  position: absolute;
}

.gallery__position {
  font-size: var(--gallery-position-font-size, var(--font-size-meta));
  font-weight: var(--font-weight-bold);
  inset-block-start: var(--_gallery-padding);
  inset-inline-start: var(--_gallery-padding);
  line-height: var(--_gallery-close-icon-button-height);
  position: absolute;
}

.gallery__navigation {
  --icon-button-font-size: var(--_gallery-navigation-icon-button-font-size);

  display: flex;
  gap: 1rem;
  inset-block-end: var(--_gallery-padding);
  inset-inline-start: 50%;
  pointer-events: all;
  position: absolute;
  transform: translateX(-50%);
}
