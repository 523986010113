.social-media-post {
  @include use-responsive-config(--social-media-post-padding, responsive-map(xs 2rem, xl 3rem));

  --_social-media-post-background-color: var(--social-media-post-background-color, var(--secondary-background-color));
  --_social-media-post-color: var(--social-media-post-color, var(--text-color));
  --_social-media-post-font-size: var(--social-media-post-font-size, var(--font-size-default));
  --_social-media-post-icon-background-color: var(--social-media-post-icon-background-color, var(--text-color));
  --_social-media-post-icon-border-radius: var(--social-media-post-icon-border-radius, 50%);
  --_social-media-post-icon-color: var(--social-media-post-icon-color, var(--color-white));
  --_social-media-post-link-background-color: var(--social-media-post-link-background-color, var(--color-white));
  --_social-media-post-link-color: var(--social-media-post-link-color, var(--text-color));
  --_social-media-post-meta-color: var(--social-media-post-meta-color, var(--text-color));
  --_social-media-post-repost-background-color: var(--social-media-post-repost-background-color, var(--color-white));
  --_social-media-post-repost-color: var(--social-media-post-repost-color, var(--text-color));

  background-color: var(--_social-media-post-background-color);
  color: var(--_social-media-post-color);
  font-size: var(--_social-media-post-font-size);
  line-height: var(--line-height-body);
  overflow: hidden;
  padding: var(--_social-media-post-padding);
  position: relative;
}

.social-media-post__meta {
  align-items: flex-start;
  color: var(--_social-media-post-meta-color);
  display: flex;
  font-size: 0.9em;
  gap: 0.5em;
  margin-block-end: 0.5em;
  min-width: 0;
  width: 100%;
}

.social-media-post__sender {
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.social-media-post__date {
  flex-shrink: 0;

  &:not(:first-child)::before {
    content: '•';
    margin-inline-end: 0.5em;
  }
}

.social-media-post__service {
  align-items: center;
  background-color: var(--_social-media-post-icon-background-color);
  border-radius: var(--_social-media-post-icon-border-radius);
  color: var(--_social-media-post-icon-color);
  display: flex;
  flex-shrink: 0;
  font-size: 1.1em;
  height: 1.7em;
  justify-content: center;
  line-height: 0;
  margin-inline-start: auto;
  width: 1.7em;
}

.social-media-post__repost {
  align-items: center;
  background-color: var(--_social-media-post-repost-background-color);
  color: var(--_social-media-post-repost-color);
  display: inline-flex;
  font-size: 0.8em;
  font-weight: var(--font-weight-bold);
  gap: 0.4em;
  padding-block: 0.2em;
  padding-inline: 0.6em;
}

.social-media-post__link {
  align-items: center;
  background-color: var(--_social-media-post-link-background-color);
  color: var(--_social-media-post-link-color);
  display: flex;
  font-size: 0.8em;
  gap: 1em;
  padding: 0.8em;
}

.social-media-post__link-image {
  flex-shrink: 0;
  width: 25%;
}

.social-media-post__link-text-description {
  --line-clamp: 4;
}

.social-media-post__link-text {
  font-weight: var(--font-weight-bold);
}

.social-media-post__image {
  --image-z-index: 1;

  position: relative;
}

.social-media-post__image-icon {
  background-color: var(--social-media-post-background-color, var(--secondary-background-color));
  color: var(--social-media-post-color, var(--text-color));
  font-size: 2rem;
  inset-block-end: 2rem;
  inset-inline-start: 2rem;
  line-height: 0;
  padding: 2rem;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.social-media-post__image-description {
  color: var(--social-media-post-image-description-color, var(--meta-color));
  font-size: 0.8em;
  margin-block-start: 1.5rem;
}

:is(.social-media-post__image):not(:first-child) {
  margin-block-start: 1em;
}

:is(.social-media-post__text, .social-media-post__image, .social-media-post__link, .social-media-post__repost):not(:last-child) {
  margin-block-end: 1em;
}
