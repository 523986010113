@use 'mq';

.election-slide__bar {
  --_election-slide-bar-background-color: var(--election-slide-bar-background-color, var(--primary-color));
  --_election-slide-bar-color: var(--election-slide-bar, var(--color-white));

  --selection-background-color: var(--_election-slide-bar-color);
  --selection-foreground-color: var(--_election-slide-bar-background-color);

  align-items: center;
  background-color: var(--_election-slide-bar-background-color);
  color: var(--_election-slide-bar-color);
  column-gap: var(--election-slide-bar-column-gap, var(--gap));
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-block: var(--election-slide-bar-padding-block, 1.6rem);
  padding-inline: var(--election-slide-bar-padding-inline, var(--inner-container-padding));
  row-gap: var(--election-slide-bar-row-gap, 1rem);
}

.election-slide__bar-headline {
  --headline-color: currentColor;
}

.election-slide__bar-link {
  --icon-button-color--enter: currentColor;
  --icon-button-color: currentColor;
  --icon-button-icon-background-color--enter: transparent;
  --icon-button-icon-background-color: transparent;
  --icon-button-icon-color--enter: currentColor;
  --icon-button-icon-color: currentColor;
  --icon-button-icon-padding: 0;
  --icon-button-icon-size: 0.8em;
  --icon-button-underline-color--enter: currentColor;
  --icon-button-font-size: 0.9em;

  font-size: var(--election-slide-bar-font-size, 2rem);
}

.election-slide__inner {
  @include use-responsive-config(--election-slide-padding-block, responsive-map(xs 4rem, xl 6rem));

  align-items: flex-start;
  background-color: var(--election-slide-background-color, var(--secondary-background-color));
  column-gap: var(--gap);
  display: flex;
  flex-wrap: wrap;
  font-size: var(--election-slide-font-size, var(--font-size-teaser));
  padding-block: var(--_election-slide-padding-block);
  padding-inline: var(--election-slide-padding-inline, var(--inner-container-padding));
  row-gap: 3rem;
}

.election-slide__content {
  @include use-column-width(width, (xs 12, l 4));

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  row-gap: 2rem;
}

.election-slide__headline {
  --headline-text-transform: uppercase;
}

.election-slide__description {
  font-size: 1em;
  line-height: var(--line-height-body);
}

.election-slide__results {
  flex-grow: 1;
  order: -1;

  @include mq.mq($from: l) {
    order: 1;
    width: column-width(8);
  }
}

.election-slide__select {
  margin-block-end: 2rem;
}

.election-slide__embed {
  border: 1px solid var(--line-color);
  line-height: 0;

  :is(iframe):focus {
    outline: 0;
  }
}
