.text-breaker {
  --_text-breaker-content-spacing: var(--text-breaker-content-spacing, 1em);

  container-name: text-breaker;
  container-type: inline-size;
}

.text-breaker__inner {
  --link-color: var(--text-breaker-link-color, var(--primary-interactive-color));
  --link-color--enter: var(--text-breaker-link-color--enter, var(--primary-interactive-color--enter));

  column-gap: var(--gap);
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  @container text-breaker (min-width: 60rem) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
}

.text-breaker__text {
  color: var(--text-breaker-color, inherit);

  > * + * {
    margin-block-start: var(--_text-breaker-content-spacing);
  }

  > .headline {
    margin-block-start: calc(var(--_text-breaker-content-spacing) * 1.5);
  }
}
