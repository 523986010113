.quote {
  container-name: quote;
  container-type: inline-size;
  padding-block: var(--quote-padding-block, 0);
  padding-inline: var(--quote-padding-inline, 0);
  text-align: left;
}

.quote--centered {
  text-align: center;
}

.quote__inner {
  display: flex;
  flex-direction: column;
  font-size: var(--quote-font-size, var(--font-size-headline-6));
  gap: var(--quote-image-gap, 3rem);
  height: 100%;
  justify-content: center;
  margin-inline: auto;
  max-width: var(--quote-max-width, 55ch);

  @container quote (min-width: 60rem) {
    font-size: var(--quote-font-size, var(--font-size-headline-4));

    .quote:not(.quote--stacked) & {
      align-items: center;
      flex-direction: row;

      &:has(.quote__image) {
        max-width: calc(clamp(10rem, 25cqi, 14rem) + var(--quote-image-gap, 3rem) + var(--quote-max-width, 55ch));
      }
    }
  }
}

.quote__image {
  --image-border-radius: 50%;

  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  width: clamp(10rem, 25cqi, 14rem);

  .quote--centered & {
    align-self: center;
  }
}

.quote__quote {
  color: var(--quote-color, var(--primary-color));
  font-size: 1em;
  font-weight: var(--quote-font-weight, var(--font-weight-bold));
  line-height: var(--quote-line-height, var(--line-height-default));

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

.quote__caption {
  color: var(--quote-caption-color, var(--text-color));
  font-size: var(--quote-caption-font-size, var(--font-size-teaser));
  line-height: var(--quote-caption-line-height, var(--line-height-default));
  margin-block-start: var(--quote-quote-margin-block, 1.5rem);

  &::before {
    background-color: currentColor;
    content: '';
    display: inline-block;
    height: 1px;
    margin-inline-end: 1rem;
    vertical-align: middle;
    width: 1rem;
  }
}

.quote__copyright {
  color: var(--quote-copyright-color, var(--caption-color));
  font-size: var(--quote-copyright-font-size, 1.2rem);
  line-height: var(--quote-line-height, var(--line-height-default));
  margin-block-start: var(--quote-copyright-margin-block, 2rem);
}
