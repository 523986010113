.details-box {
  @include use-responsive-config(
    --details-box-padding-block --details-box-padding-inline,
    responsive-map(xs 2rem, xl 3rem)
  );

  --_details-box-background-color: var(--details-box-background-color, var(--secondary-background-color));
  --_details-box-text-font-size: var(--details-box-text-font-size, var(--font-size-default));
  --_details-box-color: var(--details-box-color, var(--text-color));
  --_details-box-icon-color: var(--details-box-icon-color, var(--text-color));
  --_details-box-icon-size: var(--details-box-icon-size, 1em);

  background-color: var(--_details-box-background-color);
  color: var(--_details-box-color);
  display: grid;
  font-size: var(--_details-box-text-font-size);
  line-height: var(--line-height-body);
  padding-block: var(--_details-box-padding-block);
  padding-inline: var(--_details-box-padding-inline);
  row-gap: 1.4em;
}

.details-box__details {
  display: grid;
  row-gap: 0.6em;
}

.details-box__detail {
  display: flex;
  gap: 0.8em;
}

.details-box__detail-icon {
  color: var(--teaser-details-icon-color);
  flex-shrink: 0;
  font-size: var(--_details-box-icon-size);
  line-height: calc(var(--_details-box-icon-size) * 1.3);
}

.details-box__detail-text {
  --bullet-list-indent: 1em;
  --bullet-list-spacing: 0.15em;

  > * + * {
    margin-block-start: 0.15em;
  }
}
