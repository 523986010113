@use 'sass:color';

.t-spd-nds-2022 {
  // Text sizes
  @include use-responsive-sizing(--font-size-headline-1, responsive-map(xs 3.2rem, xl 5rem));
  @include use-responsive-sizing(--font-size-headline-2, responsive-map(xs 2.4rem, xl 4.2rem));
  @include use-responsive-sizing(--font-size-headline-3, responsive-map(xs 2.2rem, xl 3.2rem));
  @include use-responsive-sizing(--font-size-headline-4, responsive-map(xs 2rem, xl 2.8rem));
  @include use-responsive-sizing(--font-size-headline-5, responsive-map(xs 1.8rem, xl 2.4rem));
  @include use-responsive-sizing(--font-size-headline-6, responsive-map(xs 1.6rem, xl 2.2rem));

  // Colors
  --primary-color: var(--color-nds-22-blue-900);
  --secondary-color: var(--color-spd-red);

  // Color configuration
  --caption-color: var(--color-nds-22-blue-900);
  --control-background-color--enter: var(--color-white);
  --control-background-color: var(--color-white);
  --control-border-color--enter: var(--primary-color);
  --control-border-color: var(--primary-color);
  --control-color--enter: var(--color-black);
  --control-color: var(--color-black);
  --control-switch-background-color--checked: var(--color-nds-22-blue-900);
  --control-switch-background-color: var(--color-nds-22-blue-200);
  --control-switch-foreground-color--checked: var(--color-white);
  --control-switch-foreground-color: var(--color-white);
  --line-color: var(--color-nds-22-blue-200);
  --meta-text-color: var(--primary-color);
  --preview-background-color: var(--color-white);
  --primary-background-color: var(--color-primary);
  --primary-heading-color: var(--primary-color);
  --primary-heading-color: var(--secondary-color);
  --primary-interactive-background-color--disabled: var(--color-white);
  --primary-interactive-background-color--enter: var(--color-white);
  --primary-interactive-background-color: var(--color-white);
  --primary-interactive-color--disabled: var(--color-gray-200);
  --primary-interactive-color--enter: var(--secondary-color);
  --primary-interactive-color: var(--primary-color);
  --secondary-background-color: var(--color-nds-22-blue-050);
  --secondary-interactive-background-color--disabled: var(--color-white);
  --secondary-interactive-background-color--enter: var(--color-white);
  --secondary-interactive-background-color: var(--color-white);
  --secondary-interactive-color--disabled: var(--color-nds-22-blue-200);
  --secondary-interactive-color--enter: var(--secondary-color);
  --secondary-interactive-color: var(--primary-color);
  --text-color: var(--color-black);
  --meta-color: var(--color-gray-500);
  --tooltip-background-color: var(--color-black);
  --tooltip-color: var(--color-white);

  // Font
  --root-font-family: var(--font-family-archivo-narrow);
  --root-color: var(--text-color);
  --root-font-width: var(--font-width-normal);
  --root-font-weight: var(--font-weight-regular);

  // Body
  --body-background-color: var(--color-white);
  --body-font-size: var(--font-size-default);

  // Focus outlines
  --focus-outline-width: 3px;
  --focus-outline-color: var(--primary-color);
  --focus-outline-offset: 3px;

  // Selection
  --selection-background-color: var(--color-black);
  --selection-foreground-color: var(--color-white);

  // @alert
  --alert-icon-background-color: var(--secondary-color);

  // @bullet-list
  --bullet-list-unordered-marker: square;
  --bullet-list-marker-color: var(--secondary-color);

  // @date-sqare
  --date-square-background-color: var(--secondary-color);

  // @headline
  --headline-font-family: var(--font-family-karla);
  --headline-font-weight: var(--font-weight-extrabold);
  --headline-kicker-font-family: var(--font-family-archivo-narrow);
  --headline-kicker-letter-spacing: 0;
  --headline-letter-spacing: -0.06em;
  --headline-text-transform: uppercase;
  --headline-color--enter: var(--primary-color);
  --headline-underline-color--enter: transparent;

  // @label
  --label-color: var(--primary-color);

  // @link
  --link-color: var(--secondary-color);
  --link-color--enter: var(--secondary-color);

  // @notice
  --notice-color: var(--primary-color);
  --notice-icon-background-color: var(--primary-color);

  // @quote
  --quote-color: var(--secondary-color);
  --quote-caption-color: var(--primary-color);

  // @table
  --table-cell-background-color-odd: var(--color-nds-22-blue-050);
  --table-hcell-color: var(--primary-color);

  // @breadcrumb
  --breadcrumb-text-color: var(--primary-color);
  --breadcrumb-arrow-color: var(--primary-color);
  --breadcrumb-icon-color: var(--primary-color);

  // @card
  --card-background-color: var(--primary-color);
  --card-background-color--enter: var(--secondary-color);

  // @grid-teaser
  --grid-teaser-link-color: var(--secondary-color);
  --grid-teaser-link-color--enter: var(--secondary-color);

  // @social-media-post
  --social-media-post-icon-background-color: var(--primary-color);
  --social-media-post-meta-color: var(--primary-color);

  // @tab-bar
  --tab-bar-label-color: var(--primary-color);
  --tab-bar-option-color: var(--secondary-color);
  --tab-bar-option-color--selected: var(--secondary-color);
  --tab-bar-text-transform: uppercase;
  --tab-bar-font-family: var(--font-family-karla);
  --tab-bar-font-weight: var(--font-weight-extrabold);
  --tab-bar-letter-spacing: -0.06em;

  // @teaser
  --teaser-meta-color: var(--primary-color);

  // @text-breaker
  --text-breaker-link-color: var(--secondary-color);
  --text-breaker-link-color--enter: var(--secondary-color);

  // @banderole
  --banderole-background-color: var(--color-spd-red);
  --banderole-color: var(--color-white);

  // @footer
  --footer-logo-subtitle-color: var(--primary-color);
  --footer-logo-title-color: var(--secondary-color);
  --footer-logo-title-font-family: var(--font-family-karla);
  --footer-logo-title-font-weight: var(--font-weight-extrabold);
  --footer-logo-title-letter-spacing: -0.06em;
  --footer-logo-title-text-transform: uppercase;
  --footer-social-media-color: var(--primary-color);

  // @header
  --header-subtitle-color: var(--primary-color);
  --header-title-color: var(--primary-color);
  --header-title-font-family: var(--font-family-karla);
  --header-title-font-weight: var(--font-weight-extrabold);
  --header-title-letter-spacing: -0.06em;
  --header-title-text-transform: uppercase;

  // @slider
  --slider-scroll-bar-background-color: var(--color-nds-22-blue-100);
  --slider-scroll-bar-foreground-color: var(--primary-color);

  // @stage
  --stage-tile-background-color: var(--secondary-color);
  --stage-tile-background-color--enter: var(--primary-color);
  --stage-tile-color: var(--color-white);

  // @section
  --section-background-color--light: var(--secondary-background-color);
  --section-background-color--dark: var(--secondary-color);

  // @portal
  --portal-font-factor: 0.8;

  // @section
  [data-theme='light'] {
    --section-background-color: var(--secondary-background-color);
    --teaser-background-color: var(--color-white);
    --grid-teaser-background-color: var(--color-white);
    --tab-bar-background-color: var(--secondary-background-color);
    --social-media-post-background-color: var(--color-white);
    --social-media-post-link-background-color: var(--secondary-background-color);
    --social-media-post-repost-background-color: var(--secondary-background-color);
    --slider-scroll-bar-background-color: var(--color-white);
    --slider-scroll-bar-foreground-color: var(--primary-color);
    --tile-background-color: var(--color-white);
    --form-breaker-background-color: var(--secondary-background-color);
    --form-breaker-alert-background-color: var(--color-white);
  }

  [data-theme='dark'] {
    --date-square-background-color: var(--color-nds-22-blue-100);
    --date-square-color: var(--primary-color);
    --section-button-background-color--enter: var(--primary-color);
    --section-button-background-color: var(--color-white);
    --section-button-color--enter: var(--color-white);
    --section-button-color: var(--secondary-color);
    --section-button-background-color--disabled: var(--color-nds-22-blue-100);
    --section-button-color--disabled: var(--color-nds-22-blue-400);
    --grid-teaser-background-color: var(--color-white);
    --icon-button-color--enter: var(--color-white);
    --icon-button-color: var(--color-white);
    --icon-button-icon-background-color--disabled: rgba(0, 0, 0, 0.1);
    --icon-button-icon-background-color--enter: var(--color-white);
    --icon-button-icon-background-color: var(--color-white);
    --icon-button-icon-color--enter: var(--color-black);
    --icon-button-icon-color--disabled: rgba(255, 255, 255, 0.4);
    --icon-button-icon-color: var(--primary-color);
    --icon-button-underline-color--enter: var(--color-white);
    --section-background-color: var(--secondary-color);
    --section-headline-color: var(--color-white);
    --section-text-color: var(--color-white);
    --slider-scroll-bar-background-color: rgba(255, 255, 255, 0.3);
    --slider-scroll-bar-foreground-color: var(--color-white);
    --social-media-post-background-color: var(--color-white);
    --teaser-background-color: var(--color-white);
    --text-breaker-color: var(--color-white);
    --text-breaker-link-color: var(--color-white);
    --text-breaker-link-color--enter: var(--color-white);
    --tab-bar-background-color: var(--secondary-color);
    --tab-bar-label-color: var(--color-white);
    --tab-bar-option-color: var(--color-white);
    --tab-bar-option-color--selected: var(--color-white);
    --tab-bar-option-underline-color--selected: var(--color-white);
    --quote-color: var(--color-white);
    --quote-caption-color: var(--color-white);
    --quote-copyright-color: #{color.mix($color-spd-red, $color-white, $weight: 4.9%)};
    --form-breaker-background-color: var(--primary-color);
    --form-breaker-headline-color: var(--color-white);
    --form-breaker-color: var(--color-white);
    --form-breaker-control-color: var(--color-white);
    --form-breaker-link-color: var(--color-white);
    --form-breaker-link-color--enter: var(--color-white);
    --form-breaker-label-color: var(--color-white);
    --form-breaker-alert-icon-border-color: var(--color-gray-050);
    --form-breaker-alert-icon-border-width: 2px;
    --button-background-color: var(--color-white);
    --button-color: var(--primary-color);
    --required-color: var(--color-white);
  }
}
