.headline {
  --_headline-color: var(--headline-color, var(--primary-heading-color));
  --_headline-font-family: var(--headline-font-family, inherit);
  --_headline-font-size: var(--headline-font-size, inherit);
  --_headline-font-weight: var(--headline-font-weight, var(--font-weight-bold));
  --_headline-letter-spacing: var(--headline-letter-spacing, 0);
  --_headline-line-height: var(--headline-line-height, var(--line-height-small));
  --_headline-text-transform: var(--headline-text-transform, inherit);
  --_headline-underline-color: var(--headline-underline-color, transparent);

  // Kicker
  --_headline-kicker-color: var(--headline-kicker-color, var(--meta-text-color));
  --_headline-kicker-font-family: var(--headline-kicker-font-family, inherit);
  --_headline-kicker-font-size: var(--headline-kicker-font-size, var(--font-size-headline-kicker));
  --_headline-kicker-font-weight: var(--headline-kicker-font-weight, var(--font-weight-bold));
  --_headline-kicker-letter-spacing: var(--headline-kicker-letter-spacing, inherit);
  --_headline-kicker-spacing: var(--headline-kicker-spacing, 0.5em);
  --_headline-kicker-text-transform: var(--headline-kicker-text-transform, none);

  // :hover, :focus
  --_headline-color--enter: var(--headline-color--enter, var(--primary-heading-color));
  --_headline-underline-color--enter: var(--headline-underline-color, transparent);

  color: var(--_headline-color);
  font-family: var(--_headline-font-family);
  font-size: var(--_headline-font-size);
  font-weight: var(--_headline-font-weight);
  hyphens: var(--headline-hyphens, manual);
  letter-spacing: var(--_headline-letter-spacing);
  line-height: var(--_headline-line-height);
  text-transform: var(--_headline-text-transform);
  transition-property: color;

  :is(button, :any-link):is(:focus-visible:not(.has-invisible-focus), :hover:not([disabled]), :focus-within:not(.has-invisible-focus)) & {
    color: var(--_headline-color--enter);
  }
}

.headline--1 {
  --_headline-font-size: var(--font-size-headline-1);
}

.headline--2 {
  --_headline-font-size: var(--font-size-headline-2);
}

.headline--3 {
  --_headline-font-size: var(--font-size-headline-3);
}

.headline--4 {
  --_headline-font-size: var(--font-size-headline-4);
}

.headline--5 {
  --_headline-font-size: var(--font-size-headline-5);
}

.headline--6 {
  --_headline-font-size: var(--font-size-headline-6);
}

.headline__icon {
  font-size: 0.8em;
  line-height: 0;
  margin-inline-end: 0.3em;
  vertical-align: 0.1em;
}

.headline__text {
  --underline-color: var(--headline-underline-color, transparent);
  --underline-color--enter: var(--headline-underline-color--enter, currentColor);
  --underline-offset: var(--headline-underline-offset, 0.2em);
  --underline-height: var(--headline-underline-height, 0.1em);

  background-color: var(--headline-text-background-color, transparent);
  box-decoration-break: clone;
  padding-block: var(--headline-text-padding-block, 0);
  padding-inline: var(--headline-text-padding-inline, 0);
}

.headline__kicker {
  background-color: var(--headline-kicker-background-color, transparent);
  box-decoration-break: clone;
  color: var(--_headline-kicker-color);
  display: block;
  font-family: var(--_headline-kicker-font-family);
  font-size: var(--_headline-kicker-font-size);
  font-weight: var(--_headline-kicker-font-weight);
  letter-spacing: var(--_headline-kicker-letter-spacing);
  padding-block: var(--headline-kicker-padding-block, 0);
  padding-inline: var(--headline-kicker-padding-inline, 0);
  text-transform: var(--_headline-kicker-text-transform);

  &:first-child {
    margin-block-end: var(--_headline-kicker-spacing);
  }

  &:last-child {
    margin-block-start: var(--_headline-kicker-spacing);
  }
}
