.toggle {
  --_toggle-active: 0;
  --_toggle-background-color: var(--toggle-background-color, var(--control-switch-background-color));
  --_toggle-font-size: var(--toggle-font-size, var(--font-size-meta));
  --_toggle-foreground-color: var(--toggle-foreground-color, var(--control-switch-foreground-color));
  --_toggle-inner-padding: var(--toggle-inner-padding, 0.5rem);
  --_toggle-size: var(--toggle-size, 2.8rem);
  --_toggle-text-color: var(--toggle-text-color, var(--control-color));
  --_toggle-width: var(--toggle-width, auto);

  // [active]
  --_toggle-background-color--checked: var(--toggle-background-color--checked, var(--control-switch-background-color--checked));
  --_toggle-foreground-color--checked: var(--toggle-foreground-color--checked, var(--control-switch-foreground-color--checked));

  align-items: center;
  color: var(--_toggle-text-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--_toggle-font-size);
  gap: 0.5em;
  text-align: start;
  user-select: none;
  width: var(--_toggle-width);
}

.toggle:is([aria-pressed='true']) {
  --_toggle-active: 1;
  --_toggle-background-color: var(--_toggle-background-color--checked);
  --_toggle-foreground-color: var(--_toggle-foreground-color--checked);
}

.toggle__indicator {
  --_toggle-_bullet-size: calc(var(--_toggle-size) - var(--_toggle-inner-padding) * 2);

  background-color: var(--_toggle-background-color);
  border-radius: calc(var(--_toggle-size) / 2);
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  height: var(--_toggle-size);
  margin-inline-end: 0.5em;
  position: relative;
  transition-property: background-color;
  width: calc(var(--_toggle-_bullet-size) * 2 + var(--_toggle-inner-padding) * 2);

  &::before {
    background-color: var(--_toggle-foreground-color);
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
    content: '';
    height: var(--_toggle-_bullet-size);
    inset-block-start: var(--_toggle-inner-padding);
    inset-inline-start: var(--_toggle-inner-padding);
    position: absolute;
    transition-property: background-color, translate;
    translate: calc(var(--_toggle-active) * 100%) 0;
    width: var(--_toggle-_bullet-size);
  }
}

.toggle__text {
  line-height: var(--line-height-default);
}
