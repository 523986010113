.embed {
  @include use-responsive-config(
    --embed-wall-padding-block --embed-wall-padding-inline,
    responsive-map(xs 2rem, xl 4rem)
  );

  --_embed-content-padding-block: var(--embed-content-padding-block, 0);
  --_embed-content-padding-inline: var(--embed-content-padding-inline, 0);
  --_embed-wall-background-color: var(--embed-wall-background-color, var(--secondary-background-color));
  --_embed-wall-button-margin-block: var(--embed-wall-button-margin-block, 3rem);
  --_embed-wall-font-size: var(--embed-wall-font-size, inherit);

  iframe {
    height: revert-layer;
  }
}

.embed__wall {
  background-color: var(--_embed-wall-background-color);
  font-size: var(--_embed-wall-font-size);
  line-height: var(--line-height-body);
  padding-block: var(--_embed-wall-padding-block);
  padding-inline: var(--_embed-wall-padding-inline);
  text-align: center;
}

.embed__message {
  margin-block-end: var(--_embed-wall-button-margin-block);
  margin-inline: auto;
  max-width: 60ch;
}

.embed__content {
  padding-block: var(--_embed-content-padding-block);
  padding-inline: var(--_embed-content-padding-inline);
}
