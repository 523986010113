.teaser-grid {
  @include use-responsive-config(--teaser-grid-row-gap, responsive-map(xs 2rem, xl 4rem));
  @include use-responsive-config(--teaser-grid-tab-bar-margin-block, responsive-map(xs 1.5rem, xl 2rem));
  @include use-responsive-config(--teaser-grid-text-margin-block, responsive-map(xs 1.5rem, xl 2rem));
  @include use-responsive-config(--teaser-grid-teasers-margin-block, responsive-map(xs 2rem, xl 4rem));
  @include use-responsive-config(--teaser-grid-button-margin-block, responsive-map(xs 2rem, xl 4rem));

  --_teaser-grid-column-gap: var(--teaser-grid-column-gap, var(--gap));
  --_teaser-grid-text-font-size: var(--teaser-grid-text-font-size, var(--font-size-paragraph));
  --_teaser-grid-text-max-width: var(--teaser-grid-text-max-width, 80rem);
  --_teaser-grid-text-color: var(--teaser-grid-text-color, var(--text-color));

  container-name: teaser-grid;
  container-type: inline-size;

  > *:first-child {
    margin-block-start: 0;
  }

  > *:last-child {
    margin-block-end: 0;
  }
}

.teaser-grid__text {
  color: var(--_teaser-grid-text-color);
  font-size: var(--_teaser-grid-text-font-size);
  line-height: var(--line-height-body);
  margin-block: var(--_teaser-grid-text-margin-block);
  max-width: var(--_teaser-grid-text-max-width);
}

.teaser-grid__tab-bar {
  margin-block: var(--_teaser-grid-tab-bar-margin-block);
}

.teaser-grid__teasers {
  display: grid;
  gap: var(--_teaser-grid-column-gap);
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  margin-block: var(--_teaser-grid-teasers-margin-block);
  row-gap: var(--_teaser-grid-row-gap);

  @container teaser-grid (min-width: 50rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  @container teaser-grid (min-width: 90rem) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.teaser-grid__teaser {
  display: grid;

  @container teaser-grid (min-width: 50rem) {
    min-block-size: calc(25cqi - var(--_teaser-grid-column-gap) / 2);
  }

  @container teaser-grid (min-width: 90rem) {
    grid-column: span 2;
    min-block-size: calc(15cqi - (var(--_teaser-grid-column-gap) / 1.5));

    .teaser-grid--1 &:is(:nth-child(1)) {
      grid-column: span 6;
      min-block-size: 0;
    }

    .teaser-grid--2 &:is(:nth-child(1), :nth-child(2)) {
      grid-column: span 3;
      min-block-size: calc(25cqi - (var(--_teaser-grid-column-gap) / 2));
    }
  }
}

.teaser-grid__button {
  margin-block: var(--_teaser-grid-button-margin-block);
}
