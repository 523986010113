@use 'sass:map';
@use 'mq';

.archive {
  @include use-responsive-config(--archive-sidebar-row-gap, responsive-map(xs 2.5rem, xl 4rem));
  @include use-responsive-config(--archive-sidebar-margin-block, responsive-map(xs 2.5rem, xl 4rem));
  @include use-responsive-config(--archive-bar-margin-block, responsive-map(xs 2.5rem, xl 4rem));
  @include use-responsive-config(--archive-teasers-gap, responsive-map(xs 2.5rem, xl 5rem));
  @include use-responsive-config(--archive-pagination-margin-block, responsive-map(xs 2.5rem, xl 6rem));
  @include use-responsive-config(--archive-facets-row-gap, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-config(--archive-filters-row-gap, responsive-map(xs 2rem, xl 3rem));

  --_archive-additional-content-background-color: var(--archive-additional-content-background-color, var(--secondary-background-color));
  --_archive-additional-content-padding: var(--archive-additional-content-padding, 2rem);
  --_archive-additional-toggle-background-color: var(--archive-additional-toggle-background-color, var(--color-white));
  --_archive-additional-toggle-color: var(--archive-additional-toggle-color, var(--text-color));
  --_archive-additional-toggle-font-size: var(--archive-additional-toggle-font-size, var(--font-size-meta));
  --_archive-additional-toggle-icon-background-color: var(--archive-additional-toggle-icon-background-color, var(--secondary-interactive-color));
  --_archive-additional-toggle-icon-border-color: var(--archive-additional-toggle-icon-border-color, transparent);
  --_archive-additional-toggle-icon-border-radius: var(--archive-additional-toggle-icon-border-radius, 50%);
  --_archive-additional-toggle-icon-border-style: var(--archive-additional-toggle-icon-border-style, solid);
  --_archive-additional-toggle-icon-border-width: var(--archive-additional-toggle-icon-border-width, 0);
  --_archive-additional-toggle-icon-color: var(--archive-additional-toggle-icon-color, var(--secondary-interactive-background-color));
  --_archive-count-color: var(--archive-count-color, var(--text-color));
  --_archive-count-font-size: var(--archive-count-font-size, var(--font-size-meta));
  --_archive-count-font-weight: var(--archive-count-font-weight, var(--font-weight-bold));
  --_archive-sidebar-breakpoint: (max-width: var(--archive-sidebar-breakpoint, #{map.get($breakpoints, m)}));
  --_archive-sidebar-line-color: var(--archive-sidebar-line-color, var(--line-color));
  --_archive-sidebar-line-style: var(--archive-sidebar-line-style, solid);
  --_archive-sidebar-line-width: var(--archive-sidebar-line-width, 1px);
  --_archive-views-row-gap: var(--archive-views-row-gap, 1.5rem);

  column-gap: var(--gap);
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--_archive-sidebar-margin-block);
}

.archive__sidebar {
  flex-shrink: 0;
  width: 100%;

  > *:not(:last-child) {
    border-block-end-color: var(--_archive-sidebar-line-color);
    border-block-end-style: var(--_archive-sidebar-line-style);
    border-block-end-width: var(--_archive-sidebar-line-width);
    margin-block-end: var(--_archive-sidebar-row-gap);
    padding-block-end: var(--_archive-sidebar-row-gap);
  }

  @include mq.mq($from: m) {
    width: column-width(5);
  }

  @include mq.mq($from: l) {
    width: column-width(4);
  }
}

.archive__views {
  display: grid;
  gap: var(--_archive-views-row-gap);
}

.archive__view {
  display: block;
}

.archive__filters {
  --tags-label-margin-block: 1rem;

  display: grid;
  gap: var(--_archive-filters-row-gap);
}

.archive__additional-toggle {
  align-items: center;
  appearance: none;
  background-color: var(--_archive-additional-toggle-background-color);
  color: var(--_archive-additional-toggle-color);
  cursor: pointer;
  display: flex;
  font-size: var(--_archive-additional-toggle-font-size);
  font-weight: var(--font-weight-bold);
  gap: 0.5em;
  inset-block-start: -1px;
  line-height: var(--line-height-body);
  margin-block: calc(var(--_archive-sidebar-row-gap) * -1);
  padding-block: var(--_archive-sidebar-row-gap);
  position: sticky;
  user-select: none;
  z-index: 2;

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }

  @include mq.mq($from: m) {
    display: none;
  }
}

.archive__additional-toggle-caret {
  --icon-size: 0.6em;

  align-items: center;
  background-color: var(--_archive-additional-toggle-icon-background-color);
  border-color: var(--_archive-additional-toggle-icon-border-color);
  border-radius: var(--_archive-additional-toggle-icon-border-radius);
  border-style: var(--_archive-additional-toggle-icon-border-style);
  border-width: var(--_archive-additional-toggle-icon-border-width);
  color: var(--_archive-additional-toggle-icon-color);
  display: flex;
  flex-shrink: 0;
  height: 1.4em;
  justify-content: center;
  line-height: 0;
  margin-inline-start: auto;
  transition-property: transform;
  width: 1.4em;

  .archive__additional[open] & {
    transform: rotate(180deg);
  }
}

.archive__facets {
  --field-label-margin-block: 1rem;
  --links-label-margin-block: 1rem;

  background-color: var(--_archive-additional-content-background-color);
  display: grid;
  gap: var(--_archive-facets-row-gap);
  margin-block-start: 2rem;
  padding: var(--_archive-additional-content-padding);
  position: relative;
  z-index: 1;

  @include mq.mq($from: m) {
    background-color: transparent;
    padding: 0;
  }
}

.archive__facet--submit {
  margin-block-start: calc(var(--_archive-facets-row-gap) / 2);
}

.archive__bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-block-end: var(--_archive-bar-margin-block);
}

.archive__count {
  color: var(--_archive-count-color);
  font-size: var(--_archive-count-font-size);
  font-weight: var(--_archive-count-font-weight);
  line-height: var(--line-height-body);
}

.archive__sorting {
  margin-inline-start: auto;
}

.archive__content {
  flex-shrink: 0;
  width: 100%;

  @include mq.mq($from: m) {
    width: column-width(7);
  }

  @include mq.mq($from: l) {
    width: column-width(8);
  }
}

.archive__teasers {
  display: grid;
  gap: var(--_archive-teasers-gap);
}

.archive__pagination {
  margin-block-start: var(--_archive-pagination-margin-block);
}
