.icon-button {
  --_icon-button-background-color: var(--icon-button-background-color, transparent);
  --_icon-button-color: var(--icon-button-color, var(--secondary-interactive-color));
  --_icon-button-font-size: var(--icon-button-font-size, var(--font-size-teaser));
  --_icon-button-font-weight: var(--icon-button-font-weight, var(--font-weight-bold));
  --_icon-button-gap: var(--icon-button-gap, 0.6em);
  --_icon-button-icon-background-color: var(--icon-button-icon-background-color, var(--secondary-interactive-color));
  --_icon-button-icon-border-color: var(--icon-button-icon-border-color, transparent);
  --_icon-button-icon-border-radius: var(--icon-button-icon-border-radius, 50%);
  --_icon-button-icon-border-style: var(--icon-button-icon-border-style, solid);
  --_icon-button-icon-border-width: var(--icon-button-icon-border-width, 0);
  --_icon-button-icon-color: var(--icon-button-icon-color, var(--secondary-interactive-background-color));
  --_icon-button-line-height: var(--icon-button-line-height, 1.3);
  --_icon-button-opacity: var(--icon-button-opacity, 1);
  --_icon-button-underline-color: var(--icon-button-underline-color, transparent);
  --_icon-button-underline-height: var(--icon-button-underline-height, 2px);
  --_icon-button-underline-offset: var(--icon-button-underline-offset, 0.2em);

  // :hover, :focus & :active
  --_icon-button-color--enter: var(--icon-button-color--enter, var(--secondary-interactive-color--enter));
  --_icon-button-icon-background-color--enter: var(--icon-button-icon-background-color--enter, var(--secondary-interactive-color--enter));
  --_icon-button-icon-border-color--enter: var(--icon-button-icon-border-color--enter, transparent);
  --_icon-button-icon-color--enter: var(--icon-button-icon-color--enter, var(--secondary-interactive-background-color--enter));
  --_icon-button-underline-color--enter: var(--icon-button-underline-color--enter, transparent);
  --_icon-button-background-color--enter: var(--icon-button-background-color--enter, transparent);

  // Disabled
  --_icon-button-color--disabled: var(--icon-button-color--disabled, var(--secondary-interactive-color--disabled));
  --_icon-button-icon-background-color--disabled: var(--icon-button-icon-background-color--disabled, var(--secondary-interactive-color--disabled));
  --_icon-button-icon-border-color--disabled: var(--icon-button-icon-border-color--disabled, transparent);
  --_icon-button-icon-color--disabled: var(--icon-button-icon-color--disabled, var(--secondary-interactive-background-color--disabled));
  --_icon-button-underline-color--disabled: var(--icon-button-underline-color--disabled, transparent);
  --_icon-button-background-color--disabled: var(--icon-button-background-color--disabled, transparent);

  align-items: center;
  background-color: var(--_icon-button-background-color);
  display: var(--icon-button-display, inline-flex);
  font-size: var(--_icon-button-font-size);
  line-height: 1;
  opacity: var(--_icon-button-opacity);
  padding-block: var(--icon-button-padding-block, 0);
  padding-inline: var(--icon-button-padding-inline, 0);
  text-align: left;
  transition-property: background-color, opacity;
  user-select: none;
  width: var(--icon-button-width, auto);

  &:is(:hover:not([disabled]), :focus-visible:not(.has-invisible-focus), .icon-button--active),
  .has-button:is(:hover, :focus-within) &:not([disabled]) {
    --_icon-button-color: var(--_icon-button-color--enter);
    --_icon-button-icon-background-color: var(--_icon-button-icon-background-color--enter);
    --_icon-button-icon-color: var(--_icon-button-icon-color--enter);
    --_icon-button-icon-border-color: var(--_icon-button-icon-border-color--enter);
    --_icon-button-underline-color: var(--_icon-button-underline-color--enter);
    --_icon-button-background-color: var(--_icon-button-background-color--enter);
  }

  &[disabled] {
    --_icon-button-color: var(--_icon-button-color--disabled);
    --_icon-button-icon-background-color: var(--_icon-button-icon-background-color--disabled);
    --_icon-button-icon-color: var(--_icon-button-icon-color--disabled);
    --_icon-button-icon-border-color: var(--_icon-button-icon-border-color--disabled);
    --_icon-button-underline-color: var(--_icon-button-underline-color--disabled);
    --_icon-button-background-color: var(--_icon-button-background-color--disabled);

    cursor: not-allowed;
  }
}

.icon-button--clipped {
  max-width: 100%;
}

.icon-button__text {
  --underline-color: var(--_icon-button-underline-color);
  --underline-color--enter: var(--_icon-button-underline-color);
  --underline-height: var(--_icon-button-underline-height);
  --underline-offset: var(--_icon-button-underline-offset);

  color: var(--_icon-button-color);
  font-size: var(--_icon-button-font-size);
  font-weight: var(--_icon-button-font-weight);
  line-height: var(--_icon-button-line-height);

  .icon-button--clipped & {
    overflow: hidden;
    padding-block: 0.25em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.icon-button__icon {
  align-self: center;
  color: var(--_icon-button-icon-color);
  flex-shrink: 0;
  font-size: var(--icon-button-icon-size, 0.8em);
  line-height: 0;
  vertical-align: 0.2em;
  white-space: nowrap;

  .icon-button--secondary & {
    font-size: calc(var(--icon-button-icon-size, 0.8em) * 1.5);
    vertical-align: 0;
  }

  &:first-child:not(:only-child) {
    margin-inline-end: var(--_icon-button-gap);
  }

  &:last-child:not(:only-child) {
    margin-inline-start: var(--_icon-button-gap);
  }
}

.icon-button__icon-icon {
  block-size: 1em;
  display: inline-block;
  inline-size: 1em;
  transition-property: background-color, border-color, color;

  .icon-button:not(.icon-button--secondary) & {
    background-color: var(--_icon-button-icon-background-color);
    block-size: calc(1em + var(--icon-button-icon-padding, 0.4em) * 2);
    border-color: var(--_icon-button-icon-border-color);
    border-radius: var(--_icon-button-icon-border-radius);
    border-style: var(--_icon-button-icon-border-style);
    border-width: var(--_icon-button-icon-border-width);
    inline-size: calc(1em + var(--icon-button-icon-padding, 0.4em) * 2);
    padding: var(--icon-button-icon-padding, 0.4em);
  }

  .icon-button--secondary & {
    color: var(--_icon-button-icon-background-color);
  }
}
