.calendar {
  container-name: calendar;
  container-type: inline-size;
  font-size: 1.6rem;
}

.calendar__calendar {
  --fc-event-bg-color: var(--calendar-event-background-color, var(--primary-interactive-color));
  --fc-event-fg-color: var(--calendar-event-color, var(--primary-interactive-background-color));
  --fc-event-border-color: var(--calendar-event-border-color, transparent);
  --fc-button-bg-color: var(--calendar-button-background-color, var(--secondary-interactive-color));
  --fc-button-fg-color: var(--calendar-button-color, var(--secondary-interactive-background-color));
  --fc-button-border-color: var(--calendar-button-border-color, transparent);
  --fc-button-hover-bg-color: var(--calendar-button-background-color--enter, var(--secondary-interactive-color--enter));
  --fc-button-hover-fg-color: var(--calendar-button-color--enter, var(--secondary-interactive-background-color--enter));
  --fc-button-hover-border-color: var(--calendar-button-border-color--enter, transparent);
  --fc-button-active-bg-color: var(--calendar-button-background-color--selected, var(--primary-interactive-color));
  --fc-button-active-fg-color: var(--calendar-button-color--selected, var(--primary-interactive-background-color));
  --fc-button-active-border-color: var(--calendar-button-border-color--selected, transparent);

  height: 55rem;

  .fc-event-past {
    filter: grayscale(1);
    opacity: 0.6;
  }

  .fc-daygrid-event-dot {
    border-color: var(--calendar-dot-color, var(--primary-interactive-color));
  }

  .fc-daygrid-week-number {
    @container calendar (inline-size <= 50rem) {
      font-size: 0.8rem;
    }
  }

  .fc-header-toolbar {
    @container calendar (inline-size <= 50rem) {
      flex-direction: column;
      row-gap: 2rem;
    }
  }

  .fc-button-group {
    gap: var(--calendar-button-gap, 0.5rem);
  }

  .fc-button {
    border-radius: var(--calendar-button-border-radius, 0);
    padding-block: var(--calendar-button-padding-block, 0.5rem);
    padding-inline: var(--calendar-button-padding-inline, 1rem);
  }

  .fc-button:disabled {
    background-color: var(--calendar-button-background-color--disabled, var(--secondary-interactive-color--disabled));
    border-color: var(--calendar-button-border-color--disabled, transparent);
    color: var(--calendar-button-color--disabled, var(--secondary-interactive-background-color--disabled));
    opacity: 1;
  }

  @container calendar (inline-size > 50rem) {
    height: 70rem;
  }

  @container calendar (inline-size > 70rem) {
    height: 80rem;
  }
}

.calendar__action {
  display: flex;
  justify-content: flex-end;
  margin-block-start: 3rem;
}
