.input-form {
  --_input-form-input-line-height: var(--input-line-height, 2.4rem);
  --_input-form-input-padding-block: var(--input-padding-block, 1.2rem);
  --_input-form-input-border-width: var(--input-border-width, 2px);

  align-items: flex-end;
  column-gap: 1.5rem;
  display: flex;
}

.input-form__field {
  flex-grow: 1;
}

.input-form__button {
  --button-height: calc(var(--_input-form-input-line-height) +
  (var(--_input-form-input-padding-block) + var(--_input-form-input-border-width)) * 2);
  --button-width: var(--button-height);
  --button-padding-inline: 0;
  --button-icon-padding-inline: 0;
  --button-align: center;

  flex-shrink: 0;
}
