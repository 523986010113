.tag {
  --_tag-background-color: var(--tag-background-color, var(--secondary-interactive-color));
  --_tag-border-color: var(--tag-border-color, transparent);
  --_tag-border-radius: var(--tag-border-radius, 99999px);
  --_tag-border-style: var(--tag-border-style, solid);
  --_tag-border-width: var(--tag-border-width, 0);
  --_tag-color: var(--tag-color, var(--secondary-interactive-background-color));
  --_tag-font-size: var(--tag-font-size, var(--font-size-meta));
  --_tag-font-weight: var(--tag-font-weight, var(--font-weight-regular));
  --_tag-padding-block: var(--tag-padding-block, 0.4em);
  --_tag-padding-inline: var(--tag-padding-inline, 1em);
  --_tag-text-transform: var(--tag-text-transform, none);
  --_tag-underline-color: var(--tag-underline-color, transparent);
  --_tag-underline-height: var(--tag-underline-height, 0);

  // :hover, :focus & :active
  --_tag-background-color--enter: var(--tag-background-color--enter, var(--secondary-interactive-color--enter));
  --_tag-border-color--enter: var(--tag-border-color--enter, transparent);
  --_tag-color--enter: var(--tag-color--enter, var(--secondary-interactive-background-color--enter));
  --_tag-underline-color--enter: var(--tag-underline-color--enter, transparent);

  // [aria-current='true']
  --_tag-background-color--current: var(--tag-background-color--current, var(--secondary-interactive-color--enter));
  --_tag-border-color--current: var(--tag-border-color--current, transparent);
  --_tag-color--current: var(--tag-color--current, var(--secondary-interactive-background-color--enter));
  --_tag-underline-color--current: var(--tag-underline-color--current, transparent);

  // [aria-current='true'] :hover, :focus & :active
  --_tag-background-color--current-enter: var(--tag-background-color--current-enter, var(--secondary-interactive-color));
  --_tag-border-color--current-enter: var(--tag-border-color--current-enter, transparent);
  --_tag-color--current-enter: var(--tag-color--current-enter, var(--secondary-interactive-background-color));
  --_tag-underline-color--current-enter: var(--tag-underline-color--current-enter, transparent);

  // Configuration
  --underline-color: var(--_tag-underline-color);
  --underline-color--enter: var(--_tag-underline-color--enter);
  --underline-height: var(--_tag-underline-height);
  --selection-background-color: var(--_tag-color);
  --selection-foreground-color: var(--_tag-background-color);

  background-color: var(--_tag-background-color);
  border-color: var(--_tag-border-color);
  border-radius: var(--_tag-border-radius);
  border-style: var(--_tag-border-style);
  border-width: var(--_tag-border-width);
  color: var(--_tag-color);
  display: inline-block;
  font-size: var(--_tag-font-size);
  font-weight: var(--_tag-font-weight);
  line-height: 1;
  line-height: var(--tag-line-height, var(--line-height-small));
  padding-block: var(--_tag-padding-block);
  padding-inline: var(--_tag-padding-inline);
  text-transform: var(--_tag-text-transform);
  transition-duration: var(--duration-fast);
  transition-property: background-color, border-color, color;

  &:any-link:is(:hover, :focus-visible) {
    background-color: var(--_tag-background-color--enter);
    border-color: var(--_tag-border-color--enter);
    color: var(--_tag-color--enter);
  }
}

.tag:is([aria-current='true']) {
  --underline-color: var(--_tag-underline-color--current);
  --underline-color--enter: var(--_tag-underline-color--current-enter);

  background-color: var(--_tag-background-color--current);
  border-color: var(--_tag-border-color--current);
  color: var(--_tag-color--current);

  &:any-link:is(:hover, :focus-visible) {
    background-color: var(--_tag-background-color--current-enter);
    border-color: var(--_tag-border-color--current-enter);
    color: var(--_tag-color--current-enter);
  }
}
