.radio {
  --_radio-background-color: var(--radio-background-color, var(--control-background-color));
  --_radio-border-color: var(--radio-border-color, var(--control-border-color));
  --_radio-border-style: var(--radio-border-style, solid);
  --_radio-border-width: var(--radio-border-width, 2px);
  --_radio-font-size: var(--radio-font-size, var(--font-size-meta));
  --_radio-gap: var(--radio-gap, 1.5rem);
  --_radio-line-height: var(--radio-line-height, 2.2rem);
  --_radio-marker-color: var(--radio-marker-color, var(--control-color));
  --_radio-size: var(--radio-size, 2.8rem);
  --_radio-text-color: var(--radio-text-color, var(--control-color));

  // :checked
  --_radio-border-color--checked: var(--radio-border-color--checked, var(--control-border-color));
  --_radio-background-color--checked: var(--radio-background-color--checked, var(--control-border-color));

  cursor: pointer;
  display: flex;
  gap: var(--_radio-gap);
  position: relative;
}

.radio--disabled {
  cursor: default;
  filter: grayscale(1);
  opacity: 0.2;
}

.radio__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;

  &:focus {
    outline: 0;
  }
}

.radio__marker {
  align-items: center;
  background-color: var(--_radio-background-color);
  border-color: var(--_radio-border-color);
  border-radius: 50%;
  border-style: var(--_radio-border-style);
  border-width: var(--_radio-border-width);
  color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: var(--_radio-size);
  justify-content: center;
  transition-property: border-color;
  user-select: none;
  width: var(--_radio-size);

  .radio__input:focus-visible ~ & {
    @include use-focus-outline();
  }

  &::before {
    background-color: var(--_radio-marker-color);
    border-radius: 50%;
    content: '';
    display: block;
    height: calc(var(--_radio-size) / 2);
    opacity: 0;
    transition-property: opacity;
    width: calc(var(--_radio-size) / 2);
  }

  .radio__input:checked ~ &::before {
    opacity: 1;
  }
}

.radio__label {
  color: var(--_radio-text-color);
  font-size: var(--_radio-font-size);
  line-height: var(--_radio-line-height);
  margin-block-start: calc((var(--_radio-size) - var(--_radio-line-height)) / 2);
  text-align: left;
}
