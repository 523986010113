.link-preview {
  --_link-preview-background-color: var(--link-preview-background-color, var(--secondary-background-color));
  --_link-preview-border-color: var(--link-preview-border-color, transparent);
  --_link-preview-border-radius: var(--link-preview-border-radius, 0);
  --_link-preview-border-style: var(--link-preview-border-style, solid);
  --_link-preview-border-width: var(--link-preview-border-width, 0);
  --_link-preview-color: var(--link-preview-color, var(--text-color));
  --_link-preview-description-color: var(--link-preview-description-color, var(--text-color));
  --_link-preview-font-size: var(--link-preview-font-size, var(--font-size-meta));
  --_link-preview-provider-color: var(--link-preview-provider-color, var(--caption-color));

  background-color: var(--_link-preview-background-color);
  border-color: var(--_link-preview-border-color);
  border-radius: var(--_link-preview-border-radius);
  border-style: var(--_link-preview-border-style);
  border-width: var(--_link-preview-border-width);
  color: var(--_link-preview-color);
  container-name: link-preview;
  container-type: inline-size;
  display: flex;
  font-size: var(--_link-preview-font-size);
  line-height: var(--line-height-body);
}

.link-preview__content {
  display: flex;
  flex-direction: column;
  flex-shrink: 10;
  gap: 0.3em;
  justify-content: center;
  padding: 1.5rem;

  @container link-preview (min-width: 40rem) {
    padding: 2rem;
  }
}

.link-preview__title {
  --line-clamp: 3;

  font-size: 0.8em;
  font-weight: var(--font-weight-bold);

  @container link-preview (min-width: 40rem) {
    --line-clamp: 1;

    font-size: 1em;
  }
}

.link-preview__description {
  --line-clamp: 2;

  @container link-preview (max-width: 40rem) {
    display: none;
  }
}

.link-preview__provider {
  align-items: center;
  color: var(--_link-preview-provider-color);
  display: flex;
  font-size: 0.8em;
  gap: 0.5em;
}

.link-preview__provider-icon {
  line-height: 0;
}

.link-preview__provider-text {
  --line-clamp: 1;
}

.link-preview__preview {
  aspect-ratio: 1;
  flex-shrink: 0;
  max-width: 15rem;
  object-fit: cover;
  width: 40%;
}
