@use 'mq';

.teaser-slide {
  @include use-responsive-config(--teaser-slide-padding-block, responsive-map(xs 4rem, xl 6rem));

  --_teaser-slide-background-color: var(--teaser-slide-background-color, var(--secondary-background-color));
  --_teaser-slide-padding-inline: var(--teaser-slide-padding-inline, var(--inner-container-padding));
  --_teaser-slide-font-size: var(--teaser-slide-font-size, var(--font-size-teaser));

  align-items: center;
  background-color: var(--_teaser-slide-background-color);
  column-gap: var(--gap);
  display: flex;
  flex-wrap: wrap;
  padding-block: var(--_teaser-slide-padding-block);
  padding-inline: var(--_teaser-slide-padding-inline);
  row-gap: 3rem;
}

.teaser-slide__image {
  --image-z-index: 1;

  flex-shrink: 0;
  margin-block-start: calc(var(--_teaser-slide-padding-block) * -1);
  margin-inline: calc(var(--_teaser-slide-padding-inline) * -1);
  order: -1;
  position: relative;
  width: calc(100% + var(--_teaser-slide-padding-inline) * 2);

  @include mq.mq($from: l) {
    margin-block-start: 0;
    margin-inline: 0;
    order: 1;
    width: column-width(8);
  }
}

.teaser-slide__copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  position: absolute;
  z-index: 2;
}

.teaser-slide__content {
  @include use-column-width(width, (xs 12, l 4));

  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  row-gap: 2rem;
}

.teaser-slide__headline {
  --headline-text-transform: uppercase;
}

.teaser-slide__description {
  font-size: var(--_teaser-slide-font-size);
  line-height: var(--line-height-body);
}
