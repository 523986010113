.external-video {
  --_external-video-aspect-ratio: var(--external-video-aspect-ratio, 16 / 9);
  --_external-video-background-color: var(--external-video-background-color, var(--secondary-background-color));
  --_external-video-play-background-color: var(--external-video-play-background-color, var(--primary-interactive-color));
  --_external-video-play-border-radius: var(--external-video-play-border-radius, 50%);
  --_external-video-play-color: var(--external-video-play-color, var(--primary-interactive-background-color));
  --_external-video-play-size: var(--external-video-play-size, 0.15);
  --_external-video-warning-color: var(--external-video-warning-color, var(--color-white));
  --_external-video-warning-padding-block: var(--external-video-warning-padding-block, 2rem);
  --_external-video-warning-padding-inline: var(--external-video-warning-padding-inline, 2rem);
  --_external-video-z-index: var(--external-video-z-index, #{z-index('default')});

  // :hover, :focus & :active
  --_external-video-play-background-color--enter: var(--external-video-play-background-color--enter, var(--primary-interactive-color--enter));
  --_external-video-play-color--enter: var(--external-video-play-color--enter, var(--primary-interactive-background-color--enter));

  aspect-ratio: var(--_external-video-aspect-ratio);
  container-name: external-video;
  container-type: inline-size;
  position: relative;
  z-index: var(--_external-video-z-index);
}

.external-video__preview {
  --_external-video-image-scale: 1.1;

  aspect-ratio: var(--_external-video-aspect-ratio);
  background-color: var(--_external-video-background-color);
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:is(:hover, :focus-visible) {
    --_external-video-image-scale: 1.2;
    --_external-video-play-background-color: var(--_external-video-play-background-color--enter);
    --_external-video-play-color: var(--_external-video-play-color--enter);
  }

  &::after {
    @include use-scrim-gradient($start-color: rgba(0, 0, 0, 0.8), $direction: 'to top');

    content: '';
    inset: 0;
    position: absolute;
    z-index: 2;
  }
}

.external-video__preview-image {
  height: 100%;
  inset: 0;
  object-fit: cover;
  position: absolute;
  transform: scale(var(--_external-video-image-scale));
  transition-property: transform;
  width: 100%;
  z-index: 1;
}

.external-video__preview-image--autogenerated {
  filter: blur(0.5rem);
}

.external-video__preview-icon {
  align-items: center;
  background-color: var(--_external-video-play-background-color);
  border-radius: var(--_external-video-play-border-radius);
  color: var(--_external-video-play-color);
  display: flex;
  font-size: calc((var(--_external-video-play-size) / 2) * 100cqw);
  height: calc(var(--_external-video-play-size) * 100cqw);
  inset-block-start: 50%;
  inset-inline-start: 50%;
  justify-content: center;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  transition-property: background-color, color;
  width: calc(var(--_external-video-play-size) * 100cqw);
  z-index: 3;
}

.external-video__iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.external-video__warning {
  --link-color: var(--_external-video-warning-color);
  --link-color--enter: var(--_external-video-warning-color);

  color: var(--_external-video-warning-color);
  display: flex;
  font-size: 1.2rem;
  inset-block-end: 0;
  justify-content: var(--external-video-warning-justify-content, center);
  line-height: var(--line-height-default);
  padding-block: var(--_external-video-warning-padding-block);
  padding-inline: var(--_external-video-warning-padding-inline);
  position: absolute;
  text-align: var(--external-video-warning-text-align, center);
  width: 100%;
  z-index: 3;
}

.external-video__warning-text {
  max-width: 60rem;
}
