@use 'sass:color';

.t-spd-2023 {
  // Color configuration
  --caption-color: var(--color-gray-600);
  --control-background-color: var(--color-white);
  --control-background-color--enter: var(--color-white);
  --control-border-color: var(--color-black);
  --control-border-color--enter: var(--color-black);
  --control-color: var(--color-black);
  --control-color--enter: var(--color-black);
  --control-switch-background-color: var(--color-gray-200);
  --control-switch-foreground-color: var(--color-white);
  --control-switch-background-color--checked: var(--color-switch-green);
  --control-switch-foreground-color--checked: var(--color-white);
  --primary-background-color: var(--color-spd-red);
  --primary-color: var(--color-spd-red);
  --primary-heading-color: var(--color-spd-red);
  --primary-interactive-background-color--enter: var(--color-white);
  --primary-interactive-background-color--disabled: var(--color-white);
  --primary-interactive-background-color: var(--color-white);
  --primary-interactive-color--enter: var(--text-color);
  --primary-interactive-color--disabled: var(--color-gray-200);
  --primary-interactive-color: var(--color-spd-red);
  --secondary-background-color: var(--color-gray-050);
  --secondary-heading-color: var(--text-color);
  --secondary-interactive-background-color--enter: var(--color-white);
  --secondary-interactive-background-color--disabled: var(--color-white);
  --secondary-interactive-background-color: var(--color-white);
  --secondary-interactive-color--enter: var(--color-spd-red);
  --secondary-interactive-color--disabled: var(--color-gray-200);
  --secondary-interactive-color: var(--color-black);
  --text-color: var(--color-black);
  --meta-color: var(--color-gray-600);
  --tooltip-background-color: var(--color-black);
  --tooltip-color: var(--color-white);
  --line-color: var(--color-gray-200);

  // Root
  --root-font-family: var(--font-family-thesans);
  --root-color: var(--text-color);
  --root-font-width: var(--font-width-normal);
  --root-font-weight: var(--font-weight-regular);

  // Body
  --body-background-color: var(--color-white);
  --body-font-size: var(--font-size-default);

  // Focus outlines
  --focus-outline-width: 3px;
  --focus-outline-color: var(--color-black);
  --focus-outline-offset: 3px;

  // Selection
  --selection-background-color: var(--color-black);
  --selection-foreground-color: var(--color-white);

  // @button
  --button-text-transform: uppercase;

  // @headline
  --headline-kicker-text-transform: uppercase;

  // @accordion
  --accordion-summary-text-transform: uppercase;
  --accordion-summary-border-width: 0 0 1px;

  // @footer
  --footer-social-media-text-transform: uppercase;

  // @banderole
  --banderole-background-color: var(--color-spd-red);
  --banderole-color: var(--color-white);
  --banderole-text-transform: uppercase;

  // @stage
  --stage-tile-text-transform: uppercase;

  // @bubble
  --bubble-text-text-transform: uppercase;

  // @section
  --section-background-color--light: var(--secondary-background-color);
  --section-background-color--dark: var(--primary-color);

  .headline--1,
  .headline--2 {
    --headline-text-transform: uppercase;
  }

  // Line
  .grid-teaser:not(.grid-teaser--image, .grid-teaser--large, .grid-teaser--links, .grid-teaser--app) .grid-teaser__content,
  .page__heading,
  .footer__logo {
    &::after {
      background-color: var(--color-spd-red);
      content: '';
      display: block;
      height: 0.5rem;
      width: 5rem;
    }
  }

  .footer__logo::after {
    margin-block-start: 1.5rem;
  }

  .page__headline {
    --headline-text-transform: uppercase;
  }

  .page__footer {
    padding-block-start: var(--_page-section-padding-block);
    position: relative;

    &::before {
      background-color: var(--color-spd-red);
      content: '';
      display: block;
      height: 0.5rem;
      position: absolute;
      width: clamp(5rem, 30%, 10rem);
    }
  }

  .section__headline,
  .form-breaker__headline {
    --headline-text-transform: uppercase;
  }

  [data-theme='light'] {
    --section-background-color: var(--secondary-background-color);
    --teaser-background-color: var(--color-white);
    --tab-bar-background-color: var(--secondary-background-color);
    --social-media-post-background-color: var(--color-white);
    --social-media-post-link-background-color: var(--secondary-background-color);
    --social-media-post-repost-background-color: var(--secondary-background-color);
    --tile-background-color: var(--color-white);
    --grid-teaser-background-color: var(--color-white);
    --slider-scroll-bar-background-color: var(--color-white);
    --form-breaker-background-color: var(--secondary-background-color);
    --form-breaker-alert-background-color: var(--color-white);
  }

  [data-theme='dark'] {
    --date-square-background-color: var(--color-white);
    --date-square-color: var(--primary-color);
    --button-background-color--disabled: var(--secondary-interactive-background-color--disabled);
    --button-background-color--enter: var(--color-white);
    --button-color--enter: var(--color-black);
    --grid-teaser-background-color: transparent;
    --grid-teaser-border-color: var(--color-white);
    --grid-teaser-border-width: 0.5rem;
    --grid-teaser-color: var(--color-white);
    --grid-teaser-link-color: var(--color-white);
    --grid-teaser-link-color--enter: var(--color-white);
    --grid-teaser-headline-color: var(--color-white);
    --grid-teaser-headline-color--enter: var(--color-white);
    --teaser-background-color: transparent;
    --teaser-border-color: var(--color-white);
    --teaser-border-width: 0.5rem;
    --teaser-color: var(--color-white);
    --teaser-link-color: var(--color-white);
    --teaser-link-color--enter: var(--color-white);
    --teaser-headline-color: var(--color-white);
    --teaser-headline-color--enter: var(--color-white);
    --icon-button-color--enter: var(--color-white);
    --icon-button-color: var(--color-white);
    --icon-button-icon-background-color--disabled: var(--secondary-interactive-background-color--disabled);
    --icon-button-icon-background-color--enter: var(--color-white);
    --icon-button-icon-background-color: var(--color-white);
    --icon-button-icon-color--enter: var(--color-black);
    --icon-button-icon-color--disabled: rgba(255, 255, 255, 0.3);
    --icon-button-icon-color: var(--primary-color);
    --icon-button-underline-color--enter: var(--color-white);
    --secondary-interactive-background-color--disabled: #{color.mix($color-spd-red, $color-black, $weight: 80%)};
    --section-background-color: var(--primary-color);
    --section-headline-color: var(--color-white);
    --section-text-color: var(--color-white);
    --slider-scroll-bar-background-color: var(--secondary-interactive-background-color--disabled);
    --slider-scroll-bar-foreground-color: var(--color-white);
    --social-media-post-background-color: var(--color-white);
    --social-media-post-link-background-color: var(--secondary-background-color);
    --social-media-post-repost-background-color: var(--secondary-background-color);
    --tab-bar-background-color: var(--primary-color);
    --tab-bar-label-color: var(--color-black);
    --tab-bar-option-color: var(--color-white);
    --tab-bar-option-color--selected: var(--color-white);
    --tab-bar-option-underline-color--selected: var(--color-white);
    --text-breaker-color: var(--color-white);
    --text-breaker-link-color: var(--color-white);
    --text-breaker-link-color--enter: var(--color-white);
    --quote-color: var(--color-white);
    --quote-caption-color: var(--color-white);
    --quote-copyright-color: #{color.mix($color-spd-red, $color-white, $weight: 4.9%)};
    --form-breaker-background-color: var(--primary-color);
    --form-breaker-headline-color: var(--color-white);
    --form-breaker-color: var(--color-white);
    --form-breaker-control-color: var(--color-white);
    --form-breaker-link-color: var(--color-white);
    --form-breaker-link-color--enter: var(--color-white);
    --form-breaker-label-color: var(--color-white);
    --form-breaker-alert-icon-border-color: var(--color-gray-050);
    --form-breaker-alert-icon-border-width: 2px;
    --required-color: var(--color-white);
  }
}
