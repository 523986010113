.social-media-posts {
  container-name: social-media-posts;
  container-type: inline-size;
}

.social-media-posts__accounts {
  column-gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  margin-block-end: 3rem;
  row-gap: 1rem;

  @container social-media-posts (inline-size >= 90rem) {
    margin-block-end: 4rem;
  }
}

.social-media-posts__account {
  display: block;
  flex-shrink: 0;
}

.social-media-posts__posts {
  --_social-media-posts-row-gap: 3rem;

  column-count: 1;
  column-gap: var(--gap);
  margin-block-end: calc(var(--_social-media-posts-row-gap) * -1);

  @container social-media-posts (inline-size >= 60rem) {
    column-count: 2;
  }

  @container social-media-posts (inline-size >= 90rem) {
    --_social-media-posts-row-gap: 4rem;

    column-count: 3;
  }
}

.social-media-posts__column {
  break-inside: avoid;
}

.social-media-posts__post {
  break-inside: avoid;
  padding-block-end: var(--_social-media-posts-row-gap);
}

.social-media-posts__columns {
  break-inside: avoid;
}
