.preview {
  background-color: var(--preview-background-color, transparent);
}

.preview__inner {
  margin-inline: auto;
  max-width: #{$inner-container-max-width};
  min-height: 100vh;
  padding: var(--inner-container-padding);

  .preview--paddingless & {
    max-width: none;
    padding: 0;
  }
}

.preview__grid-overlay {
  inset: 0;
  inset-block-start: var(--root-offset);
  opacity: 0.5;
  pointer-events: none;
  position: fixed;
  z-index: 99999;
}

.preview__grid-overlay-inner {
  @include use-container();

  column-gap: var(--gap);
  display: grid;
  grid-auto-flow: column;
  height: 100%;
}

.preview__grid-overlay-column {
  background-color: #f00;
}
