// stylelint-disable order/order

@use 'sass:map';
@use 'mq';

:root {
  // Colors
  --color-white: #{$color-white};
  --color-black: #{$color-black};
  --color-spd-red: #{$color-spd-red};
  --color-easy-language-blue: #{$color-easy-language-blue};
  --color-switch-green: #{$color-switch-green};

  // Grays
  --color-gray-050: #{$color-gray-050};
  --color-gray-100: #{$color-gray-100};
  --color-gray-200: #{$color-gray-200};
  --color-gray-300: #{$color-gray-300};
  --color-gray-400: #{$color-gray-400};
  --color-gray-500: #{$color-gray-500};
  --color-gray-600: #{$color-gray-600};
  --color-gray-700: #{$color-gray-700};
  --color-gray-800: #{$color-gray-800};
  --color-gray-900: #{$color-gray-900};

  // SPD NDS 23
  --color-nds-22-blue-050: #{$color-nds-22-blue-050};
  --color-nds-22-blue-100: #{$color-nds-22-blue-100};
  --color-nds-22-blue-200: #{$color-nds-22-blue-200};
  --color-nds-22-blue-300: #{$color-nds-22-blue-300};
  --color-nds-22-blue-400: #{$color-nds-22-blue-400};
  --color-nds-22-blue-500: #{$color-nds-22-blue-500};
  --color-nds-22-blue-600: #{$color-nds-22-blue-600};
  --color-nds-22-blue-700: #{$color-nds-22-blue-700};
  --color-nds-22-blue-800: #{$color-nds-22-blue-800};
  --color-nds-22-blue-900: #{$color-nds-22-blue-900};

  // SPD MVP 23
  --color-mvp-23-blue-050: #{$color-mvp-23-blue-050};
  --color-mvp-23-blue-100: #{$color-mvp-23-blue-100};
  --color-mvp-23-blue-200: #{$color-mvp-23-blue-200};
  --color-mvp-23-blue-300: #{$color-mvp-23-blue-300};
  --color-mvp-23-blue-400: #{$color-mvp-23-blue-400};
  --color-mvp-23-blue-500: #{$color-mvp-23-blue-500};
  --color-mvp-23-blue-600: #{$color-mvp-23-blue-600};
  --color-mvp-23-blue-700: #{$color-mvp-23-blue-700};
  --color-mvp-23-blue-800: #{$color-mvp-23-blue-800};
  --color-mvp-23-blue-900: #{$color-mvp-23-blue-900};
  --color-mvp-23-blue-950: #{$color-mvp-23-blue-950};

  // Font families
  --font-family-thesans: 'TheSans', sans-serif;
  --font-family-metropolis: 'Metropolis', sans-serif;
  --font-family-karla: 'Karla', sans-serif;
  --font-family-archivo-narrow: 'Archivo Narrow', sans-serif;

  // Font weights
  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;
  --font-weight-extrablack: 950;

  // Line heights
  --line-height-small: 1.2;
  --line-height-default: 1.4;
  --line-height-body: 1.6;

  // Durations
  --duration-fast: 0.1s;
  --duration-default: 0.2s;
  --duration-long: 0.3s;
  --duration-x-long: 0.4s;
  --duration-xx-long: 0.5s;

  // Border radius
  --border-radius-default: 0.5rem;
  --border-radius-large: 1.5rem;
  --border-radius-x-large: 2rem;
  @include use-responsive-sizing(--border-radius-xx-large, responsive-map(xs 2rem, xl 3rem));

  // Text sizes
  --font-size-meta: 1.6rem;
  @include use-responsive-sizing(--font-size-default, responsive-map(xs 1.6rem, xl 1.8rem));
  @include use-responsive-sizing(--font-size-teaser, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--font-size-paragraph, responsive-map(xs 1.8rem, xl 2.2rem));
  @include use-responsive-sizing(--font-size-headline-kicker, responsive-map(xs 1.6rem, xl 1.8rem));
  @include use-responsive-sizing(--font-size-headline-1, responsive-map(xs 4rem, xl 5.6rem));
  @include use-responsive-sizing(--font-size-headline-2, responsive-map(xs 3.2rem, xl 4.2rem));
  @include use-responsive-sizing(--font-size-headline-3, responsive-map(xs 2.4rem, xl 3.2rem));
  @include use-responsive-sizing(--font-size-headline-4, responsive-map(xs 2.2rem, xl 2.8rem));
  @include use-responsive-sizing(--font-size-headline-5, responsive-map(xs 2rem, xl 2.4rem));
  @include use-responsive-sizing(--font-size-headline-6, responsive-map(xs 1.8rem, xl 2.2rem));

  // Root
  --root-offset: 0;
  --root-min-width: #{$root-min-width};

  // Font
  --root-font-family: sans-serif;
  --root-color: var(--text-color);
  --root-font-width: var(--font-width-normal);
  --root-font-weight: var(--font-weight-regular);

  // Body
  --body-background-color: var(--color-white);
  --body-font-size: var(--font-size-default);

  // Focus outlines
  --focus-outline-width: 3px;
  --focus-outline-color: var(--color-black);
  --focus-outline-offset: 3px;

  // Selection
  --selection-background-color: var(--color-black);
  --selection-foreground-color: var(--color-white);

  // Breakpoints
  --breakpoint-l: #{map.get($breakpoints, l)};
  --breakpoint-m: #{map.get($breakpoints, m)};
  --breakpoint-s: #{map.get($breakpoints, s)};
  --breakpoint-xl: #{map.get($breakpoints, xl)};
  --breakpoint-xs: #{map.get($breakpoints, xs)};

  // Grid sizes
  @include use-responsive-sizing(--gap, $gaps);

  // Section padding
  @include use-responsive-sizing(--site-section-padding-inline, responsive-map(xs 4rem, xl 8rem));

  // Container and body paddings
  @include use-body-padding($properties: --inner-container-padding --body-padding);
  @include mq.mq($from: rem2px($inner-container-max-width)) {
    --body-padding: calc((100vw - #{$inner-container-max-width}) / 2 + var(--inner-container-padding));
  }
}
