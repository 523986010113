.horizontal-rule {
  --_horizontal-rule-color: var(--horizontal-rule-color, var(--line-color));
  --_horizontal-rule-thickness: var(--horizontal-rule-thickness, 0.2rem);
  --_horizontal-rule-width: var(--horizontal-rule-width, 100%);
  --_horizontal-rule-margin-inline: var(--horizontal-rule-margin-inline, auto);
  --_horizontal-rule-margin-block: var(--horizontal-rule-margin-block, 0);
  --_horizontal-rule-border-radius: var(--horizontal-rule-border-radius, 0);

  background-color: var(--_horizontal-rule-color);
  border-radius: var(--_horizontal-rule-border-radius);
  clear: both;
  display: block;
  height: var(--_horizontal-rule-thickness);
  margin-block: var(--_horizontal-rule-margin-block);
  margin-inline: var(--_horizontal-rule-margin-inline);
  width: var(--_horizontal-rule-width);
}
