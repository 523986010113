@font-face {
  font-family: 'bullet';
  src: url('../../fonts/bullet.woff2') format('woff2');
}

// TheSans

@font-face {
  font-display: swap;
  font-family: 'TheSans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/thesans/thesans-plain.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'TheSans';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/thesans/thesans-italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'TheSans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/thesans/thesans-bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'TheSans';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/thesans/thesans-bold-italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'TheSans';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/thesans/thesans-extrabold.woff2') format('woff2');
}

// Metropolis

@font-face {
  font-display: swap;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/metropolis/metropolis-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Metropolis';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/metropolis/metropolis-italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/metropolis/metropolis-bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Metropolis';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/metropolis/metropolis-bold-italic.woff2') format('woff2');
}

// Karla

@font-face {
  font-display: swap;
  font-family: 'Karla';
  font-style: normal;
  src: url('../../fonts/karla/karla-var.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Karla';
  font-style: italic;
  src: url('../../fonts/karla/karla-var-italic.woff2') format('woff2');
}

// Archivo Narrow

@font-face {
  font-display: swap;
  font-family: 'Archivo Narrow';
  font-style: normal;
  src: url('../../fonts/archivo-narrow/archivo-narrow-var.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Archivo Narrow';
  font-style: italic;
  src: url('../../fonts/archivo-narrow/archivo-narrow-var-italic.woff2') format('woff2');
}
