.label {
  --_label-color: var(--label-color, var(--text-color));
  --_label-font-size: var(--label-font-size, var(--font-size-meta));
  --_label-font-weight: var(--label-font-weight, var(--font-weight-bold));
  --_label-line-height: var(--label-line-height, var(--line-height-body));

  color: var(--_label-color);
  display: block;
  font-size: var(--_label-font-size);
  font-weight: var(--_label-font-weight);
  line-height: var(--_label-line-height);
}

.label__required {
  margin-inline-start: 0.7rem;
}
