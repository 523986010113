.overlay {
  @include use-responsive-config(--overlay-content-padding-inline, responsive-map(xs 3rem, xl 7rem));
  @include use-responsive-config(--overlay-content-padding-block, responsive-map(xs 3rem, xl 8rem));
  @include use-responsive-config(--overlay-close-button-size, responsive-map(xs 3.6rem, xl 4.8rem));

  --_overlay-block-margin-block: var(--overlay-block-margin-block, 2rem);
  --_overlay-close-color: var(--overlay-close-color, var(--primary-interactive-color));
  --_overlay-color: var(--overlay-color, var(--text-color));
  --_overlay-close-inset: var(--overlay-close-inset, 3rem);
  --_overlay-bar-height: var(--overlay-bar-height, calc(var(--_overlay-close-inset) * 2 + var(--_overlay-close-button-size)));

  // :hover, :focus & :active
  --_overlay-close-color--enter: var(--overlay-close-color--enter, var(--primary-interactive-color--enter));

  animation: opacity var(--duration-default);
  inset: 0;
  position: fixed;
  z-index: z-index('overlay');

  &[aria-hidden='true'] {
    display: none;
  }
}

.overlay--slim {
  @include use-responsive-config(--overlay-content-padding-inline, responsive-map(xs 3rem, xl 8rem));

  --overlay-max-width: 50rem;
}

.overlay__backdrop {
  backdrop-filter: blur(1rem);
  background-color: var(--overlay-backdrop-background-color, var(--color-black));
  inset: 0;
  opacity: var(--overlay-backdrop-opacity, 0.58);
  position: absolute;
  z-index: 1;
}

.overlay__overlay {
  animation:
    opacity var(--duration-default),
    var(--overlay-animation, slide-from-right) var(--overlay-animation-duration, var(--duration-long));
  background-color: var(--overlay-background-color, var(--secondary-background-color));
  color: var(--_overlay-color);
  inset: var(--overlay-inset, 0 0 0 auto);
  max-width: var(--overlay-max-width, 75rem);
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.overlay__bar {
  height: var(--_overlay-bar-height);
  inset: 0 0 auto auto;
  max-width: var(--overlay-max-width, 75rem);
  padding-block: var(--_overlay-close-inset);
  padding-inline-end: calc(var(--_overlay-content-padding-inline) + 2rem + var(--_overlay-close-button-size));
  padding-inline-start: var(--_overlay-content-padding-inline);
  position: fixed;
  transition-property: background-color, backdrop-filter;
  width: 100%;
  z-index: 2;

  .overlay--scrolled & {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.overlay__close {
  --icon-button-font-size: var(--_overlay-close-button-size);
  --icon-button-icon-size: calc(var(--_overlay-close-button-size) * 0.5);
  --icon-button-icon-padding: calc(var(--_overlay-close-button-size) * 0.25);

  inset-block-start: var(--_overlay-close-inset);
  inset-inline-end: var(--_overlay-close-inset);
  position: absolute;
}

.overlay__content {
  --_overlay-inner-padding-block: 2rem;
  --social-media-links-text-align: left;
  --social-media-links-links-align: flex-start;

  animation: var(--overlay-content-animation, none) var(--overlay-content-animation-duration, var(--duration-long));
  background-color: var(--overlay-content-background-color, transparent);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-block-end: var(--_overlay-content-padding-block);
  padding-block-start: calc(var(--_overlay-bar-height) + var(--_overlay-content-padding-block) / 2);
  padding-inline: var(--_overlay-content-padding-inline);
}

.overlay__block {
  margin-block-end: var(--_overlay-block-margin-block);

  &:first-child {
    margin-block-start: 0;
  }

  &:last-child {
    margin-block-end: 0;
  }

  > * + * {
    margin-block-start: var(--_overlay-block-margin-block);
  }

  > .headline {
    margin-block-start: calc(var(--_overlay-block-margin-block) * 1.5);
  }
}

.overlay__block--aside {
  margin-block: calc(var(--_overlay-block-margin-block) * 1.25);
}
